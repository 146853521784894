import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
import { registerDestructor } from "@ember/destroyable";
import Modifier from "ember-modifier";
import { bind } from "discourse-common/utils/decorators";
let DraggableModifier = (_class = class DraggableModifier extends Modifier {
  constructor(owner, args) {
    super(owner, args);
    _defineProperty(this, "hasStarted", false);
    _defineProperty(this, "element", void 0);
    registerDestructor(this, instance => instance.cleanup());
  }
  modify(el, _, _ref) {
    let {
      didStartDrag,
      didEndDrag,
      dragMove
    } = _ref;
    this.element = el;
    this.didStartDragCallback = didStartDrag;
    this.didEndDragCallback = didEndDrag;
    this.dragMoveCallback = dragMove;
    this.element.addEventListener("touchstart", this.dragMove, {
      passive: false
    });
    this.element.addEventListener("mousedown", this.dragMove, {
      passive: false
    });
    this.element.addEventListener("dragenter", this.dragMove, {
      passive: false
    });
  }
  dragMove(e) {
    if (!this.hasStarted) {
      this.hasStarted = true;
      if (this.didStartDragCallback) {
        this.didStartDragCallback(e);
      }

      // Register a global event to capture mouse moves when element 'clicked'.
      document.addEventListener("touchmove", this.drag, {
        passive: false
      });
      document.addEventListener("mousemove", this.drag, {
        passive: false
      });
      document.addEventListener("dragover", this.drag, {
        passive: false
      });
      document.body.classList.add("dragging");

      // On leaving click, stop moving.
      document.addEventListener("touchend", this.didEndDrag, {
        passive: false
      });
      document.addEventListener("mouseup", this.didEndDrag, {
        passive: false
      });
      document.addEventListener("drop", this.didEndDrag, {
        passive: false
      });
    }
  }
  drag(e) {
    if (this.hasStarted && this.dragMoveCallback) {
      this.dragMoveCallback(e, this.element);
    }
  }
  didEndDrag(e) {
    if (this.hasStarted) {
      this.didEndDragCallback(e, this.element);
      document.removeEventListener("touchmove", this.drag);
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("dragover", this.drag);
      document.body.classList.remove("dragging");
      this.hasStarted = false;
    }
  }
  cleanup() {
    document.removeEventListener("touchstart", this.dragMove);
    document.removeEventListener("mousedown", this.dragMove);
    document.removeEventListener("dragenter", this.dragMove);
    document.removeEventListener("touchend", this.didEndDrag);
    document.removeEventListener("mouseup", this.didEndDrag);
    document.removeEventListener("drop", this.didEndDrag);
    document.removeEventListener("mousemove", this.drag);
    document.removeEventListener("touchmove", this.drag);
    document.removeEventListener("dragover", this.drag);
    document.body.classList.remove("dragging");
  }
}, (_applyDecoratedDescriptor(_class.prototype, "dragMove", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "dragMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drag", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "drag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didEndDrag", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "didEndDrag"), _class.prototype)), _class);
export { DraggableModifier as default };