import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
import { ajax } from "discourse/lib/ajax";
import { bind } from "discourse-common/utils/decorators";
import SidebarCustomSection from "../common/custom-sections";
let SidebarUserCustomSections = (_class = class SidebarUserCustomSections extends SidebarCustomSection {
  constructor() {
    super(...arguments);
    this.messageBus.subscribe("/refresh-sidebar-sections", this._refresh);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.messageBus.unsubscribe("/refresh-sidebar-sections");
  }
  async _refresh() {
    const json = await ajax("/sidebar_sections.json", {});
    this.currentUser.set("sidebar_sections", json.sidebar_sections);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "_refresh", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_refresh"), _class.prototype)), _class);
export { SidebarUserCustomSections as default };