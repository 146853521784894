import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './images-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./images-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, (_dec = discourseComputed("uploadingOrProcessing"), (_obj = {
  type: "avatar",
  tagName: "span",
  uploadButtonText(uploadingOrProcessing) {
    return uploadingOrProcessing ? I18n.t("uploading") : I18n.t("upload");
  },
  validateUploadedFilesOptions() {
    return {
      imagesOnly: true
    };
  },
  uploadDone(upload) {
    this.done(upload);
  }
}, (_applyDecoratedDescriptor(_obj, "uploadButtonText", [_dec], Object.getOwnPropertyDescriptor(_obj, "uploadButtonText"), _obj)), _obj))));