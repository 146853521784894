import RenderGlimmer from "discourse/widgets/render-glimmer";
import { createWidget } from "discourse/widgets/widget";
import { createTemplateFactory } from "@ember/template-factory";
createWidget("post-user-status", {
  tagName: "span.user-status-message-wrap",
  html(attrs) {
    return [new RenderGlimmer(this, "span", createTemplateFactory(
    /*
      <UserStatusMessage @status={{@data.attrs}} />
    */
    {
      "id": "ENLJ1BWp",
      "block": "[[[8,[39,0],null,[[\"@status\"],[[30,1,[\"attrs\"]]]],null]],[\"@data\"],false,[\"user-status-message\"]]",
      "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/post-user-status.js",
      "isStrictMode": false
    }), {
      attrs
    })];
  }
});