import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
/* import __COLOCATED_TEMPLATE__ from './topic-status.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { htmlSafe } from "@ember/template";
import $ from "jquery";
import { iconHTML } from "discourse-common/lib/icon-library";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./topic-status.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("disableActions"), _dec2 = discourseComputed("topic.closed", "topic.archived"), _dec3 = discourseComputed("topic.is_warning"), _dec4 = discourseComputed("showPrivateMessageIcon", "topic.isPrivateMessage", "topic.is_warning"), _dec5 = discourseComputed("topic.pinned"), _dec6 = discourseComputed("topic.unpinned"), _dec7 = discourseComputed("topic.invisible"), (_obj = {
  disableActions: false,
  classNames: ["topic-statuses"],
  click(e) {
    // only pin unpin for now
    if (this.canAct && $(e.target).hasClass("d-icon-thumbtack")) {
      const topic = this.topic;
      topic.get("pinned") ? topic.clearPin() : topic.rePin();
      return false;
    }
  },
  canAct(disableActions) {
    return this.currentUser && !disableActions;
  },
  topicClosedArchived(closed, archived) {
    if (closed && archived) {
      this._set("closedArchived", "lock", "locked_and_archived");
      this._reset("closed");
      this._reset("archived");
      return true;
    } else {
      this._reset("closedArchived");
      closed ? this._set("closed", "lock", "locked") : this._reset("closed");
      archived ? this._set("archived", "lock", "archived") : this._reset("archived");
      return false;
    }
  },
  topicWarning(warning) {
    return warning ? this._set("warning", "envelope", "warning") : this._reset("warning");
  },
  topicPrivateMessage(showPrivateMessageIcon, privateMessage, warning) {
    return showPrivateMessageIcon && privateMessage && !warning ? this._set("privateMessage", "envelope", "personal_message") : this._reset("privateMessage");
  },
  topicPinned(pinned) {
    return pinned ? this._set("pinned", "thumbtack", "pinned") : this._reset("pinned");
  },
  topicUnpinned(unpinned) {
    return unpinned ? this._set("unpinned", "thumbtack", "unpinned", {
      class: "unpinned"
    }) : this._reset("unpinned");
  },
  topicInvisible(invisible) {
    return invisible ? this._set("invisible", "far-eye-slash", "unlisted") : this._reset("invisible");
  },
  _set(name, icon, key, iconArgs) {
    this.set(`${name}Icon`, htmlSafe(iconHTML(`${icon}`, iconArgs)));
    const translationParams = {};
    if (name === "invisible") {
      translationParams.unlistedReason = this.topic.visibilityReasonTranslated;
    }
    this.set(`${name}Title`, I18n.t(`topic_statuses.${key}.help`, translationParams));
    return true;
  },
  _reset(name) {
    this.set(`${name}Icon`, null);
    this.set(`${name}Title`, null);
    return false;
  }
}, (_applyDecoratedDescriptor(_obj, "canAct", [_dec], Object.getOwnPropertyDescriptor(_obj, "canAct"), _obj), _applyDecoratedDescriptor(_obj, "topicClosedArchived", [_dec2], Object.getOwnPropertyDescriptor(_obj, "topicClosedArchived"), _obj), _applyDecoratedDescriptor(_obj, "topicWarning", [_dec3], Object.getOwnPropertyDescriptor(_obj, "topicWarning"), _obj), _applyDecoratedDescriptor(_obj, "topicPrivateMessage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "topicPrivateMessage"), _obj), _applyDecoratedDescriptor(_obj, "topicPinned", [_dec5], Object.getOwnPropertyDescriptor(_obj, "topicPinned"), _obj), _applyDecoratedDescriptor(_obj, "topicUnpinned", [_dec6], Object.getOwnPropertyDescriptor(_obj, "topicUnpinned"), _obj), _applyDecoratedDescriptor(_obj, "topicInvisible", [_dec7], Object.getOwnPropertyDescriptor(_obj, "topicInvisible"), _obj)), _obj))));