import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './groups-form-membership-fields.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { not, readOnly } from "@ember/object/computed";
import AssociatedGroup from "discourse/models/associated-group";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./groups-form-membership-fields.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("model.visibility_level", "model.public_admission"), _dec2 = discourseComputed("model.visibility_level", "model.allow_membership_requests"), (_obj = {
  tokenSeparator: "|",
  showAssociatedGroups: readOnly("site.can_associate_groups"),
  init() {
    this._super(...arguments);
    this.set("trustLevelOptions", [{
      name: I18n.t("admin.groups.manage.membership.trust_levels_none"),
      value: 0
    }, {
      name: 1,
      value: 1
    }, {
      name: 2,
      value: 2
    }, {
      name: 3,
      value: 3
    }, {
      name: 4,
      value: 4
    }]);
    if (this.showAssociatedGroups) {
      this.loadAssociatedGroups();
    }
  },
  canEdit: not("model.automatic"),
  groupTrustLevel: computed("model.grant_trust_level", "trustLevelOptions", function () {
    return this.model.get("grant_trust_level") || this.trustLevelOptions.firstObject.value;
  }),
  disableMembershipRequestSetting(visibility_level, publicAdmission) {
    visibility_level = parseInt(visibility_level, 10);
    return publicAdmission || visibility_level > 1;
  },
  disablePublicSetting(visibility_level, allowMembershipRequests) {
    visibility_level = parseInt(visibility_level, 10);
    return allowMembershipRequests || visibility_level > 1;
  },
  emailDomains: computed("model.emailDomains", function () {
    return this.model.emailDomains.split(this.tokenSeparator).filter(Boolean);
  }),
  loadAssociatedGroups() {
    AssociatedGroup.list().then(ags => this.set("associatedGroups", ags));
  },
  actions: {
    onChangeEmailDomainsSetting(value) {
      this.set("model.automatic_membership_email_domains", value.join(this.tokenSeparator));
    }
  }
}, (_applyDecoratedDescriptor(_obj, "disableMembershipRequestSetting", [_dec], Object.getOwnPropertyDescriptor(_obj, "disableMembershipRequestSetting"), _obj), _applyDecoratedDescriptor(_obj, "disablePublicSetting", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disablePublicSetting"), _obj)), _obj))));