var _TopicParticipant;
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { gt } from "truth-helpers";
import UserAvatarFlair from "discourse/components/user-avatar-flair";
import { userPath } from "discourse/lib/url";
import { avatarImg } from "discourse-common/lib/avatar-utils";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const addTopicParticipantClassesCallbacks = [];
export function addTopicParticipantClassesCallback(callback1) {
  addTopicParticipantClassesCallbacks.push(callback1);
}
export default class TopicParticipant extends Component {
  get avatarImage() {
    return htmlSafe(avatarImg({
      avatarTemplate: this.args.participant.avatar_template,
      size: "medium",
      title: this.args.participant.name || this.args.participant.username
    }));
  }
  get participantClasses() {
    const {
      primary_group_name: primary_group_name1
    } = this.args.participant;
    return [primary_group_name1 ? `group-${primary_group_name1}` : null, addTopicParticipantClassesCallbacks.map(callback1 => callback1(this.args.participant))].filter(Boolean).flat(3).join(" ");
  }
  get linkClasses() {
    return ["poster", "trigger-user-card", this.args.toggledUsers?.has(this.args.participant.username) ? "toggled" : null].filter(Boolean).join(" ");
  }
  get userUrl() {
    return userPath(this.args.participant.username);
  }
}
_TopicParticipant = TopicParticipant;
setComponentTemplate(createTemplateFactory(
/*
  
    <div class={{this.participantClasses}}>
      <a
        class={{this.linkClasses}}
        data-user-card={{@participant.username}}
        title={{@participant.username}}
        href={{this.userUrl}}
      >
        {{this.avatarImage}}
        {{#if (gt @participant.post_count 1)}}
          <span class="post-count">{{@participant.post_count}}</span>
        {{/if}}
        <UserAvatarFlair @user={{@participant}} />
      </a>
    </div>
  
*/
{
  "id": "LAQq/slz",
  "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"participantClasses\"]]],[12],[1,\"\\n      \"],[10,3],[15,0,[30,0,[\"linkClasses\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[15,\"title\",[30,1,[\"username\"]]],[15,6,[30,0,[\"userUrl\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"avatarImage\"]]],[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"post_count\"]],1],null],[[[1,\"          \"],[10,1],[14,0,\"post-count\"],[12],[1,[30,1,[\"post_count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@participant\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/topic-participant.js",
  "scope": () => [gt, UserAvatarFlair],
  "isStrictMode": true
}), _TopicParticipant);