import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import EmberObject from "@ember/object";
import Mixin from "@ember/object/mixin";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Mixin.create((_dec = discourseComputed(), _dec2 = discourseComputed("accountName", "forceValidationReason"), (_obj = {
  nameInstructions() {
    return I18n.t(this.siteSettings.full_name_required ? "user.name.instructions_required" : "user.name.instructions");
  },
  nameValidation(accountName, forceValidationReason) {
    if (this.siteSettings.full_name_required && isEmpty(accountName)) {
      return EmberObject.create({
        failed: true,
        ok: false,
        message: I18n.t("user.name.required"),
        reason: forceValidationReason ? I18n.t("user.name.required") : null,
        element: document.querySelector("#new-account-name")
      });
    }
    return EmberObject.create({
      ok: true
    });
  }
}, (_applyDecoratedDescriptor(_obj, "nameInstructions", [_dec], Object.getOwnPropertyDescriptor(_obj, "nameInstructions"), _obj), _applyDecoratedDescriptor(_obj, "nameValidation", [_dec2], Object.getOwnPropertyDescriptor(_obj, "nameValidation"), _obj)), _obj)));