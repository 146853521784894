import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './discourse-tag-bound.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./discourse-tag-bound.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("tagRecord.id"), _dec2 = discourseComputed("tagRecord.id"), (_obj = {
  tagName: "a",
  classNameBindings: [":discourse-tag", "style", "tagClass"],
  attributeBindings: ["href"],
  tagClass(tagRecordId) {
    return "tag-" + tagRecordId;
  },
  href(tagRecordId) {
    return getURL("/tag/" + tagRecordId);
  }
}, (_applyDecoratedDescriptor(_obj, "tagClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "tagClass"), _obj), _applyDecoratedDescriptor(_obj, "href", [_dec2], Object.getOwnPropertyDescriptor(_obj, "href"), _obj)), _obj))));