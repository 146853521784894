import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './related-messages.hbs'; */
import Component from "@glimmer/component";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import TEMPLATE from "./related-messages.hbs";
import { setComponentTemplate } from "@ember/component";
let RelatedMessages = (_dec = computed("moreTopicsPreferenceTracking.selectedTab"), (_class = class RelatedMessages extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "moreTopicsPreferenceTracking", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _defineProperty(this, "listId", "related-Messages");
  }
  get hidden() {
    return this.moreTopicsPreferenceTracking.selectedTab !== this.listId;
  }
  registerList() {
    this.moreTopicsPreferenceTracking.registerTopicList({
      name: I18n.t("related_messages.pill"),
      id: this.listId
    });
  }
  removeList() {
    this.moreTopicsPreferenceTracking.removeTopicList(this.listId);
  }
  get targetUser() {
    const topic = this.args.topic;
    if (!topic || !topic.isPrivateMessage) {
      return;
    }
    const allowedUsers = topic.details.allowed_users;
    if (topic.relatedMessages && topic.relatedMessages.length >= 5 && allowedUsers.length === 2 && topic.details.allowed_groups.length === 0 && allowedUsers.find(u => u.username === this.currentUser.username)) {
      return allowedUsers.find(u => u.username !== this.currentUser.username);
    }
  }
  get searchLink() {
    return getURL(`/search?expanded=true&q=%40${this.targetUser.username}%20in%3Apersonal-direct`);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "moreTopicsPreferenceTracking", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "hidden", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hidden"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerList", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeList", [action], Object.getOwnPropertyDescriptor(_class.prototype, "removeList"), _class.prototype)), _class));
export { RelatedMessages as default };
setComponentTemplate(TEMPLATE, RelatedMessages);