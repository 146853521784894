import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './security-key-form.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import TEMPLATE from "./security-key-form.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  useAnotherMethod(event) {
    event?.preventDefault();
    this.set("showSecurityKey", false);
    this.set("showSecondFactor", true);
    this.set("secondFactorMethod", SECOND_FACTOR_METHODS.TOTP);
  }
}, (_applyDecoratedDescriptor(_obj, "useAnotherMethod", [action], Object.getOwnPropertyDescriptor(_obj, "useAnotherMethod"), _obj)), _obj)));