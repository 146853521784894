import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
import { action } from "@ember/object";
import { service } from "@ember/service";
import { queryParams, resetParams } from "discourse/controllers/discovery/list";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import PreloadStore from "discourse/lib/preload-store";
import { setTopicList } from "discourse/lib/topic-list-tracker";
import { escapeExpression } from "discourse/lib/utilities";
import Category from "discourse/models/category";
import PermissionType from "discourse/models/permission-type";
import { filterQueryParams, findTopicList } from "discourse/routes/build-topic-route";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
const NONE = "none";
const ALL = "all";
let TagShowRoute = (_dec = service("search"), disableImplicitInjections(_class = (_class2 = class TagShowRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "composer", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    _initializerDefineProperty(this, "store", _descriptor4, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor5, this);
    _initializerDefineProperty(this, "searchService", _descriptor6, this);
    _initializerDefineProperty(this, "historyStore", _descriptor7, this);
    _defineProperty(this, "queryParams", queryParams);
    _defineProperty(this, "controllerName", "discovery/list");
    _defineProperty(this, "templateName", "discovery/list");
    _defineProperty(this, "routeConfig", {});
  }
  get navMode() {
    return this.routeConfig.navMode || "latest";
  }
  get noSubcategories() {
    return this.routeConfig.noSubcategories;
  }
  async model(params, transition) {
    const tag = this.store.createRecord("tag", {
      id: escapeExpression(params.tag_id)
    });
    let additionalTags;
    if (params.additional_tags) {
      additionalTags = params.additional_tags.split("/").map(t => {
        return this.store.createRecord("tag", {
          id: escapeExpression(t)
        }).id;
      });
    }
    const filterType = filterTypeForMode(this.navMode);
    let tagNotification;
    if (tag && tag.id !== NONE && this.currentUser && !additionalTags) {
      // If logged in, we should get the tag's user settings
      tagNotification = await this.store.find("tagNotification", tag.id.toLowerCase());
    }
    let category = params.category_slug_path_with_id ? Category.findBySlugPathWithID(params.category_slug_path_with_id) : null;
    const filteredQueryParams = filterQueryParams(transition.to.queryParams, {});
    const topicFilter = this.navMode;
    const tagId = tag ? tag.id.toLowerCase() : NONE;
    let filter;
    if (category) {
      category.setupGroupsAndPermissions();
      filter = `tags/c/${Category.slugFor(category)}/${category.id}`;
      if (this.noSubcategories !== undefined) {
        filter += this.noSubcategories ? `/${NONE}` : `/${ALL}`;
      }
      filter += `/${tagId}/l/${topicFilter}`;
    } else if (additionalTags) {
      filter = `tags/intersection/${tagId}/${additionalTags.join("/")}`;
      if (transition.to.queryParams["category"]) {
        filteredQueryParams["category"] = transition.to.queryParams["category"];
        category = Category.findBySlugPathWithID(transition.to.queryParams["category"]);
      }
    } else {
      filter = `tag/${tagId}/l/${topicFilter}`;
    }
    if (this.noSubcategories === undefined && category?.default_list_filter === "none" && topicFilter === "latest") {
      // TODO: avoid throwing away preload data by redirecting on the server
      PreloadStore.getAndRemove("topic_list");
      return this.router.replaceWith("tags.showCategoryNone", params.category_slug_path_with_id, tagId);
    }
    const list = await findTopicList(this.store, this.topicTrackingState, filter, filteredQueryParams, {
      cached: this.historyStore.isPoppedState
    });
    if (list.topic_list.tags && list.topic_list.tags.length === 1) {
      // Update name of tag (case might be different)
      tag.setProperties({
        id: list.topic_list.tags[0].name,
        staff: list.topic_list.tags[0].staff
      });
    }
    return {
      tag,
      category,
      list,
      additionalTags,
      filterType,
      tagNotification,
      canCreateTopic: list.can_create_topic,
      canCreateTopicOnCategory: category?.permission === PermissionType.FULL,
      canCreateTopicOnTag: !tag.staff || this.currentUser?.staff,
      noSubcategories: this.noSubcategories
    };
  }
  setupController(controller, model) {
    super.setupController(...arguments);
    controller.bulkSelectHelper.clear();
    setTopicList(model.list);
    if (model.category || model.additionalTags) {
      const tagIntersectionSearchContext = {
        type: "tagIntersection",
        tagId: model.tag.id,
        tag: model.tag,
        additionalTags: model.additionalTags || null,
        categoryId: model.category?.id || null,
        category: model.category || null
      };
      this.searchService.searchContext = tagIntersectionSearchContext;
    } else {
      this.searchService.searchContext = model.tag.searchContext;
    }
  }
  titleToken() {
    const filterText = I18n.t(`filters.${this.navMode.replace("/", ".")}.title`);
    const model = this.currentModel;
    const tag = model?.tag?.id;
    if (tag && tag !== NONE) {
      if (model.category) {
        return I18n.t("tagging.filters.with_category", {
          filter: filterText,
          tag: model.tag.id,
          category: model.category.name
        });
      } else {
        return I18n.t("tagging.filters.without_category", {
          filter: filterText,
          tag: model.tag.id
        });
      }
    } else {
      if (model.category) {
        return I18n.t("tagging.filters.untagged_with_category", {
          filter: filterText,
          category: model.category.name
        });
      } else {
        return I18n.t("tagging.filters.untagged_without_category", {
          filter: filterText
        });
      }
    }
  }
  deactivate() {
    super.deactivate(...arguments);
    this.searchService.searchContext = null;
  }
  resetParams() {
    let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    resetParams.call(this, skipParams);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "searchService", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "historyStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "resetParams", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetParams"), _class2.prototype)), _class2)) || _class);
export { TagShowRoute as default };
export function buildTagRoute() {
  let routeConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return class extends TagShowRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "routeConfig", routeConfig);
    }
  };
}