import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const BulkSelectToggle = setComponentTemplate(createTemplateFactory(
/*
  
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />

*/
{
  "id": "E0Eyy4+k",
  "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"bulk-select\"]],[[\"@action\",\"@icon\"],[[30,1,[\"toggleBulkSelect\"]],\"list\"]],null],[1,\"\\n\"]],[\"@bulkSelectHelper\"],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/bulk-select-toggle.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), templateOnly());
export default BulkSelectToggle;