import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller from "@ember/controller";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("settings.reviewable_score_types"), (_obj = {
  saving: false,
  saved: false,
  actions: {
    save() {
      let priorities = {};
      this.scoreTypes.forEach(st => {
        priorities[st.id] = parseFloat(st.reviewable_priority);
      });
      this.set("saving", true);
      ajax("/review/settings", {
        type: "PUT",
        data: {
          reviewable_priorities: priorities
        }
      }).then(() => {
        this.set("saved", true);
      }).catch(popupAjaxError).finally(() => this.set("saving", false));
    }
  },
  scoreTypes(types) {
    const username = I18n.t("review.example_username");
    return types.map(type => ({
      ...type,
      title: type.title.replace("%{username}", username)
    }));
  }
}, (_applyDecoratedDescriptor(_obj, "scoreTypes", [_dec], Object.getOwnPropertyDescriptor(_obj, "scoreTypes"), _obj)), _obj)));