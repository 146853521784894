import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
import { tracked } from "@glimmer/tracking";
import { formattedReminderTime } from "discourse/lib/bookmark";
import { TIME_SHORTCUT_TYPES } from "discourse/lib/time-shortcut";
import { AUTO_DELETE_PREFERENCES } from "discourse/models/bookmark";
export let BookmarkFormData = (_class = class BookmarkFormData {
  constructor(bookmark) {
    _initializerDefineProperty(this, "selectedDatetime", _descriptor, this);
    _initializerDefineProperty(this, "selectedReminderType", _descriptor2, this);
    _initializerDefineProperty(this, "id", _descriptor3, this);
    _initializerDefineProperty(this, "reminderAt", _descriptor4, this);
    _initializerDefineProperty(this, "autoDeletePreference", _descriptor5, this);
    _initializerDefineProperty(this, "name", _descriptor6, this);
    _initializerDefineProperty(this, "bookmarkableId", _descriptor7, this);
    _initializerDefineProperty(this, "bookmarkableType", _descriptor8, this);
    this.id = bookmark.id;
    this.reminderAt = bookmark.reminder_at;
    this.name = bookmark.name;
    this.bookmarkableId = bookmark.bookmarkable_id;
    this.bookmarkableType = bookmark.bookmarkable_type;
    this.autoDeletePreference = bookmark.auto_delete_preference ?? AUTO_DELETE_PREFERENCES.CLEAR_REMINDER;
  }
  get reminderAtISO() {
    if (this.selectedReminderType === TIME_SHORTCUT_TYPES.NONE) {
      return null;
    }
    if (!this.selectedReminderType || !this.selectedDatetime) {
      if (this.reminderAt) {
        return this.reminderAt.toISOString();
      } else {
        return null;
      }
    }
    return this.selectedDatetime.toISOString();
  }
  get saveData() {
    return {
      reminder_at: this.reminderAtISO,
      name: this.name,
      id: this.id,
      auto_delete_preference: this.autoDeletePreference,
      bookmarkable_id: this.bookmarkableId,
      bookmarkable_type: this.bookmarkableType
    };
  }
  formattedReminder(timezone) {
    return formattedReminderTime(this.reminderAt, timezone);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedDatetime", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedReminderType", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return TIME_SHORTCUT_TYPES.NONE;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "id", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reminderAt", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "autoDeletePreference", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "name", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bookmarkableId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "bookmarkableType", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);