import { on } from "@ember/modifier";
import autosize from "autosize";
import { modifier as modifierFn } from "ember-modifier";
import autoFocus from "discourse/modifiers/auto-focus";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const resize = modifierFn(element1 => {
  autosize(element1);
  return () => autosize.destroy(element1);
});
const ExpandingTextArea = setComponentTemplate(createTemplateFactory(
/*
  
  <textarea
    {{autoFocus}}
    {{resize}}
    {{! deprecated args: }}
    autocorrect={{@autocorrect}}
    class={{@class}}
    maxlength={{@maxlength}}
    name={{@name}}
    rows={{@rows}}
    value={{@value}}
    {{(if @input (modifier on "input" @input))}}
    ...attributes
  ></textarea>

*/
{
  "id": "23u7cgrR",
  "block": "[[[1,\"\\n  \"],[11,\"textarea\"],[16,\"autocorrect\",[30,1]],[16,0,[30,2]],[16,\"maxlength\",[30,3]],[16,3,[30,4]],[16,\"rows\",[30,5]],[16,2,[30,6]],[17,7],[4,[32,0],null,null],[4,[32,1],null,null],[4,[52,[30,8],[50,[32,2],2,[\"input\",[30,8]],null]],null,null],[12],[13],[1,\"\\n\"]],[\"@autocorrect\",\"@class\",\"@maxlength\",\"@name\",\"@rows\",\"@value\",\"&attrs\",\"@input\"],false,[\"if\",\"modifier\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/expanding-text-area.js",
  "scope": () => [autoFocus, resize, on],
  "isStrictMode": true
}), templateOnly());
export default ExpandingTextArea;