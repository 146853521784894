import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj, _init, _init2, _init3, _init4;
/* import __COLOCATED_TEMPLATE__ from './topic-footer-buttons.hbs'; */
import { getOwner } from "@ember/application";
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { alias, or } from "@ember/object/computed";
import { NotificationLevels } from "discourse/lib/notification-levels";
import { getTopicFooterButtons } from "discourse/lib/register-topic-footer-button";
import { getTopicFooterDropdowns } from "discourse/lib/register-topic-footer-dropdown";
import TopicBookmarkManager from "discourse/lib/topic-bookmark-manager";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./topic-footer-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("canSendPms", "topic.isPrivateMessage"), _dec2 = discourseComputed("inlineButtons.[]", "topic.assigned_to_user"), _dec3 = discourseComputed("topic.isPrivateMessage"), _dec4 = discourseComputed("topic.details.notification_level"), _dec5 = discourseComputed("topic.message_archived"), _dec6 = discourseComputed("topic.message_archived"), _dec7 = discourseComputed("topic.message_archived"), _dec8 = discourseComputed("topic.isPrivateMessage"), (_obj = {
  elementId: "topic-footer-buttons",
  attributeBindings: ["role"],
  role: "region",
  canArchive(canSendPms, isPM) {
    return canSendPms && isPM;
  },
  inlineButtons: getTopicFooterButtons(),
  inlineDropdowns: getTopicFooterDropdowns(),
  inlineActionables: computed("inlineButtons.[]", "inlineDropdowns.[]", function () {
    return this.inlineButtons.filterBy("dropdown", false).filterBy("anonymousOnly", false).concat(this.inlineDropdowns).sortBy("priority").reverse();
  }),
  topicBookmarkManager: computed("topic", function () {
    return new TopicBookmarkManager(getOwner(this), this.topic);
  }),
  dropdownButtons(inlineButtons) {
    return inlineButtons.filter(button => button.dropdown);
  },
  showNotificationsButton(isPM) {
    return !isPM || this.canSendPms;
  },
  showNotificationUserTip(notificationLevel) {
    return notificationLevel >= NotificationLevels.TRACKING;
  },
  canSendPms: alias("currentUser.can_send_private_messages"),
  canInviteTo: alias("topic.details.can_invite_to"),
  canDefer: alias("currentUser.user_option.enable_defer"),
  inviteDisabled: or("topic.archived", "topic.closed", "topic.deleted"),
  archiveIcon: archived => archived ? "envelope" : "folder",
  archiveTitle: archived => archived ? "topic.move_to_inbox.help" : "topic.archive_message.help",
  archiveLabel: archived => archived ? "topic.move_to_inbox.title" : "topic.archive_message.title",
  showBookmarkLabel: isPM => !isPM
}, (_applyDecoratedDescriptor(_obj, "canArchive", [_dec], Object.getOwnPropertyDescriptor(_obj, "canArchive"), _obj), _applyDecoratedDescriptor(_obj, "dropdownButtons", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dropdownButtons"), _obj), _applyDecoratedDescriptor(_obj, "showNotificationsButton", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showNotificationsButton"), _obj), _applyDecoratedDescriptor(_obj, "showNotificationUserTip", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showNotificationUserTip"), _obj), _applyDecoratedDescriptor(_obj, "archiveIcon", [_dec5], (_init = Object.getOwnPropertyDescriptor(_obj, "archiveIcon"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "archiveTitle", [_dec6], (_init2 = Object.getOwnPropertyDescriptor(_obj, "archiveTitle"), _init2 = _init2 ? _init2.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init2;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "archiveLabel", [_dec7], (_init3 = Object.getOwnPropertyDescriptor(_obj, "archiveLabel"), _init3 = _init3 ? _init3.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init3;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "showBookmarkLabel", [_dec8], (_init4 = Object.getOwnPropertyDescriptor(_obj, "showBookmarkLabel"), _init4 = _init4 ? _init4.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init4;
  }
}), _obj)), _obj))));