import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import TextField from "discourse/components/text-field";
import { on } from "discourse-common/utils/decorators";
export default TextField.extend((_dec = on("init"), (_obj = {
  _init() {
    // Chrome autocomplete is buggy per:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=987293
    // work around issue while leaving a semi useable honeypot for
    // bots that are running full Chrome
    if (navigator.userAgent.includes("Chrome")) {
      this.set("type", "text");
    } else {
      this.set("type", "password");
    }
  }
}, (_applyDecoratedDescriptor(_obj, "_init", [_dec], Object.getOwnPropertyDescriptor(_obj, "_init"), _obj)), _obj)));