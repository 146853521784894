import { get } from "@ember/helper";
import UserFieldConfirm from "./user-fields/confirm";
import UserFieldDropdown from "./user-fields/dropdown";
import UserFieldMultiselect from "./user-fields/multiselect";
import UserFieldText from "./user-fields/text";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const COMPONENTS = {
  confirm: UserFieldConfirm,
  dropdown: UserFieldDropdown,
  multiselect: UserFieldMultiselect,
  text: UserFieldText
};
const UserField = setComponentTemplate(createTemplateFactory(
/*
  
  {{#let (get COMPONENTS @field.field_type) as |Component|}}
    <Component
      @field={{@field}}
      @value={{@value}}
      @validation={{@validation}}
      ...attributes
    />
  {{/let}}

*/
{
  "id": "00CUIlQh",
  "block": "[[[1,\"\\n\"],[44,[[28,[32,0],[[32,1],[30,1,[\"field_type\"]]],null]],[[[1,\"    \"],[8,[30,2],[[17,3]],[[\"@field\",\"@value\",\"@validation\"],[[30,1],[30,4],[30,5]]],null],[1,\"\\n\"]],[2]]]],[\"@field\",\"Component\",\"&attrs\",\"@value\",\"@validation\"],false,[\"let\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-field.js",
  "scope": () => [get, COMPONENTS],
  "isStrictMode": true
}), templateOnly());
export default UserField;