import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
/* import __COLOCATED_TEMPLATE__ from './basic-topic-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias, not } from "@ember/object/computed";
import $ from "jquery";
import discourseComputed, { bind, observes } from "discourse-common/utils/decorators";
import TEMPLATE from "./basic-topic-list.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("topicList.loaded"), _dec2 = observes("topicList.[]"), _dec3 = discourseComputed("topics"), (_obj = {
  loadingMore: alias("topicList.loadingMore"),
  loading: not("loaded"),
  loaded() {
    let topicList = this.topicList;
    if (topicList) {
      return topicList.get("loaded");
    } else {
      return true;
    }
  },
  _topicListChanged() {
    this._initFromTopicList(this.topicList);
  },
  _initFromTopicList(topicList) {
    if (topicList !== null) {
      this.set("topics", topicList.get("topics"));
      this.rerender();
    }
  },
  init() {
    this._super(...arguments);
    const topicList = this.topicList;
    if (topicList) {
      this._initFromTopicList(topicList);
    }
  },
  didInsertElement() {
    this._super(...arguments);
    this.topics.forEach(topic => {
      if (typeof topic.unread_by_group_member !== "undefined") {
        this.messageBus.subscribe(`/private-messages/unread-indicator/${topic.id}`, this.onMessage);
      }
    });
  },
  willDestroyElement() {
    this._super(...arguments);
    this.messageBus.unsubscribe("/private-messages/unread-indicator/*", this.onMessage);
  },
  onMessage(data) {
    const nodeClassList = document.querySelector(`.indicator-topic-${data.topic_id}`).classList;
    nodeClassList.toggle("read", !data.show_indicator);
  },
  showUnreadIndicator(topics) {
    return topics.some(topic => typeof topic.unread_by_group_member !== "undefined");
  },
  click(e) {
    // Mobile basic-topic-list doesn't use the `topic-list-item` view so
    // the event for the topic entrance is never wired up.
    if (this.site.desktopView) {
      return;
    }
    let target = $(e.target);
    if (target.closest(".posts-map").length) {
      const topicId = target.closest("tr").attr("data-topic-id");
      if (topicId) {
        if (target.prop("tagName") !== "A") {
          let targetLinks = target.find("a");
          if (targetLinks.length) {
            target = targetLinks;
          } else {
            targetLinks = target.closest("a");
            if (targetLinks.length) {
              target = targetLinks;
            } else {
              return false;
            }
          }
        }
        const topic = this.topics.findBy("id", parseInt(topicId, 10));
        this.appEvents.trigger("topic-entrance:show", {
          topic,
          position: target.offset()
        });
      }
      return false;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "loaded", [_dec], Object.getOwnPropertyDescriptor(_obj, "loaded"), _obj), _applyDecoratedDescriptor(_obj, "_topicListChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_topicListChanged"), _obj), _applyDecoratedDescriptor(_obj, "onMessage", [bind], Object.getOwnPropertyDescriptor(_obj, "onMessage"), _obj), _applyDecoratedDescriptor(_obj, "showUnreadIndicator", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showUnreadIndicator"), _obj)), _obj))));