import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _LoadingSliderFallbackSpinner;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import bodyClass from "discourse/helpers/body-class";
import hideApplicationFooter from "discourse/helpers/hide-application-footer";
import loadingSpinner from "discourse/helpers/loading-spinner";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let LoadingSliderFallbackSpinner = (_class = (_LoadingSliderFallbackSpinner = class LoadingSliderFallbackSpinner extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loadingSlider", _descriptor, this);
  }
  get shouldDisplay() {
    const {
      mode: mode1,
      loading: loading1,
      stillLoading: stillLoading1
    } = this.loadingSlider;
    return mode1 === "spinner" && loading1 || mode1 === "slider" && stillLoading1;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.shouldDisplay}}
      <div class="route-loading-spinner">{{loadingSpinner}}</div>
      {{bodyClass "has-route-loading-spinner"}}
      {{hideApplicationFooter}}
    {{/if}}
  
*/
{
  "id": "F67bFyve",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"route-loading-spinner\"],[12],[1,[32,0]],[13],[1,\"\\n      \"],[1,[28,[32,1],[\"has-route-loading-spinner\"],null]],[1,\"\\n      \"],[1,[32,2]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/loading-slider-fallback-spinner.js",
  "scope": () => [loadingSpinner, bodyClass, hideApplicationFooter],
  "isStrictMode": true
}), _LoadingSliderFallbackSpinner), _LoadingSliderFallbackSpinner), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loadingSlider", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { LoadingSliderFallbackSpinner as default };