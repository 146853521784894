import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _class;
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let BadgeGrouping = (_dec = discourseComputed("name"), _dec2 = discourseComputed("name"), (_class = class BadgeGrouping extends RestModel {
  i18nNameKey() {
    return this.name.toLowerCase().replace(/\s/g, "_");
  }
  displayName() {
    const i18nKey = `badges.badge_grouping.${this.i18nNameKey}.name`;
    return I18n.t(i18nKey, {
      defaultValue: this.name
    });
  }
}, (_applyDecoratedDescriptor(_class.prototype, "i18nNameKey", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "i18nNameKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "displayName"), _class.prototype)), _class));
export { BadgeGrouping as default };