import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;
/* import __COLOCATED_TEMPLATE__ from './uppy-image-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { or } from "@ember/object/computed";
import { next } from "@ember/runloop";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import $ from "jquery";
import lightbox, { cleanupLightboxes, setupLightboxes } from "discourse/lib/lightbox";
import { authorizesOneOrMoreExtensions } from "discourse/lib/uploads";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./uppy-image-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, (_dec = discourseComputed("siteSettings.enable_experimental_lightbox"), _dec2 = discourseComputed("disabled", "notAllowed"), _dec3 = discourseComputed("currentUser.staff", "siteSettings.{authorized_extensions,authorized_extensions_for_staff}"), _dec4 = discourseComputed("imageUrl", "placeholderUrl"), _dec5 = discourseComputed("placeholderUrl"), _dec6 = discourseComputed("imageUrl"), _dec7 = discourseComputed("imageCDNURL"), _dec8 = discourseComputed("imageUrl"), _dec9 = on("didRender"), _dec10 = on("willDestroyElement"), (_obj = {
  classNames: ["image-uploader"],
  disabled: or("notAllowed", "uploading", "processing"),
  experimentalLightboxEnabled(experimentalLightboxEnabled) {
    return experimentalLightboxEnabled;
  },
  disabledReason(disabled, notAllowed) {
    if (disabled && notAllowed) {
      return I18n.t("post.errors.no_uploads_authorized");
    }
  },
  notAllowed() {
    return !authorizesOneOrMoreExtensions(this.currentUser?.staff, this.siteSettings);
  },
  showingPlaceholder(imageUrl, placeholderUrl) {
    return !imageUrl && placeholderUrl;
  },
  placeholderStyle(url) {
    if (isEmpty(url)) {
      return htmlSafe("");
    }
    return htmlSafe(`background-image: url(${url})`);
  },
  imageCDNURL(url) {
    if (isEmpty(url)) {
      return htmlSafe("");
    }
    return getURLWithCDN(url);
  },
  backgroundStyle(url) {
    return htmlSafe(`background-image: url(${url})`);
  },
  imageBaseName(imageUrl) {
    if (isEmpty(imageUrl)) {
      return;
    }
    return imageUrl.split("/").slice(-1)[0];
  },
  validateUploadedFilesOptions() {
    return {
      imagesOnly: true
    };
  },
  _uppyReady() {
    this._onPreProcessComplete(() => {
      this.set("processing", false);
    });
  },
  uploadDone(upload) {
    this.setProperties({
      imageFilesize: upload.human_filesize,
      imageFilename: upload.original_filename,
      imageWidth: upload.width,
      imageHeight: upload.height
    });

    // the value of the property used for imageUrl should be set
    // in this callback. this should be done in cases where imageUrl
    // is bound to a computed property of the parent component.
    if (this.onUploadDone) {
      this.onUploadDone(upload);
    } else {
      this.set("imageUrl", upload.url);
    }
  },
  _applyLightbox() {
    if (this.experimentalLightboxEnabled) {
      setupLightboxes({
        container: this.element,
        selector: ".lightbox"
      });
    } else {
      next(() => lightbox(this.element, this.siteSettings));
    }
  },
  _closeOnRemoval() {
    if (this.experimentalLightboxEnabled) {
      cleanupLightboxes();
    } else {
      if ($.magnificPopup?.instance) {
        $.magnificPopup.instance.close();
      }
    }
  },
  toggleLightbox() {
    $(this.element.querySelector("a.lightbox"))?.magnificPopup("open");
  },
  actions: {
    trash() {
      // uppy needs to be reset to allow for more uploads
      this._reset();

      // the value of the property used for imageUrl should be cleared
      // in this callback. this should be done in cases where imageUrl
      // is bound to a computed property of the parent component.
      if (this.onUploadDeleted) {
        this.onUploadDeleted();
      } else {
        this.setProperties({
          imageUrl: null
        });
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "experimentalLightboxEnabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "experimentalLightboxEnabled"), _obj), _applyDecoratedDescriptor(_obj, "disabledReason", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disabledReason"), _obj), _applyDecoratedDescriptor(_obj, "notAllowed", [_dec3], Object.getOwnPropertyDescriptor(_obj, "notAllowed"), _obj), _applyDecoratedDescriptor(_obj, "showingPlaceholder", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showingPlaceholder"), _obj), _applyDecoratedDescriptor(_obj, "placeholderStyle", [_dec5], Object.getOwnPropertyDescriptor(_obj, "placeholderStyle"), _obj), _applyDecoratedDescriptor(_obj, "imageCDNURL", [_dec6], Object.getOwnPropertyDescriptor(_obj, "imageCDNURL"), _obj), _applyDecoratedDescriptor(_obj, "backgroundStyle", [_dec7], Object.getOwnPropertyDescriptor(_obj, "backgroundStyle"), _obj), _applyDecoratedDescriptor(_obj, "imageBaseName", [_dec8], Object.getOwnPropertyDescriptor(_obj, "imageBaseName"), _obj), _applyDecoratedDescriptor(_obj, "_applyLightbox", [_dec9], Object.getOwnPropertyDescriptor(_obj, "_applyLightbox"), _obj), _applyDecoratedDescriptor(_obj, "_closeOnRemoval", [_dec10], Object.getOwnPropertyDescriptor(_obj, "_closeOnRemoval"), _obj), _applyDecoratedDescriptor(_obj, "toggleLightbox", [action], Object.getOwnPropertyDescriptor(_obj, "toggleLightbox"), _obj)), _obj))));