import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
/* import __COLOCATED_TEMPLATE__ from './scroller.hbs'; */
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { SCROLLER_HEIGHT, timelineDate } from "discourse/components/topic-timeline/container";
import I18n from "discourse-i18n";
import TEMPLATE from "./scroller.hbs";
import { setComponentTemplate } from "@ember/component";
export default class TopicTimelineScroller extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "style", htmlSafe(`height: ${SCROLLER_HEIGHT}px`));
  }
  get repliesShort() {
    const current = this.args.current;
    const total = this.args.total;
    return I18n.t(`topic.timeline.replies_short`, {
      current,
      total
    });
  }
  get timelineAgo() {
    return timelineDate(this.args.date);
  }
}
setComponentTemplate(TEMPLATE, TopicTimelineScroller);