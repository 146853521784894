import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './badge-card.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import { emojiUnescape, sanitize } from "discourse/lib/text";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./badge-card.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("badge.url", "filterUser", "username"), _dec2 = discourseComputed("count", "badge.grant_count"), _dec3 = discourseComputed("size", "badge.long_description", "badge.description"), _dec4 = discourseComputed("badge.id"), (_obj = {
  size: "medium",
  classNameBindings: [":badge-card", "size", "badge.slug"],
  url(badgeUrl, filterUser, username) {
    return filterUser ? `${badgeUrl}?username=${username}` : badgeUrl;
  },
  displayCount(count, grantCount) {
    if (count == null) {
      return grantCount;
    }
    if (count > 1) {
      return count;
    }
  },
  summary(size, longDescription, description) {
    if (size === "large") {
      if (!isEmpty(longDescription)) {
        return emojiUnescape(sanitize(longDescription));
      }
    }
    return sanitize(description);
  },
  showFavorite(badgeId) {
    return ![1, 2, 3, 4].includes(badgeId);
  }
}, (_applyDecoratedDescriptor(_obj, "url", [_dec], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "displayCount", [_dec2], Object.getOwnPropertyDescriptor(_obj, "displayCount"), _obj), _applyDecoratedDescriptor(_obj, "summary", [_dec3], Object.getOwnPropertyDescriptor(_obj, "summary"), _obj), _applyDecoratedDescriptor(_obj, "showFavorite", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showFavorite"), _obj)), _obj))));