import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import { canDisplayCategory } from "discourse/lib/sidebar/helpers";
import CategorySectionLink from "discourse/lib/sidebar/user/categories-section/category-section-link";
import Category from "discourse/models/category";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export const TOP_SITE_CATEGORIES_TO_SHOW = 5;
let SidebarCommonCategoriesSection = (_class = class SidebarCommonCategoriesSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor3, this);
    _defineProperty(this, "shouldSortCategoriesByDefault", true);
  }
  /**
  * Override in child
  *
  * @returns {Object[]} An array of Category objects
  */
  get categories() {}
  get topSiteCategories() {
    return this.site.categoriesList.filter(category => {
      return !category.parent_category_id && canDisplayCategory(category.id, this.siteSettings);
    }).slice(0, TOP_SITE_CATEGORIES_TO_SHOW);
  }
  get sortedCategories() {
    if (!this.shouldSortCategoriesByDefault) {
      return this.categories;
    }
    let categories = [...this.site.categories];
    if (!this.siteSettings.fixed_category_positions) {
      categories.sort((a, b) => a.name.localeCompare(b.name));
    }
    const categoryIds = this.categories.map(category => category.id);
    return Category.sortCategories(categories).reduce((filteredCategories, category) => {
      if (categoryIds.includes(category.id) && canDisplayCategory(category.id, this.siteSettings)) {
        filteredCategories.push(category);
      }
      return filteredCategories;
    }, []);
  }
  get sectionLinks() {
    return this.sortedCategories.map(category => {
      return new CategorySectionLink({
        category,
        topicTrackingState: this.topicTrackingState,
        currentUser: this.currentUser
      });
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "sectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "sectionLinks"), _class.prototype)), _class);
export { SidebarCommonCategoriesSection as default };