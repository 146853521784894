import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Controller from "@ember/controller";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("invitesCount.total", "invitesCount.pending"), _dec2 = discourseComputed("invitesCount.total", "invitesCount.expired"), _dec3 = discourseComputed("invitesCount.total", "invitesCount.redeemed"), (_obj = {
  pendingLabel(invitesCountTotal, invitesCountPending) {
    if (invitesCountTotal > 0) {
      return I18n.t("user.invited.pending_tab_with_count", {
        count: invitesCountPending
      });
    } else {
      return I18n.t("user.invited.pending_tab");
    }
  },
  expiredLabel(invitesCountTotal, invitesCountExpired) {
    if (invitesCountTotal > 0) {
      return I18n.t("user.invited.expired_tab_with_count", {
        count: invitesCountExpired
      });
    } else {
      return I18n.t("user.invited.expired_tab");
    }
  },
  redeemedLabel(invitesCountTotal, invitesCountRedeemed) {
    if (invitesCountTotal > 0) {
      return I18n.t("user.invited.redeemed_tab_with_count", {
        count: invitesCountRedeemed
      });
    } else {
      return I18n.t("user.invited.redeemed_tab");
    }
  }
}, (_applyDecoratedDescriptor(_obj, "pendingLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "pendingLabel"), _obj), _applyDecoratedDescriptor(_obj, "expiredLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "expiredLabel"), _obj), _applyDecoratedDescriptor(_obj, "redeemedLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "redeemedLabel"), _obj)), _obj)));