import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './change-tags.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TEMPLATE from "./change-tags.hbs";
import { setComponentTemplate } from "@ember/component";
let ChangeTags = (_class = class ChangeTags extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "tags", _descriptor, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tags", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
})), _class);
export { ChangeTags as default };
setComponentTemplate(TEMPLATE, ChangeTags);