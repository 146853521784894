import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { service } from "@ember/service";
import UserMenuItemsList from "discourse/components/user-menu/items-list";
import { ajax } from "discourse/lib/ajax";
import UserMenuReviewableItem from "discourse/lib/user-menu/reviewable-item";
import UserMenuReviewable from "discourse/models/user-menu-reviewable";
import getUrl from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
let UserMenuReviewablesList = (_class = class UserMenuReviewablesList extends UserMenuItemsList {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
  }
  get showAllHref() {
    return getUrl("/review");
  }
  get showAllTitle() {
    return I18n.t("user_menu.reviewable.view_all");
  }
  get itemsCacheKey() {
    return "pending-reviewables";
  }
  fetchItems() {
    return ajax("/review/user-menu-list").then(data => {
      this.currentUser.updateReviewableCount(data.reviewable_count);
      return data.reviewables.map(item => {
        return new UserMenuReviewableItem({
          reviewable: UserMenuReviewable.create(item),
          currentUser: this.currentUser,
          siteSettings: this.siteSettings,
          site: this.site
        });
      });
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { UserMenuReviewablesList as default };