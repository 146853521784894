import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './discourse-linked-text.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./discourse-linked-text.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("text", "textParams"), (_obj = {
  tagName: "span",
  translatedText(text) {
    if (text) {
      return I18n.t(...arguments);
    }
  },
  click(event) {
    if (event.target.tagName.toUpperCase() === "A") {
      this.action(this.actionParam);
    }
    return false;
  }
}, (_applyDecoratedDescriptor(_obj, "translatedText", [_dec], Object.getOwnPropertyDescriptor(_obj, "translatedText"), _obj)), _obj))));