import RenderGlimmer, { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createWidget } from "discourse/widgets/widget";
import { createTemplateFactory } from "@ember/template-factory";
createWidget("small-user-list", {
  tagName: "div.clearfix.small-user-list",
  buildClasses(atts) {
    return atts.listClassName;
  },
  buildAttributes(attrs) {
    const attributes = {
      role: "list"
    };
    if (attrs.ariaLabel) {
      attributes["aria-label"] = attrs.ariaLabel;
    }
    return attributes;
  },
  html(attrs) {
    return [new RenderGlimmer(this, "span.small-user-list-content", createTemplateFactory(
    /*
      <SmallUserList @data={{@data.attrs}}/>
    */
    {
      "id": "Tel2/qRr",
      "block": "[[[8,[39,0],null,[[\"@data\"],[[30,1,[\"attrs\"]]]],null]],[\"@data\"],false,[\"small-user-list\"]]",
      "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/actions-summary.js",
      "isStrictMode": false
    }), {
      attrs
    })];
  }
});
registerWidgetShim("actions-summary", "section.post-actions", createTemplateFactory(
/*
  <ActionsSummary @data={{@data}} /> 
*/
{
  "id": "hHvvcDuy",
  "block": "[[[8,[39,0],null,[[\"@data\"],[[30,1]]],null],[1,\" \"]],[\"@data\"],false,[\"actions-summary\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/actions-summary.js",
  "isStrictMode": false
}));