import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _FeaturedLink;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { extractLinkMeta } from "discourse/lib/render-topic-featured-link";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let FeaturedLink = (_class = (_FeaturedLink = class FeaturedLink extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "header", _descriptor, this);
  }
  get meta() {
    return extractLinkMeta(this.header.topic);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.meta}}
      <a
        class="topic-featured-link"
        rel={{this.meta.rel}}
        target={{this.meta.target}}
        href={{this.meta.href}}
      >
        {{icon "external-link-alt"}}
        {{this.meta.domain}}
      </a>
    {{/if}}
  
*/
{
  "id": "Q1gN2IHu",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"meta\"]],[[[1,\"      \"],[10,3],[14,0,\"topic-featured-link\"],[15,\"rel\",[30,0,[\"meta\",\"rel\"]]],[15,\"target\",[30,0,[\"meta\",\"target\"]]],[15,6,[30,0,[\"meta\",\"href\"]]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"external-link-alt\"],null]],[1,\"\\n        \"],[1,[30,0,[\"meta\",\"domain\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/topic/featured-link.js",
  "scope": () => [icon],
  "isStrictMode": true
}), _FeaturedLink), _FeaturedLink), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "header", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { FeaturedLink as default };