import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-membership-button.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import RequestGroupMembershipForm from "./modal/request-group-membership-form";
import TEMPLATE from "./group-membership-button.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("model.public_admission", "userIsGroupUser"), _dec2 = discourseComputed("model.public_exit", "userIsGroupUser"), _dec3 = discourseComputed("model.allow_membership_requests", "userIsGroupUser"), _dec4 = discourseComputed("model.is_group_user"), (_obj = {
  classNames: ["group-membership-button"],
  appEvents: service(),
  currentUser: service(),
  dialog: service(),
  modal: service(),
  canJoinGroup(publicAdmission, userIsGroupUser) {
    return publicAdmission && !userIsGroupUser;
  },
  canLeaveGroup(publicExit, userIsGroupUser) {
    return publicExit && userIsGroupUser;
  },
  canRequestMembership(allowMembershipRequests, userIsGroupUser) {
    return allowMembershipRequests && !userIsGroupUser;
  },
  userIsGroupUser(isGroupUser) {
    return !!isGroupUser;
  },
  _showLoginModal() {
    this.showLogin();
    cookie("destination_url", window.location.href);
  },
  removeFromGroup() {
    const model = this.model;
    model.leave().then(() => {
      model.set("is_group_user", false);
      this.appEvents.trigger("group:leave", model);
    }).catch(popupAjaxError).finally(() => this.set("updatingMembership", false));
  },
  actions: {
    joinGroup() {
      if (this.currentUser) {
        this.set("updatingMembership", true);
        const group = this.model;
        group.join().then(() => {
          group.set("is_group_user", true);
          this.appEvents.trigger("group:join", group);
        }).catch(popupAjaxError).finally(() => {
          this.set("updatingMembership", false);
        });
      } else {
        this._showLoginModal();
      }
    },
    leaveGroup() {
      this.set("updatingMembership", true);
      if (this.model.public_admission) {
        this.removeFromGroup();
      } else {
        return this.dialog.yesNoConfirm({
          message: I18n.t("groups.confirm_leave"),
          didConfirm: () => this.removeFromGroup(),
          didCancel: () => this.set("updatingMembership", false)
        });
      }
    },
    showRequestMembershipForm() {
      if (this.currentUser) {
        this.modal.show(RequestGroupMembershipForm, {
          model: {
            group: this.model
          }
        });
      } else {
        this._showLoginModal();
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "canJoinGroup", [_dec], Object.getOwnPropertyDescriptor(_obj, "canJoinGroup"), _obj), _applyDecoratedDescriptor(_obj, "canLeaveGroup", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canLeaveGroup"), _obj), _applyDecoratedDescriptor(_obj, "canRequestMembership", [_dec3], Object.getOwnPropertyDescriptor(_obj, "canRequestMembership"), _obj), _applyDecoratedDescriptor(_obj, "userIsGroupUser", [_dec4], Object.getOwnPropertyDescriptor(_obj, "userIsGroupUser"), _obj)), _obj))));