import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
/* import __COLOCATED_TEMPLATE__ from './group-add-members.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { extractError } from "discourse/lib/ajax-error";
import { emailValid } from "discourse/lib/utilities";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-add-members.hbs";
import { setComponentTemplate } from "@ember/component";
let GroupAddMembers = (_class = class GroupAddMembers extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    _initializerDefineProperty(this, "loading", _descriptor3, this);
    _initializerDefineProperty(this, "setOwner", _descriptor4, this);
    _initializerDefineProperty(this, "notifyUsers", _descriptor5, this);
    _initializerDefineProperty(this, "usernamesAndEmails", _descriptor6, this);
    _initializerDefineProperty(this, "flash", _descriptor7, this);
  }
  get title() {
    return I18n.t("groups.add_members.title", {
      group_name: this.args.model.fullName || this.args.model.name
    });
  }
  get usernames() {
    return this.usernamesAndEmails.reject(emailValid).join(",");
  }
  get emails() {
    return this.usernamesAndEmails.filter(emailValid).join(",");
  }
  setUsernamesAndEmails(usernamesAndEmails) {
    this.usernamesAndEmails = usernamesAndEmails;
    if (this.emails) {
      if (!this.usernames) {
        this.notifyUsers = false;
      }
      this.setOwner = false;
    }
  }
  async addMembers() {
    if (isEmpty(this.usernamesAndEmails)) {
      return;
    }
    this.loading = true;
    try {
      if (this.setOwner) {
        await this.args.model.addOwners(this.usernames, true, this.notifyUsers);
      } else {
        await this.args.model.addMembers(this.usernames, true, this.notifyUsers, this.emails);
      }
      this.router.transitionTo("group.members", this.args.model.name, {
        queryParams: {
          ...(this.usernames && {
            filter: this.usernames
          })
        }
      });
      this.args.closeModal();
    } catch (e) {
      this.flash = extractError(e);
    } finally {
      this.loading = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "setOwner", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifyUsers", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "usernamesAndEmails", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "setUsernamesAndEmails", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setUsernamesAndEmails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMembers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "addMembers"), _class.prototype)), _class);
export { GroupAddMembers as default };
setComponentTemplate(TEMPLATE, GroupAddMembers);