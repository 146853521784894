import { Input, setComponentTemplate } from "@ember/component";
import concatClass from "discourse/helpers/concat-class";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const PreferenceCheckbox = setComponentTemplate(createTemplateFactory(
/*
  
  <div class={{concatClass "controls" @class}} ...attributes>
    <label class="checkbox-label">
      <Input @type="checkbox" @checked={{@checked}} disabled={{@disabled}} />

      {{#if @labelCount}}
        {{i18n @labelKey count=@labelCount}}
      {{else}}
        {{i18n @labelKey}}
      {{/if}}
    </label>
  </div>

*/
{
  "id": "aZHyMVdx",
  "block": "[[[1,\"\\n  \"],[11,0],[16,0,[28,[32,0],[\"controls\",[30,1]],null]],[17,2],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[8,[32,1],[[16,\"disabled\",[30,3]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,4]]],null],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"        \"],[1,[28,[32,2],[[30,6]],[[\"count\"],[[30,5]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,2],[[30,6]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@disabled\",\"@checked\",\"@labelCount\",\"@labelKey\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/preference-checkbox.js",
  "scope": () => [concatClass, Input, i18n],
  "isStrictMode": true
}), templateOnly());
export default PreferenceCheckbox;