import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
import Component from "@ember/component";
import { cancel, schedule, throttle } from "@ember/runloop";
import { headerOffset } from "discourse/lib/offset-calculator";
import positioningWorkaround from "discourse/lib/safari-hacks";
import { isiPad } from "discourse/lib/utilities";
import Composer from "discourse/models/composer";
import discourseDebounce from "discourse-common/lib/debounce";
import discourseLater from "discourse-common/lib/later";
import discourseComputed, { bind, observes } from "discourse-common/utils/decorators";
const START_DRAG_EVENTS = ["touchstart", "mousedown"];
const DRAG_EVENTS = ["touchmove", "mousemove"];
const END_DRAG_EVENTS = ["touchend", "mouseup"];
const THROTTLE_RATE = 20;
function mouseYPos(e) {
  return e.clientY || e.touches && e.touches[0] && e.touches[0].clientY;
}
export default Component.extend((_dec = discourseComputed("composer.action"), _dec2 = discourseComputed("currentUser.primary_group_name"), _dec3 = discourseComputed("composer.composeState"), _dec4 = observes("composeState"), _dec5 = observes("composeState", "composer.{action,canEditTopicFeaturedLink}"), (_obj = {
  elementId: "reply-control",
  classNameBindings: ["composer.creatingPrivateMessage:private-message", "composeState", "composer.loading", "prefixedComposerAction", "composer.canEditTitle:edit-title", "composer.createdPost:created-post", "composer.creatingTopic:topic", "composer.whisper:composing-whisper", "composer.sharedDraft:composing-shared-draft", "showPreview:show-preview:hide-preview", "currentUserPrimaryGroupClass"],
  prefixedComposerAction(action) {
    return action ? `composer-action-${action}` : "";
  },
  currentUserPrimaryGroupClass(primaryGroupName) {
    return primaryGroupName && `group-${primaryGroupName}`;
  },
  composeState(composeState) {
    return composeState || Composer.CLOSED;
  },
  keyUp() {
    this.typed();
    const lastKeyUp = new Date();
    this._lastKeyUp = lastKeyUp;

    // One second from now, check to see if the last key was hit when
    // we recorded it. If it was, the user paused typing.
    cancel(this._lastKeyTimeout);
    this._lastKeyTimeout = discourseLater(() => {
      if (lastKeyUp !== this._lastKeyUp) {
        return;
      }
      this.appEvents.trigger("composer:find-similar");
    }, 1000);
  },
  disableFullscreen() {
    if (this.composeState !== Composer.OPEN && positioningWorkaround.blur) {
      positioningWorkaround.blur();
    }
  },
  setupComposerResizeEvents() {
    this.origComposerSize = 0;
    this.lastMousePos = 0;
    START_DRAG_EVENTS.forEach(startDragEvent => {
      this.element.querySelector(".grippie")?.addEventListener(startDragEvent, this.startDragHandler, {
        passive: false
      });
    });
  },
  performDragHandler() {
    this.appEvents.trigger("composer:div-resizing");
    this.element.classList.add("clear-transitions");
    const currentMousePos = mouseYPos(event);
    let size = this.origComposerSize + (this.lastMousePos - currentMousePos);
    size = Math.min(size, window.innerHeight - headerOffset());
    const minHeight = parseInt(getComputedStyle(this.element).minHeight, 10);
    size = Math.max(minHeight, size);
    this.set("composer.composerHeight", `${size}px`);
    this.keyValueStore.set({
      key: "composerHeight",
      value: this.get("composer.composerHeight")
    });
    document.documentElement.style.setProperty("--composer-height", size ? `${size}px` : "");
    this._triggerComposerResized();
  },
  _triggerComposerResized() {
    schedule("afterRender", () => {
      discourseDebounce(this, this.composerResized, 300);
    });
  },
  composerResized() {
    if (!this.element || this.isDestroying || this.isDestroyed) {
      return;
    }
    this.appEvents.trigger("composer:resized");
  },
  startDragHandler(event) {
    event.preventDefault();
    this.origComposerSize = this.element.offsetHeight;
    this.lastMousePos = mouseYPos(event);
    DRAG_EVENTS.forEach(dragEvent => {
      document.addEventListener(dragEvent, this.throttledPerformDrag);
    });
    END_DRAG_EVENTS.forEach(endDragEvent => {
      document.addEventListener(endDragEvent, this.endDragHandler);
    });
    this.appEvents.trigger("composer:resize-started");
  },
  endDragHandler() {
    this.appEvents.trigger("composer:resize-ended");
    DRAG_EVENTS.forEach(dragEvent => {
      document.removeEventListener(dragEvent, this.throttledPerformDrag);
    });
    END_DRAG_EVENTS.forEach(endDragEvent => {
      document.removeEventListener(endDragEvent, this.endDragHandler);
    });
    this.element.classList.remove("clear-transitions");
    this.element.focus();
  },
  throttledPerformDrag(event) {
    event.preventDefault();
    throttle(this, this.performDragHandler, event, THROTTLE_RATE);
  },
  didInsertElement() {
    this._super(...arguments);
    this.setupComposerResizeEvents();
    const triggerOpen = () => {
      if (this.get("composer.composeState") === Composer.OPEN) {
        this.appEvents.trigger("composer:opened");
      }
    };
    triggerOpen();
    this.element.addEventListener("transitionend", event => {
      if (event.propertyName === "height") {
        triggerOpen();
      }
    });
    positioningWorkaround(this.element);
  },
  willDestroyElement() {
    this._super(...arguments);
    START_DRAG_EVENTS.forEach(startDragEvent => {
      this.element.querySelector(".grippie")?.removeEventListener(startDragEvent, this.startDragHandler);
    });
    cancel(this._lastKeyTimeout);
  },
  click() {
    this.openIfDraft();
  },
  keyDown(e) {
    if (document.body.classList.contains("modal-open")) {
      return;
    }
    if (e.key === "Escape") {
      e.preventDefault();
      this.cancelled();
    } else if (e.key === "Enter" && (e.ctrlKey || e.metaKey || isiPad() && e.altKey)) {
      // Ctrl+Enter or Cmd+Enter
      // iPad physical keyboard does not offer Command or Ctrl detection
      // so use Alt+Enter
      e.preventDefault();
      this.save(undefined, e);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "prefixedComposerAction", [_dec], Object.getOwnPropertyDescriptor(_obj, "prefixedComposerAction"), _obj), _applyDecoratedDescriptor(_obj, "currentUserPrimaryGroupClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "currentUserPrimaryGroupClass"), _obj), _applyDecoratedDescriptor(_obj, "composeState", [_dec3], Object.getOwnPropertyDescriptor(_obj, "composeState"), _obj), _applyDecoratedDescriptor(_obj, "disableFullscreen", [_dec4], Object.getOwnPropertyDescriptor(_obj, "disableFullscreen"), _obj), _applyDecoratedDescriptor(_obj, "performDragHandler", [bind], Object.getOwnPropertyDescriptor(_obj, "performDragHandler"), _obj), _applyDecoratedDescriptor(_obj, "_triggerComposerResized", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_triggerComposerResized"), _obj), _applyDecoratedDescriptor(_obj, "startDragHandler", [bind], Object.getOwnPropertyDescriptor(_obj, "startDragHandler"), _obj), _applyDecoratedDescriptor(_obj, "endDragHandler", [bind], Object.getOwnPropertyDescriptor(_obj, "endDragHandler"), _obj), _applyDecoratedDescriptor(_obj, "throttledPerformDrag", [bind], Object.getOwnPropertyDescriptor(_obj, "throttledPerformDrag"), _obj)), _obj)));