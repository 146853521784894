import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import { confirmNotification, context } from "discourse/lib/desktop-notifications";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import KeyValueStore from "discourse/lib/key-value-store";
import { isPushNotificationsSupported, keyValueStore as pushNotificationKeyValueStore, subscribe as subscribePushNotification, unsubscribe as unsubscribePushNotification, userSubscriptionKey as pushNotificationUserSubscriptionKey } from "discourse/lib/push-notifications";
const keyValueStore = new KeyValueStore(context);
const DISABLED = "disabled";
const ENABLED = "enabled";
let DesktopNotificationsService = disableImplicitInjections(_class = (_class2 = class DesktopNotificationsService extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    _initializerDefineProperty(this, "notificationsDisabled", _descriptor4, this);
    _initializerDefineProperty(this, "isEnabledPush", _descriptor5, this);
    this.notificationsDisabled = keyValueStore.getItem("notifications-disabled") === DISABLED;
    this.isEnabledPush = this.currentUser ? pushNotificationKeyValueStore.getItem(pushNotificationUserSubscriptionKey(this.currentUser)) : false;
  }
  get isNotSupported() {
    return typeof window.Notification === "undefined";
  }
  get notificationsPermission() {
    return this.isNotSupported ? "" : Notification.permission;
  }
  setNotificationsDisabled(value) {
    keyValueStore.setItem("notifications-disabled", value);
    this.notificationsDisabled = value === DISABLED;
  }
  get isDeniedPermission() {
    if (this.isNotSupported) {
      return false;
    }
    return this.notificationsPermission === "denied";
  }
  get isGrantedPermission() {
    if (this.isNotSupported) {
      return false;
    }
    return this.notificationsPermission === "granted";
  }
  get isEnabledDesktop() {
    if (this.isGrantedPermission) {
      return !this.notificationsDisabled;
    }
    return false;
  }
  setIsEnabledPush(value) {
    const user = this.currentUser;
    if (!user) {
      return false;
    }
    pushNotificationKeyValueStore.setItem(pushNotificationUserSubscriptionKey(user), value);
    this.isEnabledPush = pushNotificationKeyValueStore.getItem(pushNotificationUserSubscriptionKey(user));
  }
  get isEnabled() {
    return this.isEnabledDesktop || this.isEnabledPush;
  }
  get isSubscribed() {
    if (!this.isEnabled) {
      return false;
    }
    if (this.isPushNotificationsPreferred) {
      return this.isEnabledPush === "subscribed";
    } else {
      return !this.notificationsDisabled;
    }
  }
  get isPushNotificationsPreferred() {
    return (this.site.mobileView || this.siteSettings.enable_desktop_push_notifications) && isPushNotificationsSupported();
  }
  async disable() {
    if (this.isEnabledDesktop) {
      this.setNotificationsDisabled(DISABLED);
    }
    if (this.isEnabledPush) {
      await unsubscribePushNotification(this.currentUser, () => {
        this.setIsEnabledPush("");
      });
    }
    return true;
  }
  enable() {
    if (this.isPushNotificationsPreferred) {
      return subscribePushNotification(() => {
        this.setIsEnabledPush("subscribed");
      }, this.siteSettings.vapid_public_key_bytes);
    } else {
      this.setNotificationsDisabled(ENABLED);
      return Notification.requestPermission(permission => {
        confirmNotification(this.siteSettings);
        return permission === "granted";
      });
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "notificationsDisabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isEnabledPush", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "disable", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "disable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enable", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "enable"), _class2.prototype)), _class2)) || _class;
export { DesktopNotificationsService as default };