import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './results.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import CategoryViewComponent from "./results/type/category";
import GroupViewComponent from "./results/type/group";
import PostViewComponent from "./results/type/post";
import TagViewComponent from "./results/type/tag";
import TopicViewComponent from "./results/type/topic";
import UserViewComponent from "./results/type/user";
import TEMPLATE from "./results.hbs";
import { setComponentTemplate } from "@ember/component";
const SEARCH_RESULTS_COMPONENT_TYPE = {
  "search-result-category": CategoryViewComponent,
  "search-result-topic": TopicViewComponent,
  "search-result-post": PostViewComponent,
  "search-result-user": UserViewComponent,
  "search-result-tag": TagViewComponent,
  "search-result-group": GroupViewComponent
};
let Results = (_class = class Results extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "search", _descriptor, this);
    _initializerDefineProperty(this, "searchTopics", _descriptor2, this);
  }
  get renderInitialOptions() {
    return !this.search.activeGlobalSearchTerm && !this.args.inPMInboxContext;
  }
  get noTopicResults() {
    return this.args.searchTopics && this.search.noResults;
  }
  get termTooShort() {
    return this.args.searchTopics && this.args.invalidTerm;
  }
  get resultTypesWithComponent() {
    let content = [];
    this.search.results.resultTypes?.map(resultType => {
      content.push({
        ...resultType,
        component: SEARCH_RESULTS_COMPONENT_TYPE[resultType.componentName]
      });
    });
    return content;
  }
  get searchLogId() {
    return this.search.results.grouped_search_result?.search_log_id;
  }
  updateSearchTopics(value) {
    this.searchTopics = value;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchTopics", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.searchTopics;
  }
}), _applyDecoratedDescriptor(_class.prototype, "updateSearchTopics", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSearchTopics"), _class.prototype)), _class);
export { Results as default };
setComponentTemplate(TEMPLATE, Results);