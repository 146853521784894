import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
import Controller, { inject as controller } from "@ember/controller";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed, { debounce, observes } from "discourse-common/utils/decorators";
export default Controller.extend((_dec = observes("filterInput"), _dec2 = debounce(500), _dec3 = observes("order", "asc", "filter"), _dec4 = discourseComputed("order", "asc", "filter"), _dec5 = discourseComputed("model.requesters.[]"), (_obj = {
  application: controller(),
  queryParams: ["order", "asc", "filter"],
  order: "",
  asc: null,
  filter: null,
  filterInput: null,
  loading: false,
  get canLoadMore() {
    return this.get("model.requesters")?.length < this.get("model.user_count");
  },
  filterInputChanged() {
    this._setFilter();
  },
  _setFilter() {
    this.set("filter", this.filterInput);
  },
  _filtersChanged() {
    this.findRequesters(true);
  },
  findRequesters(refresh) {
    if (this.loading) {
      return;
    }
    const model = this.model;
    if (!model) {
      return;
    }
    if (!refresh && !this.canLoadMore) {
      return;
    }
    this.set("loading", true);
    model.findRequesters(this.memberParams, refresh).finally(() => {
      this.set("loading", false);
    });
  },
  memberParams(order, asc, filter) {
    return {
      order,
      asc,
      filter
    };
  },
  hasRequesters(requesters) {
    return requesters && requesters.length > 0;
  },
  filterPlaceholder() {
    if (this.currentUser && this.currentUser.admin) {
      return "groups.members.filter_placeholder_admin";
    } else {
      return "groups.members.filter_placeholder";
    }
  },
  handleRequest(data) {
    ajax(`/groups/${this.get("model.id")}/handle_membership_request.json`, {
      data,
      type: "PUT"
    }).catch(popupAjaxError);
  },
  actions: {
    loadMore() {
      this.findRequesters();
    },
    acceptRequest(user) {
      this.handleRequest({
        user_id: user.get("id"),
        accept: true
      });
      user.setProperties({
        request_accepted: true,
        request_denied: false
      });
    },
    undoAcceptRequest(user) {
      ajax("/groups/" + this.get("model.id") + "/members.json", {
        type: "DELETE",
        data: {
          user_id: user.get("id")
        }
      }).then(() => {
        user.set("request_undone", true);
      });
    },
    denyRequest(user) {
      this.handleRequest({
        user_id: user.get("id")
      });
      user.setProperties({
        request_accepted: false,
        request_denied: true
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "filterInputChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "filterInputChanged"), _obj), _applyDecoratedDescriptor(_obj, "_setFilter", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_setFilter"), _obj), _applyDecoratedDescriptor(_obj, "_filtersChanged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_filtersChanged"), _obj), _applyDecoratedDescriptor(_obj, "memberParams", [_dec4], Object.getOwnPropertyDescriptor(_obj, "memberParams"), _obj), _applyDecoratedDescriptor(_obj, "hasRequesters", [_dec5], Object.getOwnPropertyDescriptor(_obj, "hasRequesters"), _obj), _applyDecoratedDescriptor(_obj, "filterPlaceholder", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "filterPlaceholder"), _obj)), _obj)));