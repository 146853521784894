import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import Service, { inject as service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import Bookmark from "discourse/models/bookmark";
let BookmarkApi = (_class = class BookmarkApi extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
  buildNewBookmark(bookmarkableType, bookmarkableId) {
    return Bookmark.createFor(this.currentUser, bookmarkableType, bookmarkableId);
  }
  create(bookmarkFormData) {
    return ajax("/bookmarks.json", {
      method: "POST",
      data: bookmarkFormData.saveData
    }).then(response => {
      bookmarkFormData.id = response.id;
      return bookmarkFormData;
    }).catch(popupAjaxError);
  }
  delete(bookmarkId) {
    return ajax(`/bookmarks/${bookmarkId}.json`, {
      method: "DELETE"
    }).catch(popupAjaxError);
  }
  update(bookmarkFormData) {
    return ajax(`/bookmarks/${bookmarkFormData.id}.json`, {
      method: "PUT",
      data: bookmarkFormData.saveData
    }).catch(popupAjaxError);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { BookmarkApi as default };