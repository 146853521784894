import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { tracked } from "@glimmer/tracking";
import RestModel from "discourse/models/rest";
let UserMenuReviewable = (_class = class UserMenuReviewable extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "pending", _descriptor, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pending", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { UserMenuReviewable as default };