import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _CountI18n;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let CountI18n = (_class = (_CountI18n = class CountI18n extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
  get fullKey() {
    let key1 = this.args.key;
    if (this.args.suffix) {
      key1 += this.args.suffix;
    }
    if (this.currentUser?.new_new_view_enabled && key1 === "topic_count_new") {
      key1 = "topic_count_latest";
    }
    return key1;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <span>{{htmlSafe (i18n this.fullKey count=@count)}}</span>
  
*/
{
  "id": "WCsMU36v",
  "block": "[[[1,\"\\n    \"],[10,1],[12],[1,[28,[32,0],[[28,[32,1],[[30,0,[\"fullKey\"]]],[[\"count\"],[[30,1]]]]],null]],[13],[1,\"\\n  \"]],[\"@count\"],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/count-i18n.js",
  "scope": () => [htmlSafe, i18n],
  "isStrictMode": true
}), _CountI18n), _CountI18n), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { CountI18n as default };