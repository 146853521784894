import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './navigation.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import ReorderCategories from "discourse/components/modal/reorder-categories";
import { calculateFilterMode } from "discourse/lib/filter-mode";
import { TRACKED_QUERY_PARAM_VALUE } from "discourse/lib/topic-list-tracked-filter";
import DiscourseURL from "discourse/lib/url";
import Category from "discourse/models/category";
import TEMPLATE from "./navigation.hbs";
import { setComponentTemplate } from "@ember/component";
let DiscoveryNavigation = (_class = class DiscoveryNavigation extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "modal", _descriptor3, this);
  }
  get filterMode() {
    return calculateFilterMode({
      category: this.args.category,
      filterType: this.args.filterType,
      noSubcategories: this.args.noSubcategories
    });
  }
  get skipCategoriesNavItem() {
    return this.router.currentRoute.queryParams.f === TRACKED_QUERY_PARAM_VALUE;
  }
  get canCreateTopic() {
    return this.currentUser?.can_create_topic;
  }
  get bodyClass() {
    if (this.args.tag) {
      return ["tags-page", this.args.additionalTags ? "tags-intersection" : null].filter(Boolean).join(" ");
    } else if (this.filterMode === "categories") {
      return "navigation-categories";
    } else if (this.args.category) {
      return "navigation-category";
    } else {
      return "navigation-topics";
    }
  }
  editCategory() {
    DiscourseURL.routeTo(`/c/${Category.slugFor(this.args.category)}/edit`);
  }
  createCategory() {
    this.router.transitionTo("newCategory");
  }
  reorderCategories() {
    this.modal.show(ReorderCategories);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "editCategory", [action], Object.getOwnPropertyDescriptor(_class.prototype, "editCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createCategory", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reorderCategories", [action], Object.getOwnPropertyDescriptor(_class.prototype, "reorderCategories"), _class.prototype)), _class);
export { DiscoveryNavigation as default };
setComponentTemplate(TEMPLATE, DiscoveryNavigation);