import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor, _descriptor2;
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarSectionForm from "discourse/components/modal/sidebar-section-form";
import CommonCommunitySection from "discourse/lib/sidebar/common/community-section/section";
import I18n from "discourse-i18n";
let _class = (_class2 = class _class2 extends CommonCommunitySection {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
    _initializerDefineProperty(this, "navigationMenu", _descriptor2, this);
  }
  moreSectionButtonAction() {
    return this.modal.show(SidebarSectionForm, {
      model: this
    });
  }
  get moreSectionButtonText() {
    return I18n.t(`sidebar.sections.community.edit_section.${this.navigationMenu.isDesktopDropdownMode ? "header_dropdown" : "sidebar"}`);
  }
  get moreSectionButtonIcon() {
    return "pencil-alt";
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "navigationMenu", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "moreSectionButtonAction", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "moreSectionButtonAction"), _class2.prototype)), _class2);
export { _class as default };