import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
/* import __COLOCATED_TEMPLATE__ from './group-default-notifications.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./group-default-notifications.hbs";
import { setComponentTemplate } from "@ember/component";
let GroupDefaultNotifications = (_class = class GroupDefaultNotifications extends Component {
  updateExistingUsers() {
    this.args.model.setUpdateExistingUsers(true);
    this.args.closeModal();
  }
  cancel() {
    this.args.model.setUpdateExistingUsers(false);
    this.args.closeModal();
  }
}, (_applyDecoratedDescriptor(_class.prototype, "updateExistingUsers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateExistingUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
export { GroupDefaultNotifications as default };
setComponentTemplate(TEMPLATE, GroupDefaultNotifications);