import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { action } from "@ember/object";
import { service } from "@ember/service";
import Tag from "discourse/models/tag";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default DiscourseRoute.extend((_obj = {
  router: service(),
  model() {
    return this.store.findAll("tag").then(result => {
      if (result.extras) {
        if (result.extras.categories) {
          result.extras.categories.forEach(category => {
            category.tags = category.tags.map(t => Tag.create(t));
          });
        }
        if (result.extras.tag_groups) {
          result.extras.tag_groups.forEach(tagGroup => {
            tagGroup.tags = tagGroup.tags.map(t => Tag.create(t));
          });
        }
      }
      return result;
    });
  },
  titleToken() {
    return I18n.t("tagging.tags");
  },
  setupController(controller, model) {
    this.controllerFor("tags.index").setProperties({
      model,
      sortProperties: this.siteSettings.tags_sort_alphabetically ? ["id"] : ["totalCount:desc", "id"]
    });
  },
  showTagGroups() {
    this.router.transitionTo("tagGroups");
    return true;
  },
  triggerRefresh() {
    this.refresh();
  }
}, (_applyDecoratedDescriptor(_obj, "showTagGroups", [action], Object.getOwnPropertyDescriptor(_obj, "showTagGroups"), _obj), _applyDecoratedDescriptor(_obj, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj)), _obj));