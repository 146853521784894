import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller from "@ember/controller";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed from "discourse-common/utils/decorators";
export default Controller.extend((_dec = discourseComputed("model.watched_tags.[]", "model.watching_first_post_tags.[]", "model.tracked_tags.[]", "model.muted_tags.[]"), (_obj = {
  init() {
    this._super(...arguments);
    this.saveAttrNames = ["muted_tags", "tracked_tags", "watched_tags", "watching_first_post_tags"];
  },
  selectedTags(watched, watchedFirst, tracked, muted) {
    return [].concat(watched, watchedFirst, tracked, muted).filter(t => t);
  },
  actions: {
    save() {
      this.set("saved", false);
      return this.model.save(this.saveAttrNames).then(() => {
        this.set("saved", true);
      }).catch(popupAjaxError);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "selectedTags", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedTags"), _obj)), _obj)));