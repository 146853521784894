import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
import EmberObject from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default EmberObject.extend((_dec = discourseComputed("topic.like_count", "topic.posts_count"), _dec2 = discourseComputed("topic.replyCount", "ratioText"), _dec3 = discourseComputed("ratio"), _dec4 = discourseComputed("ratioText"), (_obj = {
  tagName: "td",
  ratio(likeCount, postCount) {
    const likes = parseFloat(likeCount);
    const posts = parseFloat(postCount);
    if (posts < 10) {
      return 0;
    }
    return (likes || 0) / posts;
  },
  title(count, ratio) {
    return I18n.messageFormat("posts_likes_MF", {
      count,
      ratio
    }).trim();
  },
  ratioText(ratio) {
    const settings = this.siteSettings;
    if (ratio > settings.topic_post_like_heat_high) {
      return "high";
    }
    if (ratio > settings.topic_post_like_heat_medium) {
      return "med";
    }
    if (ratio > settings.topic_post_like_heat_low) {
      return "low";
    }
    return "";
  },
  likesHeat(ratioText) {
    if (ratioText && ratioText.length) {
      return `heatmap-${ratioText}`;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "ratio", [_dec], Object.getOwnPropertyDescriptor(_obj, "ratio"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec2], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "ratioText", [_dec3], Object.getOwnPropertyDescriptor(_obj, "ratioText"), _obj), _applyDecoratedDescriptor(_obj, "likesHeat", [_dec4], Object.getOwnPropertyDescriptor(_obj, "likesHeat"), _obj)), _obj)));