import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("header-bootstrap-mode", "div.d-header-mode", createTemplateFactory(
/*
  <BootstrapModeNotice />
*/
{
  "id": "LITCK5Kd",
  "block": "[[[8,[39,0],null,null,null]],[],false,[\"bootstrap-mode-notice\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/header-bootstrap-mode.js",
  "isStrictMode": false
}));