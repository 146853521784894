import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2, _descriptor3;
import { tracked } from "@glimmer/tracking";
import Service, { service } from "@ember/service";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
const TOPIC_LIST_PREFERENCE_KEY = "more-topics-list-preference";
let MoreTopicsPreferenceTracking = disableImplicitInjections(_class = (_class2 = class MoreTopicsPreferenceTracking extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "keyValueStore", _descriptor, this);
    _initializerDefineProperty(this, "selectedTab", _descriptor2, this);
    _initializerDefineProperty(this, "topicLists", _descriptor3, this);
    _defineProperty(this, "memoryTab", null);
  }
  init() {
    super.init(...arguments);
    this.memoryTab = this.keyValueStore.get(TOPIC_LIST_PREFERENCE_KEY);
  }
  updatePreference(value) {
    // Don't change the preference when selecting related PMs.
    // It messes with the topics pref.
    const rememberPref = value !== "related-messages";
    if (rememberPref) {
      this.keyValueStore.set({
        key: TOPIC_LIST_PREFERENCE_KEY,
        value
      });
      this.memoryTab = value;
    }
    this.selectedTab = value;
  }
  registerTopicList(item) {
    // We have a preference stored and the list exists.
    if (this.memoryTab && this.memoryTab === item.id) {
      this.selectedTab = item.id;
    }

    // Use the first list as a default. Future lists may override this
    // if they match the stored preference.
    if (!this.selectedTab) {
      this.selectedTab = item.id;
    }
    this.topicLists = [...this.topicLists, item];
  }
  removeTopicList(itemId) {
    this.topicLists = this.topicLists.filter(item => item.id !== itemId);
    if (this.selectedTab === itemId) {
      this.selectedTab = this.topicLists[0]?.id;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "keyValueStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selectedTab", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "topicLists", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
})), _class2)) || _class;
export { MoreTopicsPreferenceTracking as default };