import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
/* import __COLOCATED_TEMPLATE__ from './user-notification-schedule.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import EmberObject, { action } from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./user-notification-schedule.hbs";
const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
const Day = EmberObject.extend((_dec = discourseComputed("model.user_notification_schedule.day_{0,1,2,3,4,5,6}_start_time"), _dec2 = discourseComputed("model.user_notification_schedule.day_{0,1,2,3,4,5,6}_start_time"), _dec3 = discourseComputed("model.user_notification_schedule.day_{0,1,2,3,4,5,6}_end_time"), (_obj = {
  id: null,
  startTimeOptions: null,
  model: null,
  onChangeStartTime(val) {
    this.startingTimeChangedForDay(val);
  },
  onChangeEndTime(val) {
    this.set(`model.user_notification_schedule.day_${this.id}_end_time`, val);
  },
  startTimeValue(schedule) {
    return schedule[`day_${this.id}_start_time`];
  },
  endTimeOptions(schedule) {
    return this.buildEndTimeOptionsFor(schedule[`day_${this.id}_start_time`]);
  },
  endTimeValue(schedule) {
    return schedule[`day_${this.id}_end_time`];
  },
  startingTimeChangedForDay(val) {
    val = parseInt(val, 10);
    this.model.set(`user_notification_schedule.day_${this.id}_start_time`, val);
    if (val !== "-1" && this.model.user_notification_schedule[`day_${this.id}_end_time`] <= val) {
      this.model.set(`user_notification_schedule.day_${this.id}_end_time`, val + 30);
    }
  },
  buildEndTimeOptionsFor(startTime) {
    startTime = parseInt(startTime, 10);
    if (startTime === -1) {
      return null;
    }
    return this.buildTimeOptions(startTime + 30, {
      includeNone: false,
      showMidnight: true
    });
  }
}, (_applyDecoratedDescriptor(_obj, "onChangeStartTime", [action], Object.getOwnPropertyDescriptor(_obj, "onChangeStartTime"), _obj), _applyDecoratedDescriptor(_obj, "onChangeEndTime", [action], Object.getOwnPropertyDescriptor(_obj, "onChangeEndTime"), _obj), _applyDecoratedDescriptor(_obj, "startTimeValue", [_dec], Object.getOwnPropertyDescriptor(_obj, "startTimeValue"), _obj), _applyDecoratedDescriptor(_obj, "endTimeOptions", [_dec2], Object.getOwnPropertyDescriptor(_obj, "endTimeOptions"), _obj), _applyDecoratedDescriptor(_obj, "endTimeValue", [_dec3], Object.getOwnPropertyDescriptor(_obj, "endTimeValue"), _obj)), _obj)));
export default setComponentTemplate(TEMPLATE, Component.extend({
  days: null,
  didInsertElement() {
    this._super(...arguments);
    this.set("startTimeOptions", this.buildTimeOptions(0, {
      includeNone: true,
      showMidnight: false
    }));
    this.set("days", []);
    DAYS.forEach((day, index) => {
      this.days.pushObject(Day.create({
        id: index,
        day,
        model: this.model,
        buildTimeOptions: this.buildTimeOptions,
        startTimeOptions: this.startTimeOptions
      }));
    });
  },
  buildTimeOptions(startAt) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      includeNone: false,
      showMidnight: true
    };
    let timeOptions = [];
    if (opts.includeNone) {
      timeOptions.push({
        name: I18n.t("user.notification_schedule.none"),
        value: -1
      });
    }
    for (let timeInMin = startAt; timeInMin <= 1440; timeInMin += 30) {
      let hours = Math.floor(timeInMin / 60);
      let minutes = timeInMin % 60;
      if (minutes === 0) {
        minutes = "00";
      }
      if (hours === 24) {
        if (opts.showMidnight) {
          timeOptions.push({
            name: I18n.t("user.notification_schedule.midnight"),
            value: 1440
          });
        }
        break;
      }
      timeOptions.push({
        name: moment().set("hour", hours).set("minute", minutes).format("LT"),
        value: timeInMin
      });
    }
    return timeOptions;
  }
}));