import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action } from "@ember/object";
import { service } from "@ember/service";
import GroupDeleteDialog from "discourse/components/dialog-messages/group-delete";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
const Tab = EmberObject.extend({
  init() {
    this._super(...arguments);
    this.setProperties({
      route: this.route || `group.${this.name}`,
      message: I18n.t(`groups.${this.i18nKey || this.name}`)
    });
  }
});
export default Controller.extend((_dec = discourseComputed("showMessages", "model.user_count", "model.request_count", "canManageGroup", "model.allow_membership_requests"), _dec2 = discourseComputed("model.has_messages", "model.is_group_user", "currentUser.can_send_private_messages"), _dec3 = discourseComputed("model.messageable"), _dec4 = discourseComputed("model", "model.automatic"), (_obj = {
  application: controller(),
  dialog: service(),
  currentUser: service(),
  router: service(),
  composer: service(),
  counts: null,
  showing: "members",
  destroying: null,
  showTooltip: false,
  tabs(showMessages, userCount, requestCount, canManageGroup, allowMembershipRequests) {
    const membersTab = Tab.create({
      name: "members",
      route: "group.index",
      icon: "users",
      i18nKey: "members.title",
      count: userCount
    });
    const defaultTabs = [membersTab, Tab.create({
      name: "activity"
    })];
    if (canManageGroup && allowMembershipRequests) {
      defaultTabs.push(Tab.create({
        name: "requests",
        i18nKey: "requests.title",
        icon: "user-plus",
        count: requestCount
      }));
    }
    if (showMessages) {
      defaultTabs.push(Tab.create({
        name: "messages",
        i18nKey: "messages"
      }));
    }
    if (canManageGroup) {
      defaultTabs.push(Tab.create({
        name: "manage",
        i18nKey: "manage.title",
        icon: "wrench"
      }));
    }
    defaultTabs.push(Tab.create({
      name: "permissions",
      i18nKey: "permissions.title"
    }));
    return defaultTabs;
  },
  showMessages(hasMessages, isGroupUser) {
    if (!this.currentUser?.can_send_private_messages) {
      return false;
    }
    if (!hasMessages) {
      return false;
    }
    return isGroupUser || this.currentUser && this.currentUser.admin;
  },
  displayGroupMessageButton(messageable) {
    return this.currentUser && messageable;
  },
  canManageGroup(model, automatic) {
    return this.currentUser && (this.currentUser.canManageGroup(model) || model.can_admin_group && automatic);
  },
  messageGroup() {
    this.composer.openNewMessage({
      recipients: this.get("model.name"),
      hasGroups: true
    });
  },
  destroyGroup() {
    this.set("destroying", true);
    const model = this.model;
    this.dialog.deleteConfirm({
      title: I18n.t("admin.groups.delete_confirm", {
        group: model.name
      }),
      bodyComponent: GroupDeleteDialog,
      bodyComponentModel: model,
      didConfirm: () => {
        model.destroy().catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.dialog.alert(I18n.t("admin.groups.delete_failed"));
        }).then(() => {
          this.router.transitionTo("groups.index");
        }).finally(() => {
          this.set("destroying", false);
        });
      },
      didCancel: () => this.set("destroying", false)
    });
  },
  toggleDeleteTooltip() {
    this.toggleProperty("showTooltip");
  }
}, (_applyDecoratedDescriptor(_obj, "tabs", [_dec], Object.getOwnPropertyDescriptor(_obj, "tabs"), _obj), _applyDecoratedDescriptor(_obj, "showMessages", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showMessages"), _obj), _applyDecoratedDescriptor(_obj, "displayGroupMessageButton", [_dec3], Object.getOwnPropertyDescriptor(_obj, "displayGroupMessageButton"), _obj), _applyDecoratedDescriptor(_obj, "canManageGroup", [_dec4], Object.getOwnPropertyDescriptor(_obj, "canManageGroup"), _obj), _applyDecoratedDescriptor(_obj, "messageGroup", [action], Object.getOwnPropertyDescriptor(_obj, "messageGroup"), _obj), _applyDecoratedDescriptor(_obj, "destroyGroup", [action], Object.getOwnPropertyDescriptor(_obj, "destroyGroup"), _obj), _applyDecoratedDescriptor(_obj, "toggleDeleteTooltip", [action], Object.getOwnPropertyDescriptor(_obj, "toggleDeleteTooltip"), _obj)), _obj)));