import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './save-controls.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { or } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./save-controls.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("model.isSaving"), (_obj = {
  classNames: ["controls", "save-button"],
  buttonDisabled: or("model.isSaving", "saveDisabled"),
  didInsertElement() {
    this._super(...arguments);
    this.set("saved", false);
  },
  savingText(saving) {
    return saving ? "saving" : "save";
  }
}, (_applyDecoratedDescriptor(_obj, "savingText", [_dec], Object.getOwnPropertyDescriptor(_obj, "savingText"), _obj)), _obj))));