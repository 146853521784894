import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './change-timestamp.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { isEmpty } from "@ember/utils";
import DiscourseURL from "discourse/lib/url";
import Topic from "discourse/models/topic";
import I18n from "discourse-i18n";

// Modal related to changing the timestamp of posts
import TEMPLATE from "./change-timestamp.hbs";
import { setComponentTemplate } from "@ember/component";
let ChangeTimestamp = (_class = class ChangeTimestamp extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "saving", _descriptor, this);
    _initializerDefineProperty(this, "date", _descriptor2, this);
    _initializerDefineProperty(this, "time", _descriptor3, this);
    _initializerDefineProperty(this, "flash", _descriptor4, this);
  }
  get createdAt() {
    return moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm:ss");
  }
  get validTimestamp() {
    return moment().diff(this.createdAt, "minutes") < 0;
  }
  get buttonDisabled() {
    return this.saving || this.validTimestamp || isEmpty(this.date);
  }
  async changeTimestamp() {
    this.saving = true;
    try {
      await Topic.changeTimestamp(this.args.model.topic.id, this.createdAt.unix());
      this.args.closeModal();
      next(() => DiscourseURL.routeTo(this.args.model.topic.url));
    } catch {
      this.flash = I18n.t("topic.change_timestamp.error");
    } finally {
      this.saving = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return moment().format("YYYY-MM-DD");
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "time", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "changeTimestamp", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeTimestamp"), _class.prototype)), _class);
export { ChangeTimestamp as default };
setComponentTemplate(TEMPLATE, ChangeTimestamp);