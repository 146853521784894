import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { queryParams, resetParams } from "discourse/controllers/discovery/list";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import { setTopicList } from "discourse/lib/topic-list-tracker";
import { defaultHomepage } from "discourse/lib/utilities";
import Session from "discourse/models/session";
import Site from "discourse/models/site";
import DiscourseRoute from "discourse/routes/discourse";
import { deepEqual } from "discourse-common/lib/object";
import I18n from "discourse-i18n";

// A helper to build a topic route for a filter
export function filterQueryParams(params, defaultParams) {
  const findOpts = {
    ...(defaultParams || {})
  };
  if (params) {
    Object.keys(queryParams).forEach(function (opt) {
      if (!isEmpty(params[opt])) {
        findOpts[opt] = params[opt];
      }
    });
  }
  return findOpts;
}
export async function findTopicList(store, tracking, filter) {
  let filterParams = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  let extras = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  let list;
  const session = Session.current();
  if (extras.cached) {
    const cachedList = session.get("topicList");

    // Try to use the cached version if it exists and is greater than the topics per page
    if (cachedList && cachedList.get("filter") === filter && (cachedList.get("topics.length") || 0) > cachedList.get("per_page") && deepEqual(cachedList.get("listParams"), filterParams)) {
      cachedList.set("loaded", true);
      tracking?.updateTopics(cachedList.get("topics"));
      list = cachedList;
    }
    session.set("topicList", null);
  } else {
    // Clear the cache
    session.setProperties({
      topicList: null
    });
  }
  list ||= await store.findFiltered("topicList", {
    filter,
    params: filterParams
  });
  list.set("listParams", filterParams);
  if (tracking) {
    tracking.sync(list, list.filter, filterParams);
    tracking.trackIncoming(list.filter);
  }
  Session.currentProp("topicList", list);
  if (list.topic_list?.top_tags) {
    if (list.filter.startsWith("c/") || list.filter.startsWith("tags/c/")) {
      Site.currentProp("category_top_tags", list.topic_list.top_tags);
    } else {
      Site.currentProp("top_tags", list.topic_list.top_tags);
    }
  }
  return list;
}
let AbstractTopicRoute = disableImplicitInjections(_class = (_class2 = class AbstractTopicRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "screenTrack", _descriptor, this);
    _initializerDefineProperty(this, "store", _descriptor2, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor3, this);
    _initializerDefineProperty(this, "currentUser", _descriptor4, this);
    _initializerDefineProperty(this, "historyStore", _descriptor5, this);
    _defineProperty(this, "queryParams", queryParams);
    _defineProperty(this, "templateName", "discovery/list");
    _defineProperty(this, "controllerName", "discovery/list");
  }
  async model(data) {
    // attempt to stop early cause we need this to be called before .sync
    this.screenTrack.stop();
    const findOpts = filterQueryParams(data),
      findExtras = {
        cached: this.historyStore.isPoppedState
      };
    const topicListPromise = findTopicList(this.store, this.topicTrackingState, this.routeConfig.filter, findOpts, findExtras);
    return {
      list: await topicListPromise,
      filterType: this.routeConfig.filter.split("/")[0]
    };
  }
  titleToken() {
    if (this.routeConfig.filter === defaultHomepage()) {
      return;
    }
    const filterText = I18n.t("filters." + this.routeConfig.filter.replace("/", ".") + ".title");
    return I18n.t("filters.with_topics", {
      filter: filterText
    });
  }
  setupController(controller, model) {
    super.setupController(...arguments);
    controller.bulkSelectHelper.clear();
    setTopicList(model.list);
  }
  resetParams() {
    let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    resetParams.call(this, skipParams);
  }
  willTransition() {
    if (this.routeConfig.filter === "top" && this.currentUser) {
      this.currentUser.set("user_option.should_be_redirected_to_top", false);
      if (this.currentUser.user_option?.redirected_to_top) {
        this.currentUser.set("user_option.redirected_to_top.reason", null);
      }
    }
    return super.willTransition(...arguments);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "screenTrack", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "historyStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "resetParams", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetParams"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "willTransition", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "willTransition"), _class2.prototype)), _class2)) || _class;
export default function buildTopicRoute(filter) {
  return class extends AbstractTopicRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "routeConfig", {
        filter
      });
    }
  };
}