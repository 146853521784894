import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
/* import __COLOCATED_TEMPLATE__ from './topic-dismiss-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DismissReadModal from "discourse/components/modal/dismiss-read";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./topic-dismiss-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("position"), _dec2 = discourseComputed("position"), _dec3 = discourseComputed("position"), _dec4 = discourseComputed("position", "model.topics.length"), _dec5 = discourseComputed("selectedTopics.length"), _dec6 = discourseComputed("selectedTopics.length"), (_obj = {
  tagName: "",
  classNames: ["topic-dismiss-buttons"],
  currentUser: service(),
  modal: service(),
  position: null,
  selectedTopics: null,
  model: null,
  containerClass(position) {
    return `dismiss-container-${position}`;
  },
  dismissReadId(position) {
    return `dismiss-topics-${position}`;
  },
  dismissNewId(position) {
    return `dismiss-new-${position}`;
  },
  showBasedOnPosition(position, topicCount) {
    if (position !== "top") {
      return topicCount > 5;
    }
    return true;
  },
  dismissLabel(selectedTopicCount) {
    if (selectedTopicCount === 0) {
      return I18n.t("topics.bulk.dismiss_button");
    }
    return I18n.t("topics.bulk.dismiss_button_with_selected", {
      count: selectedTopicCount
    });
  },
  dismissNewLabel(selectedTopicCount) {
    if (this.currentUser?.new_new_view_enabled) {
      return I18n.t("topics.bulk.dismiss_button");
    } else if (selectedTopicCount === 0) {
      return I18n.t("topics.bulk.dismiss_new");
    }
    return I18n.t("topics.bulk.dismiss_new_with_selected", {
      count: selectedTopicCount
    });
  },
  dismissReadPosts() {
    let dismissTitle = "topics.bulk.dismiss_read";
    if (this.selectedTopics.length) {
      dismissTitle = "topics.bulk.dismiss_read_with_selected";
    }
    this.modal.show(DismissReadModal, {
      model: {
        title: dismissTitle,
        count: this.selectedTopics.length,
        dismissRead: this.dismissRead
      }
    });
  }
}, (_applyDecoratedDescriptor(_obj, "containerClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "containerClass"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dismissReadId"), _obj), _applyDecoratedDescriptor(_obj, "dismissNewId", [_dec3], Object.getOwnPropertyDescriptor(_obj, "dismissNewId"), _obj), _applyDecoratedDescriptor(_obj, "showBasedOnPosition", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showBasedOnPosition"), _obj), _applyDecoratedDescriptor(_obj, "dismissLabel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "dismissLabel"), _obj), _applyDecoratedDescriptor(_obj, "dismissNewLabel", [_dec6], Object.getOwnPropertyDescriptor(_obj, "dismissNewLabel"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadPosts", [action], Object.getOwnPropertyDescriptor(_obj, "dismissReadPosts"), _obj)), _obj))));