import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './pending-post.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { resolveAllShortUrls } from "pretty-text/upload-short-url";
import { ajax } from "discourse/lib/ajax";
import { loadOneboxes } from "discourse/lib/load-oneboxes";
import { afterRender } from "discourse-common/utils/decorators";
import TEMPLATE from "./pending-post.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  didRender() {
    this._super(...arguments);
    this._loadOneboxes();
    this._resolveUrls();
  },
  _loadOneboxes() {
    loadOneboxes(this.element, ajax, this.post.topic_id, this.post.category_id, this.siteSettings.max_oneboxes_per_post, true);
  },
  _resolveUrls() {
    resolveAllShortUrls(ajax, this.siteSettings, this.element, this.opts);
  }
}, (_applyDecoratedDescriptor(_obj, "_loadOneboxes", [afterRender], Object.getOwnPropertyDescriptor(_obj, "_loadOneboxes"), _obj), _applyDecoratedDescriptor(_obj, "_resolveUrls", [afterRender], Object.getOwnPropertyDescriptor(_obj, "_resolveUrls"), _obj)), _obj)));