import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './hidden-details.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./hidden-details.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  expand() {
    this.set("expanded", true);
  }
}, (_applyDecoratedDescriptor(_obj, "expand", [action], Object.getOwnPropertyDescriptor(_obj, "expand"), _obj)), _obj)));