import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { action, computed } from "@ember/object";
import I18n from "discourse-i18n";
import DropdownSelectBoxComponent from "select-kit/components/dropdown-select-box";
export default DropdownSelectBoxComponent.extend((_obj = {
  classNames: ["passkey-options-dropdown"],
  selectKitOptions: {
    icon: "wrench",
    showFullTitle: false
  },
  content: computed(function () {
    return [{
      id: "edit",
      icon: "pencil-alt",
      name: I18n.t("user.second_factor.edit")
    }, {
      id: "delete",
      icon: "trash-alt",
      name: I18n.t("user.second_factor.delete")
    }];
  }),
  onChange(id) {
    switch (id) {
      case "edit":
        this.renamePasskey();
        break;
      case "delete":
        this.deletePasskey();
        break;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "onChange", [action], Object.getOwnPropertyDescriptor(_obj, "onChange"), _obj)), _obj));