import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
/* import __COLOCATED_TEMPLATE__ from './avatar-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { isBlank } from "@ember/utils";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./avatar-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, (_dec = discourseComputed("uploading", "uploadedAvatarId"), _dec2 = discourseComputed("user_id"), _dec3 = discourseComputed("uploading", "uploadProgress"), (_obj = {
  type: "avatar",
  tagName: "span",
  imageIsNotASquare: false,
  customAvatarUploaded() {
    return !this.uploading && !isBlank(this.uploadedAvatarId);
  },
  validateUploadedFilesOptions() {
    return {
      imagesOnly: true
    };
  },
  uploadDone(upload) {
    this.setProperties({
      imageIsNotASquare: upload.width !== upload.height,
      uploadedAvatarTemplate: upload.url,
      uploadedAvatarId: upload.id
    });
    this.done();
  },
  data(user_id) {
    return {
      user_id
    };
  },
  uploadLabel() {
    return this.uploading ? `${I18n.t("uploading")} ${this.uploadProgress}%` : I18n.t("upload");
  },
  chooseImage() {
    this.fileInputEl.click();
  }
}, (_applyDecoratedDescriptor(_obj, "customAvatarUploaded", [_dec], Object.getOwnPropertyDescriptor(_obj, "customAvatarUploaded"), _obj), _applyDecoratedDescriptor(_obj, "data", [_dec2], Object.getOwnPropertyDescriptor(_obj, "data"), _obj), _applyDecoratedDescriptor(_obj, "uploadLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "uploadLabel"), _obj), _applyDecoratedDescriptor(_obj, "chooseImage", [action], Object.getOwnPropertyDescriptor(_obj, "chooseImage"), _obj)), _obj))));