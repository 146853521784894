import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class;
import { dasherize, underscore } from "@ember/string";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import Category from "./category";
export const PENDING = 0;
export const APPROVED = 1;
export const REJECTED = 2;
export const IGNORED = 3;
export const DELETED = 4;
let Reviewable = (_dec = discourseComputed("type", "topic"), _dec2 = discourseComputed("resolvedType"), _dec3 = discourseComputed("humanType"), _dec4 = discourseComputed("resolvedType"), _dec5 = discourseComputed("humanNoun"), _dec6 = discourseComputed("category_id"), (_class = class Reviewable extends RestModel {
  static munge(json) {
    // ensure we are not overriding category computed property
    delete json.category;
    return json;
  }
  resolvedType(type, topic) {
    // Display "Queued Topic" if the post will create a topic
    if (type === "ReviewableQueuedPost" && !topic) {
      return "ReviewableQueuedTopic";
    }
    return type;
  }
  humanType(resolvedType) {
    return I18n.t(`review.types.${underscore(resolvedType)}.title`, {
      defaultValue: ""
    });
  }
  humanTypeCssClass(humanType) {
    return "-" + dasherize(humanType);
  }
  humanNoun(resolvedType) {
    return I18n.t(`review.types.${underscore(resolvedType)}.noun`, {
      defaultValue: "reviewable"
    });
  }
  flaggedReviewableContextQuestion(humanNoun) {
    const uniqueReviewableScores = this.reviewable_scores.uniqBy("score_type.type");
    if (uniqueReviewableScores.length === 1) {
      if (uniqueReviewableScores[0].score_type.type === "notify_moderators") {
        return I18n.t("review.context_question.something_else_wrong", {
          reviewable_type: humanNoun
        });
      }
    }
    const listOfQuestions = I18n.listJoiner(uniqueReviewableScores.map(score => score.score_type.title.toLowerCase()).uniq(), I18n.t("review.context_question.delimiter"));
    return I18n.t("review.context_question.is_this_post", {
      reviewable_human_score_types: listOfQuestions,
      reviewable_type: humanNoun
    });
  }
  category() {
    return Category.findById(this.category_id);
  }
  update(updates) {
    // If no changes, do nothing
    if (Object.keys(updates).length === 0) {
      return Promise.resolve();
    }
    let adapter = this.store.adapterFor("reviewable");
    return ajax(`/review/${this.id}?version=${this.version}`, adapter.getPayload("PUT", {
      reviewable: updates
    })).then(updated => {
      updated.payload = Object.assign({}, this.payload || {}, updated.payload || {});
      this.setProperties(updated);
    });
  }
}, (_applyDecoratedDescriptor(_class.prototype, "resolvedType", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "resolvedType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "humanType", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "humanType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "humanTypeCssClass", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "humanTypeCssClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "humanNoun", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "humanNoun"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flaggedReviewableContextQuestion", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "flaggedReviewableContextQuestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "category", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "category"), _class.prototype)), _class));
export { Reviewable as default };