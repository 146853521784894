import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './publish-page.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./publish-page.hbs";
import { setComponentTemplate } from "@ember/component";
const States = {
  initializing: "initializing",
  checking: "checking",
  valid: "valid",
  invalid: "invalid",
  saving: "saving",
  new: "new",
  existing: "existing",
  unpublishing: "unpublishing",
  unpublished: "unpublished"
};
let PublishPageModal = (_class = class PublishPageModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "store", _descriptor, this);
    _initializerDefineProperty(this, "state", _descriptor2, this);
    _initializerDefineProperty(this, "reason", _descriptor3, this);
    _initializerDefineProperty(this, "publishedPage", _descriptor4, this);
    this.store.find("published_page", this.args.model.id).then(page => {
      this.state = States.existing;
      this.publishedPage = page;
    }).catch(this.startNew);
  }
  get initializing() {
    return this.state === States.initializing;
  }
  get checking() {
    return this.state === States.checking;
  }
  get valid() {
    return this.state === States.valid;
  }
  get invalid() {
    return this.state === States.invalid;
  }
  get saving() {
    return this.state === States.saving;
  }
  get new() {
    return this.state === States.new;
  }
  get existing() {
    return this.state === States.existing;
  }
  get unpublishing() {
    return this.state === States.unpublishing;
  }
  get unpublished() {
    return this.state === States.unpublished;
  }
  get disabled() {
    return this.state !== States.valid;
  }
  get showUrl() {
    return this.state === States.valid || this.state === States.saving || this.state === States.existing;
  }
  get showUnpublish() {
    return this.state === States.existing || this.state === States.unpublishing;
  }
  startCheckSlug() {
    if (this.state === States.existing) {
      return;
    }
    this.state = States.checking;
  }
  checkSlug() {
    if (this.state === States.existing) {
      return;
    }
    return ajax("/pub/check-slug", {
      data: {
        slug: this.publishedPage.slug
      }
    }).then(result => {
      if (result.valid_slug) {
        this.state = States.valid;
      } else {
        this.state = States.invalid;
        this.reason = result.reason;
      }
    });
  }
  unpublish() {
    this.state = States.unpublishing;
    return this.publishedPage.destroyRecord().then(() => {
      this.state = States.unpublished;
      this.args.model.set("publishedPage", null);
    }).catch(result => {
      this.state = States.existing;
      popupAjaxError(result);
    });
  }
  publish() {
    this.state = States.saving;
    return this.publishedPage.update(this.publishedPage.getProperties("slug", "public")).then(() => {
      this.state = States.existing;
      this.args.model.set("publishedPage", this.publishedPage);
    }).catch(errResult => {
      popupAjaxError(errResult);
      this.state = States.existing;
    });
  }
  startNew() {
    this.state = States.new;
    this.publishedPage = this.store.createRecord("published_page", this.args.model.getProperties("id", "slug", "public"));
    this.checkSlug();
  }
  onChangePublic(event) {
    this.publishedPage.set("public", event.target.checked);
    if (this.showUnpublish) {
      this.publish();
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return States.initializing;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reason", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "publishedPage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "startCheckSlug", [action], Object.getOwnPropertyDescriptor(_class.prototype, "startCheckSlug"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkSlug", [action], Object.getOwnPropertyDescriptor(_class.prototype, "checkSlug"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unpublish", [action], Object.getOwnPropertyDescriptor(_class.prototype, "unpublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [action], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startNew", [action], Object.getOwnPropertyDescriptor(_class.prototype, "startNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangePublic", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangePublic"), _class.prototype)), _class);
export { PublishPageModal as default };
setComponentTemplate(TEMPLATE, PublishPageModal);