import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './recent-searches.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import User from "discourse/models/user";
import TEMPLATE from "./recent-searches.hbs";
import { setComponentTemplate } from "@ember/component";
let RecentSearches = (_class = class RecentSearches extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    if (this.currentUser && this.siteSettings.log_search_queries && !this.currentUser.recent_searches?.length) {
      this.loadRecentSearches();
    }
  }
  async clearRecent() {
    const result = await User.resetRecentSearches();
    if (result.success) {
      this.currentUser.recent_searches.clear();
    }
  }
  onKeyup(e) {
    if (e.key === "Escape") {
      this.args.closeSearchMenu();
      e.preventDefault();
      return false;
    }
    this.search.handleArrowUpOrDown(e);
  }
  async loadRecentSearches() {
    const result = await User.loadRecentSearches();
    if (result.success && result.recent_searches?.length) {
      this.currentUser.set("recent_searches", result.recent_searches);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "clearRecent", [action], Object.getOwnPropertyDescriptor(_class.prototype, "clearRecent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype)), _class);
export { RecentSearches as default };
setComponentTemplate(TEMPLATE, RecentSearches);