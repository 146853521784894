import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { tracked } from "@glimmer/tracking";
let QuoteState = (_class = class QuoteState {
  constructor() {
    _initializerDefineProperty(this, "postId", _descriptor, this);
    _initializerDefineProperty(this, "buffer", _descriptor2, this);
    _initializerDefineProperty(this, "opts", _descriptor3, this);
  }
  selected(postId, buffer, opts) {
    this.postId = postId;
    this.buffer = buffer;
    this.opts = opts;
  }
  clear() {
    this.buffer = "";
    this.postId = null;
    this.opts = null;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "postId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "buffer", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "opts", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
})), _class);
export { QuoteState as default };