import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './highlighted-search.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import highlightSearch from "discourse/lib/highlight-search";
import TEMPLATE from "./highlighted-search.hbs";
import { setComponentTemplate } from "@ember/component";
let HighlightedSearch = (_class = class HighlightedSearch extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "search", _descriptor, this);
  }
  highlight(element) {
    highlightSearch(element, this.search.activeGlobalSearchTerm);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "highlight", [action], Object.getOwnPropertyDescriptor(_class.prototype, "highlight"), _class.prototype)), _class);
export { HighlightedSearch as default };
setComponentTemplate(TEMPLATE, HighlightedSearch);