import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
let _class = (_class2 = class _class2 extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "q", _descriptor, this);
    _defineProperty(this, "queryParams", ["q"]);
  }
  updateTopicsListQueryParams(queryString) {
    this.q = queryString;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "q", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _applyDecoratedDescriptor(_class2.prototype, "updateTopicsListQueryParams", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateTopicsListQueryParams"), _class2.prototype)), _class2);
export { _class as default };