import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './tag-drop-link.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import DiscourseURL from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./tag-drop-link.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("tagId", "category"), _dec2 = discourseComputed("tagId"), (_obj = {
  tagName: "a",
  classNameBindings: [":tag-badge-wrapper", ":badge-wrapper", ":bullet", "tagClass"],
  attributeBindings: ["href"],
  href(tagId, category) {
    let path;
    if (category) {
      path = "/tags" + category.path + "/" + tagId;
    } else {
      path = "/tag/" + tagId;
    }
    return getURL(path);
  },
  tagClass(tagId) {
    return "tag-" + tagId;
  },
  click(e) {
    e.preventDefault();
    DiscourseURL.routeTo(this.href);
    return true;
  }
}, (_applyDecoratedDescriptor(_obj, "href", [_dec], Object.getOwnPropertyDescriptor(_obj, "href"), _obj), _applyDecoratedDescriptor(_obj, "tagClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "tagClass"), _obj)), _obj))));