import { hash } from "@ember/helper";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const DropdownItem = setComponentTemplate(createTemplateFactory(
/*
  
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>

*/
{
  "id": "SAIBXNdC",
  "block": "[[[1,\"\\n  \"],[11,\"li\"],[24,0,\"dropdown-menu__item\"],[17,1],[12],[18,2,null],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/dropdown-menu.js",
  "isStrictMode": true
}), templateOnly());
const DropdownDivider = setComponentTemplate(createTemplateFactory(
/*
  
  <li ...attributes><hr class="dropdown-menu__divider" /></li>

*/
{
  "id": "e0DdE3by",
  "block": "[[[1,\"\\n  \"],[11,\"li\"],[17,1],[12],[10,\"hr\"],[14,0,\"dropdown-menu__divider\"],[12],[13],[13],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/dropdown-menu.js",
  "isStrictMode": true
}), templateOnly());
const DropdownMenu = setComponentTemplate(createTemplateFactory(
/*
  
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>

*/
{
  "id": "HagqsG82",
  "block": "[[[1,\"\\n  \"],[11,\"ul\"],[24,0,\"dropdown-menu\"],[17,1],[12],[1,\"\\n    \"],[18,2,[[28,[32,0],null,[[\"item\",\"divider\"],[[32,1],[32,2]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/dropdown-menu.js",
  "scope": () => [hash, DropdownItem, DropdownDivider],
  "isStrictMode": true
}), templateOnly());
export default DropdownMenu;