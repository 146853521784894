import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import EmberObject from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default EmberObject.extend((_obj = {
  topicsActive() {
    return this.current === "topics";
  },
  repliesActive() {
    return this.current === "replies";
  },
  allActive() {
    return !this.topicsActive && !this.repliesActive;
  },
  repliesButtonLabel() {
    if (this.newRepliesCount > 0) {
      return I18n.t("filters.new.replies_with_count", {
        count: this.newRepliesCount
      });
    } else {
      return I18n.t("filters.new.replies");
    }
  },
  topicsButtonLabel() {
    if (this.newTopicsCount > 0) {
      return I18n.t("filters.new.topics_with_count", {
        count: this.newTopicsCount
      });
    } else {
      return I18n.t("filters.new.topics");
    }
  },
  staticLabel() {
    if (this.noStaticLabel) {
      return null;
    }
    if (this.newTopicsCount > 0 && this.newRepliesCount > 0) {
      return null;
    }
    if (this.newTopicsCount > 0) {
      return this.topicsButtonLabel;
    } else {
      return this.repliesButtonLabel;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "topicsActive", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "topicsActive"), _obj), _applyDecoratedDescriptor(_obj, "repliesActive", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "repliesActive"), _obj), _applyDecoratedDescriptor(_obj, "allActive", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "allActive"), _obj), _applyDecoratedDescriptor(_obj, "repliesButtonLabel", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "repliesButtonLabel"), _obj), _applyDecoratedDescriptor(_obj, "topicsButtonLabel", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "topicsButtonLabel"), _obj), _applyDecoratedDescriptor(_obj, "staticLabel", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "staticLabel"), _obj)), _obj));