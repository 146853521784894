import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _DStyles;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let DStyles = (_class = (_DStyles = class DStyles extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "session", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
  }
  get categoryColors() {
    return [":root {", ...this.site.categories.map(category1 => `--category-${category1.id}-color: #${category1.color};`), "}"].join("\n");
  }
  get categoryBackgrounds() {
    const css1 = [];
    const darkCss1 = [];
    this.site.categories.forEach(category1 => {
      const lightUrl1 = category1.uploaded_background?.url;
      const darkUrl1 = this.session.defaultColorSchemeIsDark || this.session.darkModeAvailable ? category1.uploaded_background_dark?.url : null;
      const defaultUrl1 = darkUrl1 && this.session.defaultColorSchemeIsDark ? darkUrl1 : lightUrl1;
      if (defaultUrl1) {
        const url1 = getURLWithCDN(defaultUrl1);
        css1.push(`body.category-${category1.fullSlug} { background-image: url(${url1}); }`);
      }
      if (darkUrl1 && defaultUrl1 !== darkUrl1) {
        const url1 = getURLWithCDN(darkUrl1);
        darkCss1.push(`body.category-${category1.fullSlug} { background-image: url(${url1}); }`);
      }
    });
    if (darkCss1.length > 0) {
      css1.push("@media (prefers-color-scheme: dark) {", ...darkCss1, "}");
    }
    return css1.join("\n");
  }
  get categoryBadges() {
    const css1 = [];
    this.site.categories.forEach(category1 => {
      css1.push(`.badge-category[data-category-id="${category1.id}"] { ` + `--category-badge-color: var(--category-${category1.id}-color); ` + `--category-badge-text-color: #${category1.text_color}; ` + `}`);
      if (category1.isParent) {
        css1.push(`.badge-category[data-parent-category-id="${category1.id}"] { ` + `--parent-category-badge-color: var(--category-${category1.id}-color); ` + `}`);
      }
    });
    return css1.join("\n");
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{! template-lint-disable no-forbidden-elements }}
    <style id="d-styles">
      {{#if this.site.categories}}
        {{this.categoryColors}}
        {{this.categoryBackgrounds}}
        {{this.categoryBadges}}
      {{/if}}
    </style>
  
*/
{
  "id": "StAAWmbL",
  "block": "[[[1,\"\\n\"],[1,\"    \"],[10,\"style\"],[14,1,\"d-styles\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"categories\"]],[[[1,\"        \"],[1,[30,0,[\"categoryColors\"]]],[1,\"\\n        \"],[1,[30,0,[\"categoryBackgrounds\"]]],[1,\"\\n        \"],[1,[30,0,[\"categoryBadges\"]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-styles.js",
  "isStrictMode": true
}), _DStyles), _DStyles), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DStyles as default };