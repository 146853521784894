import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './more-link.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseURL from "discourse/lib/url";
import TEMPLATE from "./more-link.hbs";
import { setComponentTemplate } from "@ember/component";
let MoreLink = (_class = class MoreLink extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "search", _descriptor, this);
  }
  get topicResults() {
    const topicResults = this.args.resultTypes.filter(resultType => resultType.type === "topic");
    return topicResults[0];
  }
  get moreUrl() {
    return this.topicResults.moreUrl && this.topicResults.moreUrl();
  }
  transitionToMoreUrl(event) {
    event.preventDefault();
    this.args.closeSearchMenu();
    DiscourseURL.routeTo(this.moreUrl);
    return false;
  }
  moreOfType(type) {
    this.args.updateTypeFilter(type);
    this.args.triggerSearch();
    this.args.closeSearchMenu();
  }
  onKeyup(e) {
    if (e.key === "Escape") {
      this.args.closeSearchMenu();
      e.preventDefault();
      return false;
    }
    this.search.handleArrowUpOrDown(e);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "transitionToMoreUrl", [action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToMoreUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moreOfType", [action], Object.getOwnPropertyDescriptor(_class.prototype, "moreOfType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype)), _class);
export { MoreLink as default };
setComponentTemplate(TEMPLATE, MoreLink);