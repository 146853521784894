import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './jump-to-post.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import TEMPLATE from "./jump-to-post.hbs";
import { setComponentTemplate } from "@ember/component";
let JumpToPost = (_class = class JumpToPost extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "postNumber", _descriptor, this);
    _initializerDefineProperty(this, "postDate", _descriptor2, this);
  }
  get filteredPostsCount() {
    return this.args.model.topic.postStream.filteredPostsCount;
  }
  _jumpToIndex(postsCounts, postNumber) {
    const where = Math.min(postsCounts, Math.max(1, parseInt(postNumber, 10)));
    this.args.model.jumpToIndex(where);
    this.args.closeModal();
  }
  _jumpToDate(date) {
    this.args.model.jumpToDate(date);
    this.args.closeModal();
  }
  jump() {
    if (this.postNumber) {
      this._jumpToIndex(this.filteredPostsCount, this.postNumber);
    } else if (this.postDate) {
      this._jumpToDate(this.postDate);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "postNumber", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "postDate", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "jump", [action], Object.getOwnPropertyDescriptor(_class.prototype, "jump"), _class.prototype)), _class);
export { JumpToPost as default };
setComponentTemplate(TEMPLATE, JumpToPost);