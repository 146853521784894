import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-smtp-email-settings.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import EmberObject, { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import emailProviderDefaultSettings from "discourse/lib/email-provider-default-settings";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import TEMPLATE from "./group-smtp-email-settings.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("form.email_username", "form.email_password", "form.smtp_server", "form.smtp_port"), _dec2 = on("init"), (_obj = {
  tagName: "",
  form: null,
  missingSettings(email_username, email_password, smtp_server, smtp_port) {
    return [email_username, email_password, smtp_server, smtp_port].some(value => isEmpty(value));
  },
  resetSettingsValid() {
    this.set("smtpSettingsValid", false);
  },
  _fillForm() {
    this.set("form", EmberObject.create({
      email_username: this.group.email_username,
      email_password: this.group.email_password,
      email_from_alias: this.group.email_from_alias,
      smtp_server: this.group.smtp_server,
      smtp_port: (this.group.smtp_port || "").toString(),
      smtp_ssl: this.group.smtp_ssl
    }));
  },
  prefillSettings(provider, event) {
    event?.preventDefault();
    this.form.setProperties(emailProviderDefaultSettings(provider, "smtp"));
  },
  testSmtpSettings() {
    const settings = {
      host: this.form.smtp_server,
      port: this.form.smtp_port,
      ssl: this.form.smtp_ssl,
      username: this.form.email_username,
      password: this.form.email_password
    };
    this.set("testingSettings", true);
    this.set("smtpSettingsValid", false);
    return ajax(`/groups/${this.group.id}/test_email_settings`, {
      type: "POST",
      data: Object.assign(settings, {
        protocol: "smtp"
      })
    }).then(() => {
      this.set("smtpSettingsValid", true);
      this.group.setProperties({
        smtp_server: this.form.smtp_server,
        smtp_port: this.form.smtp_port,
        smtp_ssl: this.form.smtp_ssl,
        email_username: this.form.email_username,
        email_from_alias: this.form.email_from_alias,
        email_password: this.form.email_password
      });
    }).catch(popupAjaxError).finally(() => this.set("testingSettings", false));
  }
}, (_applyDecoratedDescriptor(_obj, "missingSettings", [_dec], Object.getOwnPropertyDescriptor(_obj, "missingSettings"), _obj), _applyDecoratedDescriptor(_obj, "resetSettingsValid", [action], Object.getOwnPropertyDescriptor(_obj, "resetSettingsValid"), _obj), _applyDecoratedDescriptor(_obj, "_fillForm", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_fillForm"), _obj), _applyDecoratedDescriptor(_obj, "prefillSettings", [action], Object.getOwnPropertyDescriptor(_obj, "prefillSettings"), _obj), _applyDecoratedDescriptor(_obj, "testSmtpSettings", [action], Object.getOwnPropertyDescriptor(_obj, "testSmtpSettings"), _obj)), _obj))));