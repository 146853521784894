import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './user-avatar-flair.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import autoGroupFlairForUser from "discourse/lib/avatar-flair";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./user-avatar-flair.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("user"), (_obj = {
  tagName: "",
  flair(user) {
    if (!user || !user.flair_group_id) {
      return;
    }
    if (user.flair_url || user.flair_bg_color) {
      return {
        flairName: user.flair_name,
        flairUrl: user.flair_url,
        flairBgColor: user.flair_bg_color,
        flairColor: user.flair_color
      };
    }
    const autoFlairAttrs = autoGroupFlairForUser(this.site, user);
    if (autoFlairAttrs) {
      return {
        flairName: autoFlairAttrs.flair_name,
        flairUrl: autoFlairAttrs.flair_url,
        flairBgColor: autoFlairAttrs.flair_bg_color,
        flairColor: autoFlairAttrs.flair_color
      };
    }
  }
}, (_applyDecoratedDescriptor(_obj, "flair", [_dec], Object.getOwnPropertyDescriptor(_obj, "flair"), _obj)), _obj))));