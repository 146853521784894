import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { changeEmail } from "discourse/lib/user-activation";
import discourseComputed from "discourse-common/utils/decorators";
export default Controller.extend((_dec = discourseComputed("newEmail", "accountCreated.email"), (_obj = {
  router: service(),
  accountCreated: null,
  newEmail: null,
  submitDisabled(newEmail, currentEmail) {
    return newEmail === currentEmail;
  },
  updateNewEmail(email) {
    this.set("newEmail", email);
  },
  async changeEmail() {
    try {
      await changeEmail({
        email: this.newEmail
      });
      this.set("accountCreated.email", this.newEmail);
      this.router.transitionTo("account-created.resent");
    } catch (e) {
      popupAjaxError(e);
    }
  },
  cancel() {
    this.router.transitionTo("account-created.index");
  }
}, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj), _applyDecoratedDescriptor(_obj, "updateNewEmail", [action], Object.getOwnPropertyDescriptor(_obj, "updateNewEmail"), _obj), _applyDecoratedDescriptor(_obj, "changeEmail", [action], Object.getOwnPropertyDescriptor(_obj, "changeEmail"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [action], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj)), _obj)));