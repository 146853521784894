import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _SidebarApiPanels;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import ApiSections from "./api-sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarApiPanels = (_class = (_SidebarApiPanels = class SidebarApiPanels extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  get panelCssClass() {
    return `${this.sidebarState.currentPanel.key}-panel`;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="sidebar-sections {{this.panelCssClass}}">
      <ApiSections @collapsable={{@collapsableSections}} />
    </div>
  
*/
{
  "id": "2iRHdfCo",
  "block": "[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"sidebar-sections \",[30,0,[\"panelCssClass\"]]]]],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@collapsableSections\"],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-panels.js",
  "scope": () => [ApiSections],
  "isStrictMode": true
}), _SidebarApiPanels), _SidebarApiPanels), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarApiPanels as default };