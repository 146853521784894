import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './notification-level.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { empty } from "@ember/object/computed";
import { topicLevels } from "discourse/lib/notification-levels";
import I18n from "discourse-i18n";

// Support for changing the notification level of various topics
import TEMPLATE from "./notification-level.hbs";
import { setComponentTemplate } from "@ember/component";
let NotificationLevel = (_dec = empty("notificationLevelId"), (_class = class NotificationLevel extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "notificationLevelId", null);
    _initializerDefineProperty(this, "disabled", _descriptor, this);
  }
  get notificationLevels() {
    return topicLevels.map(level => ({
      id: level.id.toString(),
      name: I18n.t(`topic.notifications.${level.key}.title`),
      description: I18n.t(`topic.notifications.${level.key}.description`)
    }));
  }
  changeNotificationLevel() {
    this.args.performAndRefresh({
      type: "change_notification_level",
      notification_level_id: this.notificationLevelId
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "changeNotificationLevel", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeNotificationLevel"), _class.prototype)), _class));
export { NotificationLevel as default };
setComponentTemplate(TEMPLATE, NotificationLevel);