import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './ignore-duration-with-username.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { timeShortcuts } from "discourse/lib/time-shortcut";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./ignore-duration-with-username.hbs";
import { setComponentTemplate } from "@ember/component";
let IgnoreDurationModal = (_class = class IgnoreDurationModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "flash", _descriptor2, this);
    _initializerDefineProperty(this, "loading", _descriptor3, this);
    _initializerDefineProperty(this, "ignoredUntil", _descriptor4, this);
    _initializerDefineProperty(this, "ignoredUsername", _descriptor5, this);
    _defineProperty(this, "enableSelection", this.args.model.enableSelection ?? true);
  }
  get timeShortcuts() {
    const timezone = this.currentUser.user_option.timezone;
    const shortcuts = timeShortcuts(timezone);
    return [shortcuts.laterToday(), shortcuts.tomorrow(), shortcuts.laterThisWeek(), shortcuts.thisWeekend(), shortcuts.monday(), shortcuts.twoWeeks(), shortcuts.nextMonth(), shortcuts.twoMonths(), shortcuts.threeMonths(), shortcuts.fourMonths(), shortcuts.sixMonths(), shortcuts.oneYear(), shortcuts.forever()];
  }
  ignore() {
    if (!this.ignoredUntil || !this.ignoredUsername) {
      this.flash = I18n.t("user.user_notifications.ignore_duration_time_frame_required");
      return;
    }
    this.loading = true;
    User.findByUsername(this.ignoredUsername).then(user => {
      user.updateNotificationLevel({
        level: "ignore",
        expiringAt: this.ignoredUntil,
        actingUser: this.args.model.actingUser
      }).then(() => {
        this.args.model.onUserIgnored?.(this.ignoredUsername);
        this.args.closeModal();
      }).catch(popupAjaxError).finally(() => this.loading = false);
    });
  }
  updateIgnoredUsername(selected) {
    this.ignoredUsername = selected.firstObject;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ignoredUntil", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ignoredUsername", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.ignoredUsername;
  }
}), _applyDecoratedDescriptor(_class.prototype, "ignore", [action], Object.getOwnPropertyDescriptor(_class.prototype, "ignore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIgnoredUsername", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateIgnoredUsername"), _class.prototype)), _class);
export { IgnoreDurationModal as default };
setComponentTemplate(TEMPLATE, IgnoreDurationModal);