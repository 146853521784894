import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias } from "@ember/object/computed";
import { prioritizeNameInUx } from "discourse/lib/settings";
import { userPath } from "discourse/lib/url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./user-info.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("user.username"), _dec2 = discourseComputed("user.name"), (_obj = {
  classNameBindings: [":user-info", "size"],
  attributeBindings: ["data-username"],
  size: "small",
  "data-username": alias("user.username"),
  includeLink: true,
  includeAvatar: true,
  didInsertElement() {
    this._super(...arguments);
    this.user?.statusManager?.trackStatus();
  },
  willDestroyElement() {
    this._super(...arguments);
    this.user?.statusManager?.stopTrackingStatus();
  },
  userPath(username) {
    return userPath(username);
  },
  nameFirst(name) {
    return prioritizeNameInUx(name);
  }
}, (_applyDecoratedDescriptor(_obj, "userPath", [_dec], Object.getOwnPropertyDescriptor(_obj, "userPath"), _obj), _applyDecoratedDescriptor(_obj, "nameFirst", [_dec2], Object.getOwnPropertyDescriptor(_obj, "nameFirst"), _obj)), _obj))));