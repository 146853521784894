import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
/* import __COLOCATED_TEMPLATE__ from './delete-themes-confirm.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./delete-themes-confirm.hbs";
import { setComponentTemplate } from "@ember/component";
let DeleteThemesConfirmComponent = (_class = class DeleteThemesConfirmComponent extends Component {
  delete() {
    ajax(`/admin/themes/bulk_destroy.json`, {
      type: "DELETE",
      data: {
        theme_ids: this.args.model.selectedThemesOrComponents.mapBy("id")
      }
    }).then(() => {
      this.args.model.refreshAfterDelete();
      this.args.closeModal();
    }).catch(popupAjaxError);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "delete", [action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class);
export { DeleteThemesConfirmComponent as default };
setComponentTemplate(TEMPLATE, DeleteThemesConfirmComponent);