import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _obj, _init, _init2, _init3, _init4;
/* import __COLOCATED_TEMPLATE__ from './topic-entrance.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { scheduleOnce } from "@ember/runloop";
import { service } from "@ember/service";
import $ from "jquery";
import DiscourseURL from "discourse/lib/url";
import CleansUp from "discourse/mixins/cleans-up";
import discourseComputed, { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./topic-entrance.hbs";
function entranceDate(dt, showTime) {
  const today = new Date();
  if (dt.toDateString() === today.toDateString()) {
    return moment(dt).format(I18n.t("dates.time"));
  }
  if (dt.getYear() === today.getYear()) {
    // No year
    return moment(dt).format(showTime ? I18n.t("dates.long_date_without_year_with_linebreak") : I18n.t("dates.long_no_year_no_time"));
  }
  return moment(dt).format(showTime ? I18n.t("dates.long_date_with_year_with_linebreak") : I18n.t("dates.long_date_with_year_without_time"));
}
export default setComponentTemplate(TEMPLATE, Component.extend(CleansUp, (_dec = discourseComputed("topic.created_at"), _dec2 = discourseComputed("topic.bumped_at"), _dec3 = discourseComputed("createdDate", "bumpedDate"), _dec4 = discourseComputed("createdDate", "showTime"), _dec5 = discourseComputed("bumpedDate", "showTime"), (_obj = {
  router: service(),
  session: service(),
  historyStore: service(),
  elementId: "topic-entrance",
  classNameBindings: ["visible::hidden"],
  topic: null,
  visible: null,
  _position: null,
  _originalActiveElement: null,
  _activeButton: null,
  createdDate: createdAt => new Date(createdAt),
  bumpedDate: bumpedAt => new Date(bumpedAt),
  showTime(createdDate, bumpedDate) {
    return bumpedDate.getTime() - createdDate.getTime() < 1000 * 60 * 60 * 24 * 2;
  },
  topDate: (createdDate, showTime) => entranceDate(createdDate, showTime),
  bottomDate: (bumpedDate, showTime) => entranceDate(bumpedDate, showTime),
  didInsertElement() {
    this._super(...arguments);
    this.appEvents.on("topic-entrance:show", this, "_show");
  },
  _setCSS() {
    const pos = this._position;
    const $self = $(this.element);
    const width = $self.width();
    const height = $self.height();
    pos.left = parseInt(pos.left, 10) - width / 2;
    pos.top = parseInt(pos.top, 10) - height / 2;
    const windowWidth = $(window).width();
    if (pos.left + width > windowWidth) {
      pos.left = windowWidth - width - 15;
    }
    $self.css(pos);
  },
  _escListener(e) {
    if (e.key === "Escape") {
      this.cleanUp();
    } else if (e.key === "Tab") {
      if (this._activeButton === "top") {
        this._jumpBottomButton().focus();
        this._activeButton = "bottom";
        e.preventDefault();
      } else if (this._activeButton === "bottom") {
        this._jumpTopButton().focus();
        this._activeButton = "top";
        e.preventDefault();
      }
    }
  },
  _jumpTopButton() {
    return this.element.querySelector(".jump-top");
  },
  _jumpBottomButton() {
    return this.element.querySelector(".jump-bottom");
  },
  _setupEscListener() {
    document.body.addEventListener("keydown", this._escListener);
  },
  _removeEscListener() {
    document.body.removeEventListener("keydown", this._escListener);
  },
  _trapFocus() {
    this._originalActiveElement = document.activeElement;
    this._jumpTopButton().focus();
    this._activeButton = "top";
  },
  _releaseFocus() {
    if (this._originalActiveElement) {
      this._originalActiveElement.focus();
      this._originalActiveElement = null;
    }
  },
  _applyDomChanges() {
    this._setCSS();
    this._setupEscListener();
    this._trapFocus();
  },
  _show(data) {
    this._position = data.position;
    this.setProperties({
      topic: data.topic,
      visible: true
    });
    scheduleOnce("afterRender", this, this._applyDomChanges);
    $("html").off("mousedown.topic-entrance").on("mousedown.topic-entrance", e => {
      const $target = $(e.target);
      if ($target.prop("id") === "topic-entrance" || $(this.element).has($target).length !== 0) {
        return;
      }
      this.cleanUp();
    });
  },
  cleanUp() {
    this.setProperties({
      topic: null,
      visible: false
    });
    $("html").off("mousedown.topic-entrance");
    this._removeEscListener();
    this._releaseFocus();
  },
  willDestroyElement() {
    this._super(...arguments);
    this.appEvents.off("topic-entrance:show", this, "_show");
  },
  _jumpTo(destination) {
    this.historyStore.set("lastTopicIdViewed", this.topic.id);
    this.cleanUp();
    DiscourseURL.routeTo(destination);
  },
  actions: {
    enterTop() {
      this._jumpTo(this.get("topic.url"));
    },
    enterBottom() {
      this._jumpTo(this.get("topic.lastPostUrl"));
    }
  }
}, (_applyDecoratedDescriptor(_obj, "createdDate", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "createdDate"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "bumpedDate", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "bumpedDate"), _init2 = _init2 ? _init2.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init2;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "showTime", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showTime"), _obj), _applyDecoratedDescriptor(_obj, "topDate", [_dec4], (_init3 = Object.getOwnPropertyDescriptor(_obj, "topDate"), _init3 = _init3 ? _init3.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init3;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "bottomDate", [_dec5], (_init4 = Object.getOwnPropertyDescriptor(_obj, "bottomDate"), _init4 = _init4 ? _init4.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init4;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "_escListener", [bind], Object.getOwnPropertyDescriptor(_obj, "_escListener"), _obj)), _obj))));