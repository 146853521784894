import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './reviewable-flagged-post.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-flagged-post.hbs";
import { setComponentTemplate } from "@ember/component";
let ReviewableFlaggedPost = (_class = class ReviewableFlaggedPost extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "isCollapsed", _descriptor, this);
    _initializerDefineProperty(this, "isLongPost", _descriptor2, this);
    _defineProperty(this, "maxPostHeight", 300);
  }
  toggleContent() {
    this.isCollapsed = !this.isCollapsed;
  }
  calculatePostBodySize(element) {
    if (element?.offsetHeight > this.maxPostHeight) {
      this.isCollapsed = true;
      this.isLongPost = true;
    } else {
      this.isCollapsed = false;
      this.isLongPost = false;
    }
  }
  get collapseButtonProps() {
    if (this.isCollapsed) {
      return {
        label: "review.show_more",
        icon: "chevron-down"
      };
    }
    return {
      label: "review.show_less",
      icon: "chevron-up"
    };
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLongPost", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "toggleContent", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calculatePostBodySize", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "calculatePostBodySize"), _class.prototype)), _class);
export { ReviewableFlaggedPost as default };
setComponentTemplate(TEMPLATE, ReviewableFlaggedPost);