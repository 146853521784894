import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './tag-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { sort } from "@ember/object/computed";
import Category from "discourse/models/category";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./tag-list.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("titleKey"), _dec2 = discourseComputed("categoryId"), _dec3 = discourseComputed("category.fullSlug"), _dec4 = discourseComputed("tagGroupName"), (_obj = {
  classNameBindings: [":tags-list", ":tag-list", "categoryClass", "tagGroupNameClass"],
  isPrivateMessage: false,
  sortedTags: sort("tags", "sortProperties"),
  title(titleKey) {
    return titleKey && I18n.t(titleKey);
  },
  category(categoryId) {
    return categoryId && Category.findById(categoryId);
  },
  categoryClass(slug) {
    return slug && `tag-list-${slug}`;
  },
  tagGroupNameClass(groupName) {
    if (groupName) {
      groupName = groupName.replace(/\s+/g, "-").replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "").toLowerCase();
      return groupName && `tag-group-${groupName}`;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "category", [_dec2], Object.getOwnPropertyDescriptor(_obj, "category"), _obj), _applyDecoratedDescriptor(_obj, "categoryClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "categoryClass"), _obj), _applyDecoratedDescriptor(_obj, "tagGroupNameClass", [_dec4], Object.getOwnPropertyDescriptor(_obj, "tagGroupNameClass"), _obj)), _obj))));