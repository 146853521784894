import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _class2, _descriptor, _descriptor2, _descriptor3;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { NotificationLevels } from "discourse/lib/notification-levels";
import I18n from "discourse-i18n";
let _class = (_dec = computed("model.watched_tags.[]", "model.watching_first_post_tags.[]", "model.tracked_tags.[]", "model.muted_tags.[]"), _dec2 = computed("model.watchedCategories", "model.mutedCategories", "model.watched_tags.[]", "model.muted_tags.[]"), _dec3 = computed("model.watchedCategories", "model.watchedFirstPostCategories", "model.trackedCategories", "model.mutedCategories", "model.regularCategories", "siteSettings.mute_all_categories_by_default"), _dec4 = computed("siteSettings.remove_muted_tags_from_latest"), _dec5 = computed("siteSettings.tagging_enabled", "siteSettings.mute_all_categories_by_default"), (_class2 = class _class2 extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "saved", _descriptor3, this);
    _defineProperty(this, "likeNotificationFrequencies", [{
      name: I18n.t("user.like_notification_frequency.always"),
      value: 0
    }, {
      name: I18n.t("user.like_notification_frequency.first_time_and_daily"),
      value: 1
    }, {
      name: I18n.t("user.like_notification_frequency.first_time"),
      value: 2
    }, {
      name: I18n.t("user.like_notification_frequency.never"),
      value: 3
    }]);
    _defineProperty(this, "autoTrackDurations", [{
      name: I18n.t("user.auto_track_options.never"),
      value: -1
    }, {
      name: I18n.t("user.auto_track_options.immediately"),
      value: 0
    }, {
      name: I18n.t("user.auto_track_options.after_30_seconds"),
      value: 30000
    }, {
      name: I18n.t("user.auto_track_options.after_1_minute"),
      value: 60000
    }, {
      name: I18n.t("user.auto_track_options.after_2_minutes"),
      value: 120000
    }, {
      name: I18n.t("user.auto_track_options.after_3_minutes"),
      value: 180000
    }, {
      name: I18n.t("user.auto_track_options.after_4_minutes"),
      value: 240000
    }, {
      name: I18n.t("user.auto_track_options.after_5_minutes"),
      value: 300000
    }, {
      name: I18n.t("user.auto_track_options.after_10_minutes"),
      value: 600000
    }]);
    _defineProperty(this, "notificationLevelsForReplying", [{
      name: I18n.t("topic.notifications.watching.title"),
      value: NotificationLevels.WATCHING
    }, {
      name: I18n.t("topic.notifications.tracking.title"),
      value: NotificationLevels.TRACKING
    }, {
      name: I18n.t("topic.notifications.regular.title"),
      value: NotificationLevels.REGULAR
    }]);
    _defineProperty(this, "considerNewTopicOptions", [{
      name: I18n.t("user.new_topic_duration.not_viewed"),
      value: -1
    }, {
      name: I18n.t("user.new_topic_duration.after_1_day"),
      value: 60 * 24
    }, {
      name: I18n.t("user.new_topic_duration.after_2_days"),
      value: 60 * 48
    }, {
      name: I18n.t("user.new_topic_duration.after_1_week"),
      value: 7 * 60 * 24
    }, {
      name: I18n.t("user.new_topic_duration.after_2_weeks"),
      value: 2 * 7 * 60 * 24
    }, {
      name: I18n.t("user.new_topic_duration.last_here"),
      value: -2
    }]);
  }
  get canSee() {
    return this.currentUser.id === this.model.id;
  }
  get selectedTags() {
    return [].concat(this.model.watched_tags, this.model.watching_first_post_tags, this.model.tracked_tags, this.model.muted_tags).filter(t => t);
  }
  get showMutePrecedenceSetting() {
    const show = this.model.watchedCategories?.length > 0 && this.model.muted_tags?.length > 0 || this.model.watched_tags?.length > 0 && this.model.mutedCategories?.length > 0;
    if (show && this.model.user_option.watched_precedence_over_muted === null) {
      this.model.user_option.watched_precedence_over_muted = this.siteSettings.watched_precedence_over_muted;
    }
    return show;
  }
  get selectedCategories() {
    return [].concat(this.model.watchedCategories, this.model.watchedFirstPostCategories, this.model.trackedCategories, this.siteSettings.mute_all_categories_by_default ? this.model.regularCategories : this.model.mutedCategories).filter(Boolean);
  }
  get hideMutedTags() {
    return this.siteSettings.remove_muted_tags_from_latest !== "never";
  }
  get canSave() {
    return this.canSee || this.currentUser.admin;
  }
  get saveAttrNames() {
    const attrs = ["new_topic_duration_minutes", "auto_track_topics_after_msecs", "notification_level_when_replying", this.siteSettings.mute_all_categories_by_default ? "regular_category_ids" : "muted_category_ids", "watched_category_ids", "tracked_category_ids", "watched_first_post_category_ids", "watched_precedence_over_muted", "topics_unread_when_closed"];
    if (this.siteSettings.tagging_enabled) {
      attrs.push("muted_tags", "tracked_tags", "watched_tags", "watching_first_post_tags");
    }
    return attrs;
  }
  save() {
    this.saved = false;
    return this.model.save(this.saveAttrNames).then(() => {
      this.saved = true;
    }).catch(popupAjaxError);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "saved", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "selectedTags", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedTags"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showMutePrecedenceSetting", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "showMutePrecedenceSetting"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectedCategories", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedCategories"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hideMutedTags", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hideMutedTags"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveAttrNames", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "saveAttrNames"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2));
export { _class as default };