import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
/* import __COLOCATED_TEMPLATE__ from './create-invite.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { not, readOnly } from "@ember/object/computed";
import { extractError } from "discourse/lib/ajax-error";
import { getNativeContact } from "discourse/lib/pwa-utils";
import { sanitize } from "discourse/lib/text";
import { timeShortcuts } from "discourse/lib/time-shortcut";
import { emailValid, hostnameValid } from "discourse/lib/utilities";
import { bufferedProperty } from "discourse/mixins/buffered-content";
import Group from "discourse/models/group";
import Invite from "discourse/models/invite";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import { FORMAT } from "select-kit/components/future-date-input-selector";
import TEMPLATE from "./create-invite.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(bufferedProperty("invite"), (_dec = discourseComputed("buffered.emailOrDomain"), _dec2 = discourseComputed("buffered.emailOrDomain"), _dec3 = discourseComputed("currentUser.staff", "siteSettings.invite_link_max_redemptions_limit", "siteSettings.invite_link_max_redemptions_limit_users"), _dec4 = discourseComputed("buffered.expires_at"), _dec5 = discourseComputed("currentUser.staff", "currentUser.groups"), _dec6 = discourseComputed("currentUser.staff"), (_obj = {
  allGroups: null,
  topics: null,
  flashText: null,
  flashClass: null,
  flashLink: false,
  editing: readOnly("model.editing"),
  inviteToTopic: false,
  limitToEmail: false,
  isEmail(emailOrDomain) {
    return emailValid(emailOrDomain?.trim());
  },
  isDomain(emailOrDomain) {
    return hostnameValid(emailOrDomain?.trim());
  },
  isLink: not("isEmail"),
  init() {
    this._super();
    Group.findAll().then(groups => {
      this.set("allGroups", groups.filterBy("automatic", false));
    });
    this.set("invite", this.model.invite || Invite.create());
    this.set("topics", this.invite?.topics || this.model.topics || []);
    this.buffered.setProperties({
      max_redemptions_allowed: this.model.invite?.max_redemptions_allowed ?? 1,
      expires_at: this.model.invite?.expires_at ?? moment().add(this.siteSettings.invite_expiry_days, "days").format(FORMAT),
      groupIds: this.model.groupIds ?? this.model.invite?.groupIds,
      topicId: this.model.invite?.topicId,
      topicTitle: this.model.invite?.topicTitle
    });
  },
  save(opts) {
    const data = {
      ...this.buffered.buffer
    };
    if (data.emailOrDomain) {
      if (emailValid(data.emailOrDomain)) {
        data.email = data.emailOrDomain?.trim();
      } else if (hostnameValid(data.emailOrDomain)) {
        data.domain = data.emailOrDomain?.trim();
      }
      delete data.emailOrDomain;
    }
    if (data.groupIds !== undefined) {
      data.group_ids = data.groupIds.length > 0 ? data.groupIds : "";
      delete data.groupIds;
    }
    if (data.topicId !== undefined) {
      data.topic_id = data.topicId;
      delete data.topicId;
      delete data.topicTitle;
    }
    if (this.isLink) {
      if (this.invite.email) {
        data.email = data.custom_message = "";
      }
    } else if (this.isEmail) {
      if (this.invite.max_redemptions_allowed > 1) {
        data.max_redemptions_allowed = 1;
      }
      if (opts.sendEmail) {
        data.send_email = true;
        if (this.inviteToTopic) {
          data.invite_to_topic = true;
        }
      } else {
        data.skip_email = true;
      }
    }
    return this.invite.save(data).then(() => {
      this.rollbackBuffer();
      const invites = this.model?.invites;
      if (invites && !invites.any(i => i.id === this.invite.id)) {
        invites.unshiftObject(this.invite);
      }
      if (this.isEmail && opts.sendEmail) {
        this.closeModal();
      } else {
        this.setProperties({
          flashText: sanitize(I18n.t("user.invited.invite.invite_saved")),
          flashClass: "success",
          flashLink: !this.editing
        });
      }
    }).catch(e => this.setProperties({
      flashText: sanitize(extractError(e)),
      flashClass: "error",
      flashLink: false
    }));
  },
  maxRedemptionsAllowedLimit(staff, staffLimit, usersLimit) {
    return staff ? staffLimit : usersLimit;
  },
  expiresAtLabel(expires_at) {
    const expiresAt = moment(expires_at);
    return expiresAt.isBefore() ? I18n.t("user.invited.invite.expired_at_time", {
      time: expiresAt.format("LLL")
    }) : I18n.t("user.invited.invite.expires_in_time", {
      time: moment.duration(expiresAt - moment()).humanize()
    });
  },
  canInviteToGroup(staff, groups) {
    return staff || groups.any(g => g.owner);
  },
  canArriveAtTopic(staff) {
    if (staff && !this.siteSettings.must_approve_users) {
      return true;
    }
    return false;
  },
  timeShortcuts() {
    const timezone = this.currentUser.user_option.timezone;
    const shortcuts = timeShortcuts(timezone);
    return [shortcuts.laterToday(), shortcuts.tomorrow(), shortcuts.laterThisWeek(), shortcuts.monday(), shortcuts.twoWeeks(), shortcuts.nextMonth(), shortcuts.twoMonths(), shortcuts.threeMonths(), shortcuts.fourMonths(), shortcuts.sixMonths()];
  },
  copied() {
    this.save({
      sendEmail: false,
      copy: true
    });
  },
  saveInvite(sendEmail) {
    this.save({
      sendEmail
    });
  },
  searchContact() {
    getNativeContact(this.capabilities, ["email"], false).then(result => {
      this.set("buffered.email", result[0].email[0]);
    });
  },
  onChangeTopic(topicId, topic) {
    this.set("topics", [topic]);
    this.set("buffered.topicId", topicId);
  }
}, (_applyDecoratedDescriptor(_obj, "isEmail", [_dec], Object.getOwnPropertyDescriptor(_obj, "isEmail"), _obj), _applyDecoratedDescriptor(_obj, "isDomain", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isDomain"), _obj), _applyDecoratedDescriptor(_obj, "maxRedemptionsAllowedLimit", [_dec3], Object.getOwnPropertyDescriptor(_obj, "maxRedemptionsAllowedLimit"), _obj), _applyDecoratedDescriptor(_obj, "expiresAtLabel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "expiresAtLabel"), _obj), _applyDecoratedDescriptor(_obj, "canInviteToGroup", [_dec5], Object.getOwnPropertyDescriptor(_obj, "canInviteToGroup"), _obj), _applyDecoratedDescriptor(_obj, "canArriveAtTopic", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canArriveAtTopic"), _obj), _applyDecoratedDescriptor(_obj, "timeShortcuts", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "timeShortcuts"), _obj), _applyDecoratedDescriptor(_obj, "copied", [action], Object.getOwnPropertyDescriptor(_obj, "copied"), _obj), _applyDecoratedDescriptor(_obj, "saveInvite", [action], Object.getOwnPropertyDescriptor(_obj, "saveInvite"), _obj), _applyDecoratedDescriptor(_obj, "searchContact", [action], Object.getOwnPropertyDescriptor(_obj, "searchContact"), _obj), _applyDecoratedDescriptor(_obj, "onChangeTopic", [action], Object.getOwnPropertyDescriptor(_obj, "onChangeTopic"), _obj)), _obj))));