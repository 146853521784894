import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
import { tracked } from "@glimmer/tracking";
import { setOwner } from "@ember/application";
import { inject as controller } from "@ember/controller";
import { inject as service } from "@ember/service";
import { BookmarkFormData } from "discourse/lib/bookmark-form-data";
import Bookmark from "discourse/models/bookmark";
let TopicBookmarkManager = (_dec = controller("topic"), (_class = class TopicBookmarkManager {
  constructor(owner, topic) {
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "bookmarkApi", _descriptor2, this);
    _initializerDefineProperty(this, "topicController", _descriptor3, this);
    _initializerDefineProperty(this, "trackedBookmark", _descriptor4, this);
    _initializerDefineProperty(this, "bookmarkModel", _descriptor5, this);
    setOwner(this, owner);
    this.model = topic;
    this.type = "Topic";
    this.bookmarkModel = this.topicController.model?.bookmarks.find(bookmark => bookmark.bookmarkable_id === this.model.id && bookmark.bookmarkable_type === this.type) || this.bookmarkApi.buildNewBookmark(this.type, this.model.id);
    this.trackedBookmark = new BookmarkFormData(this.bookmarkModel);
  }
  create() {
    return this.bookmarkApi.create(this.trackedBookmark).then(updatedBookmark => {
      this.trackedBookmark = updatedBookmark;
    });
  }
  delete() {
    return this.bookmarkApi.delete(this.trackedBookmark.id);
  }
  save() {
    return this.bookmarkApi.update(this.trackedBookmark);
  }

  // noop for topics
  afterModalClose() {
    return;
  }
  afterSave(bookmarkFormData) {
    this.trackedBookmark = bookmarkFormData;
    this._syncBookmarks(bookmarkFormData.saveData);
    this.topicController.model.set("bookmarking", false);
    this.topicController.model.set("bookmarked", true);
    this.topicController.model.incrementProperty("bookmarksWereChanged");
    this.topicController.model.appEvents.trigger("bookmarks:changed", bookmarkFormData.saveData, this.bookmarkModel.attachedTo());
    return [this.model.id];
  }
  afterDelete(deleteResponse, bookmarkId) {
    this.topicController.model.removeBookmark(bookmarkId);
    this.bookmarkModel = this.bookmarkApi.buildNewBookmark(this.type, this.model.id);
    this.trackedBookmark = new BookmarkFormData(this.bookmarkModel);
  }
  _syncBookmarks(data) {
    if (!this.topicController.bookmarks) {
      this.topicController.set("bookmarks", []);
    }
    const bookmark = this.topicController.bookmarks.findBy("id", data.id);
    if (!bookmark) {
      this.topicController.bookmarks.pushObject(Bookmark.create(data));
    } else {
      bookmark.reminder_at = data.reminder_at;
      bookmark.name = data.name;
      bookmark.auto_delete_preference = data.auto_delete_preference;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bookmarkApi", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "topicController", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "trackedBookmark", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bookmarkModel", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { TopicBookmarkManager as default };