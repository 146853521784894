import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './user-summary-topics-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";

// should be kept in sync with 'UserSummary::MAX_SUMMARY_RESULTS'
import TEMPLATE from "./user-summary-topics-list.hbs";
const MAX_SUMMARY_RESULTS = 6;
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("items.length"), (_obj = {
  tagName: "",
  hasMore(length) {
    return length >= MAX_SUMMARY_RESULTS;
  }
}, (_applyDecoratedDescriptor(_obj, "hasMore", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasMore"), _obj)), _obj))));