import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _class;
import PermissionType from "discourse/models/permission-type";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
let TagGroup = (_dec = discourseComputed("permissions"), (_class = class TagGroup extends RestModel {
  permissionName(permissions) {
    if (!permissions) {
      return "public";
    }
    if (permissions["everyone"] === PermissionType.FULL) {
      return "public";
    } else if (permissions["everyone"] === PermissionType.READONLY) {
      return "visible";
    } else {
      return "private";
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "permissionName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "permissionName"), _class.prototype)), _class));
export { TagGroup as default };