import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _class2;
import { action } from "@ember/object";
import Service from "@ember/service";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
let LoginService = disableImplicitInjections(_class = (_class2 = class LoginService extends Service {
  async externalLogin(loginMethod) {
    let {
      signup = false,
      setLoggingIn = null
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      setLoggingIn?.(true);
      await loginMethod.doLogin({
        signup
      });
    } catch {
      setLoggingIn?.(false);
    }
  }
}, (_applyDecoratedDescriptor(_class2.prototype, "externalLogin", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "externalLogin"), _class2.prototype)), _class2)) || _class;
export { LoginService as default };