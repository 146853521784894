import AnonymousSections from "./anonymous/sections";
import UserSections from "./user/sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarSections = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @currentUser}}
    <UserSections
      @collapsableSections={{@collapsableSections}}
      @panel={{@panel}}
      @hideApiSections={{@hideApiSections}}
    />
  {{else}}
    <AnonymousSections @collapsableSections={{@collapsableSections}} />
  {{/if}}

*/
{
  "id": "wl/wBL+5",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[32,0],null,[[\"@collapsableSections\",\"@panel\",\"@hideApiSections\"],[[30,2],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[32,1],null,[[\"@collapsableSections\"],[[30,2]]],null],[1,\"\\n\"]],[]]]],[\"@currentUser\",\"@collapsableSections\",\"@panel\",\"@hideApiSections\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/sections.js",
  "scope": () => [UserSections, AnonymousSections],
  "isStrictMode": true
}), templateOnly());
export default SidebarSections;