import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _PmMapUserGroup, _class, _descriptor, _PmRemoveGroupLink, _PmMapUser, _class2, _descriptor2, _PmRemoveLink, _class3, _descriptor3, _descriptor4, _PrivateMessageMap;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { and } from "truth-helpers";
import DButton from "discourse/components/d-button";
import avatar from "discourse/helpers/bound-avatar-template";
import { groupPath } from "discourse/lib/url";
import dIcon from "discourse-common/helpers/d-icon";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let PrivateMessageMap = (_class3 = (_PrivateMessageMap = class PrivateMessageMap extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor3, this);
    _initializerDefineProperty(this, "isEditing", _descriptor4, this);
  }
  get participantsClasses() {
    return !this.isEditing && this.site.mobileView && this.args.topicDetails.allowed_groups.length > 4 ? "participants hide-names" : "participants";
  }
  get canInvite() {
    return this.args.topicDetails.can_invite_to;
  }
  get canRemove() {
    return this.args.topicDetails.can_remove_allowed_users || this.args.topicDetails.can_remove_self_id;
  }
  get canShowControls() {
    return this.canInvite || this.canRemove;
  }
  get actionAllowed() {
    return this.canRemove ? this.toggleEditing : this.args.showInvite;
  }
  get actionAllowedLabel() {
    if (this.canInvite && this.canRemove) {
      return "private_message_info.edit";
    }
    if (!this.canInvite && this.canRemove) {
      return "private_message_info.remove";
    }
    return "private_message_info.add";
  }
  toggleEditing() {
    this.isEditing = !this.isEditing;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class={{this.participantsClasses}}>
      {{#each @topicDetails.allowed_groups as |group|}}
        <PmMapUserGroup
          @model={{group}}
          @isEditing={{this.isEditing}}
          @canRemoveAllowedUsers={{@topicDetails.can_remove_allowed_users}}
          @removeAllowedGroup={{@removeAllowedGroup}}
        />
      {{/each}}
      {{#each @topicDetails.allowed_users as |user|}}
        <PmMapUser
          @model={{user}}
          @isEditing={{this.isEditing}}
          @canRemoveAllowedUsers={{@topicDetails.can_remove_allowed_users}}
          @canRemoveSelfId={{@topicDetails.can_remove_self_id}}
          @removeAllowedUser={{@removeAllowedUser}}
        />
      {{/each}}
    </div>

    {{#if this.canShowControls}}
      <div class="controls">
        <DButton
          @action={{this.actionAllowed}}
          @label={{this.actionAllowedLabel}}
          class="btn-default add-remove-participant-btn"
        />

        {{#if (and this.canInvite this.isEditing)}}
          <DButton
            @action={{@showInvite}}
            @icon="plus"
            class="btn-default add-participant-btn"
          />
        {{/if}}
      </div>
    {{/if}}
  
*/
{
  "id": "QoeiHiRZ",
  "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"participantsClasses\"]]],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"allowed_groups\"]]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@model\",\"@isEditing\",\"@canRemoveAllowedUsers\",\"@removeAllowedGroup\"],[[30,2],[30,0,[\"isEditing\"]],[30,1,[\"can_remove_allowed_users\"]],[30,3]]],null],[1,\"\\n\"]],[2]],null],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"allowed_users\"]]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@model\",\"@isEditing\",\"@canRemoveAllowedUsers\",\"@canRemoveSelfId\",\"@removeAllowedUser\"],[[30,4],[30,0,[\"isEditing\"]],[30,1,[\"can_remove_allowed_users\"]],[30,1,[\"can_remove_self_id\"]],[30,5]]],null],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"canShowControls\"]],[[[1,\"      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-default add-remove-participant-btn\"]],[[\"@action\",\"@label\"],[[30,0,[\"actionAllowed\"]],[30,0,[\"actionAllowedLabel\"]]]],null],[1,\"\\n\\n\"],[41,[28,[32,3],[[30,0,[\"canInvite\"]],[30,0,[\"isEditing\"]]],null],[[[1,\"          \"],[8,[32,2],[[24,0,\"btn-default add-participant-btn\"]],[[\"@action\",\"@icon\"],[[30,6],\"plus\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@topicDetails\",\"group\",\"@removeAllowedGroup\",\"user\",\"@removeAllowedUser\",\"@showInvite\"],false,[\"each\",\"-track-array\",\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/private-message-map.js",
  "scope": () => [PmMapUserGroup, PmMapUser, DButton, and],
  "isStrictMode": true
}), _PrivateMessageMap), _PrivateMessageMap), (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "isEditing", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class3.prototype, "toggleEditing", [action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleEditing"), _class3.prototype)), _class3);
export { PrivateMessageMap as default };
let PmMapUserGroup = (_PmMapUserGroup = class PmMapUserGroup extends Component {
  get canRemoveLink() {
    return this.args.isEditing && this.args.canRemoveAllowedUsers;
  }
  get groupUrl() {
    return groupPath(this.args.model.name);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="user group">
      <a href={{this.groupUrl}} class="group-link">
        {{dIcon "users"}}
        <span class="group-name">{{@model.name}}</span>
      </a>
      {{#if this.canRemoveLink}}
        <PmRemoveGroupLink
          @model={{@model}}
          @removeAllowedGroup={{@removeAllowedGroup}}
        />
      {{/if}}
    </div>
  
*/
{
  "id": "uEiUNVrc",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"user group\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,0,[\"groupUrl\"]]],[14,0,\"group-link\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"users\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"group-name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"canRemoveLink\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@model\",\"@removeAllowedGroup\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"@removeAllowedGroup\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/private-message-map.js",
  "scope": () => [dIcon, PmRemoveGroupLink],
  "isStrictMode": true
}), _PmMapUserGroup), _PmMapUserGroup);
let PmRemoveGroupLink = (_class = (_PmRemoveGroupLink = class PmRemoveGroupLink extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dialog", _descriptor, this);
  }
  showConfirmDialog() {
    this.dialog.deleteConfirm({
      message: I18n.t("private_message_info.remove_allowed_group", {
        name: this.args.model.name
      }),
      confirmButtonLabel: "private_message_info.remove_group",
      didConfirm: () => this.args.removeAllowedGroup(this.args.model)
    });
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <DButton
      class="remove-invited"
      @action={{this.showConfirmDialog}}
      @icon="times"
    />
  
*/
{
  "id": "9WPip9qx",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"remove-invited\"]],[[\"@action\",\"@icon\"],[[30,0,[\"showConfirmDialog\"]],\"times\"]],null],[1,\"\\n  \"]],[],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/private-message-map.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), _PmRemoveGroupLink), _PmRemoveGroupLink), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "showConfirmDialog", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showConfirmDialog"), _class.prototype)), _class);
let PmMapUser = (_PmMapUser = class PmMapUser extends Component {
  get avatarTitle() {
    return this.args.model.name || this.args.model.username;
  }
  get isCurrentUser() {
    return this.args.canRemoveSelfId === this.args.model.id;
  }
  get canRemoveLink() {
    return this.args.isEditing && (this.args.canRemoveAllowedUsers || this.isCurrentUser);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="user">
      <a class="user-link" href={{@model.path}}>
        <a
          class="trigger-user-card"
          data-user-card={{@model.username}}
          title={{@model.username}}
          aria-hidden="true"
        >
          {{avatar @model.avatar_template "tiny" (hash title=this.avatarTitle)}}
        </a>
        <span class="username">{{@model.username}}</span>
      </a>

      {{#if this.canRemoveLink}}
        <PmRemoveLink
          @model={{@model}}
          @isCurrentUser={{this.isCurrentUser}}
          @removeAllowedUser={{@removeAllowedUser}}
        />
      {{/if}}
    </div>
  
*/
{
  "id": "Mi3zUKDx",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"user\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"user-link\"],[15,6,[30,1,[\"path\"]]],[12],[1,\"\\n        \"],[10,3],[14,0,\"trigger-user-card\"],[15,\"data-user-card\",[30,1,[\"username\"]]],[15,\"title\",[30,1,[\"username\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[[30,1,[\"avatar_template\"]],\"tiny\",[28,[32,1],null,[[\"title\"],[[30,0,[\"avatarTitle\"]]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"username\"],[12],[1,[30,1,[\"username\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"canRemoveLink\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@model\",\"@isCurrentUser\",\"@removeAllowedUser\"],[[30,1],[30,0,[\"isCurrentUser\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"@removeAllowedUser\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/private-message-map.js",
  "scope": () => [avatar, hash, PmRemoveLink],
  "isStrictMode": true
}), _PmMapUser), _PmMapUser);
let PmRemoveLink = (_class2 = (_PmRemoveLink = class PmRemoveLink extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dialog", _descriptor2, this);
  }
  showConfirmDialog() {
    const messageKey1 = this.args.isCurrentUser ? "private_message_info.leave_message" : "private_message_info.remove_allowed_user";
    this.dialog.deleteConfirm({
      message: I18n.t(messageKey1, {
        name: this.args.model.username
      }),
      confirmButtonLabel: this.args.isCurrentUser ? "private_message_info.leave" : "private_message_info.remove_user",
      didConfirm: () => this.args.removeAllowedUser(this.args.model)
    });
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <DButton
      class="remove-invited"
      @action={{this.showConfirmDialog}}
      @icon="times"
    />
  
*/
{
  "id": "9WPip9qx",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"remove-invited\"]],[[\"@action\",\"@icon\"],[[30,0,[\"showConfirmDialog\"]],\"times\"]],null],[1,\"\\n  \"]],[],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/private-message-map.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), _PmRemoveLink), _PmRemoveLink), (_descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "showConfirmDialog", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "showConfirmDialog"), _class2.prototype)), _class2);