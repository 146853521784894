import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2, _descriptor3;
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { reads } from "@ember/object/computed";
import { service } from "@ember/service";
import discourseComputed from "discourse-common/utils/decorators";
let CategoriesController = (_dec = reads("currentUser.staff"), (_class = class CategoriesController extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "composer", _descriptor2, this);
    _initializerDefineProperty(this, "canEdit", _descriptor3, this);
  }
  isCategoriesRoute() {
    return this.router.currentRouteName === "discovery.categories";
  }
  showInserted(event) {
    event?.preventDefault();
    const tracker = this.topicTrackingState;
    // Move inserted into topics
    this.model.loadBefore(tracker.get("newIncoming"), true);
    tracker.resetTracking();
  }
  createTopic() {
    this.composer.openNewTopic({
      preferDraft: true
    });
  }
  refresh() {
    this.send("triggerRefresh");
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "isCategoriesRoute", [discourseComputed], Object.getOwnPropertyDescriptor(_class.prototype, "isCategoriesRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showInserted", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showInserted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createTopic", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createTopic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class));
export { CategoriesController as default };