import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import { getOwner } from "@ember/application";
import Component from "@ember/component";
import deprecated from "discourse-common/lib/deprecated";
import discourseComputed from "discourse-common/utils/decorators";
export default Component.extend((_dec = discourseComputed("message.templateName"), (_obj = {
  classNameBindings: [":composer-popup", "message.extraClass"],
  layout(templateName) {
    return getOwner(this).lookup(`template:composer/${templateName}`);
  },
  actions: {
    closeMessage() {
      deprecated('You should use `action=(closeMessage message)` instead of `action=(action "closeMessage")`', {
        id: "discourse.composer-message.closeMessage"
      });
      this.closeMessage(this.message);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "layout", [_dec], Object.getOwnPropertyDescriptor(_obj, "layout"), _obj)), _obj)));