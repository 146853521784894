import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './categories-boxes.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-boxes.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("categories.[].uploaded_logo.url"), _dec2 = discourseComputed("categories.[].subcategories"), (_obj = {
  tagName: "section",
  classNameBindings: [":category-boxes", "anyLogos:with-logos:no-logos", "hasSubcategories:with-subcategories"],
  lockIcon: "lock",
  anyLogos() {
    return this.categories.any(c => !isEmpty(c.get("uploaded_logo.url")));
  },
  hasSubcategories() {
    return this.categories.any(c => !isEmpty(c.get("subcategories")));
  }
}, (_applyDecoratedDescriptor(_obj, "anyLogos", [_dec], Object.getOwnPropertyDescriptor(_obj, "anyLogos"), _obj), _applyDecoratedDescriptor(_obj, "hasSubcategories", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasSubcategories"), _obj)), _obj))));