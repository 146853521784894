import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './flag-selection.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { next } from "@ember/runloop";
import { observes } from "discourse-common/utils/decorators";

// Mostly hacks because `flag.hbs` didn't use `radio-button`
import TEMPLATE from "./flag-selection.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = observes("nameKey"), (_obj = {
  _selectRadio() {
    this.element.querySelector("input[type='radio']").checked = false;
    const nameKey = this.nameKey;
    if (!nameKey) {
      return;
    }
    const selector = this.element.querySelector("#radio_" + nameKey);
    if (selector) {
      selector.checked = "true";
    }
  },
  selectedChanged() {
    next(this, this._selectRadio);
  }
}, (_applyDecoratedDescriptor(_obj, "selectedChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedChanged"), _obj)), _obj))));