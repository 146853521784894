import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import Controller from "@ember/controller";
import { next } from "@ember/runloop";
import { underscore } from "@ember/string";
import { isPresent } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("reviewableTypes"), _dec2 = discourseComputed("filtersExpanded"), (_obj = {
  queryParams: ["priority", "type", "status", "category_id", "topic_id", "username", "reviewed_by", "from_date", "to_date", "sort_order", "additional_filters"],
  type: null,
  status: "pending",
  priority: "low",
  category_id: null,
  reviewables: null,
  topic_id: null,
  filtersExpanded: false,
  username: "",
  reviewed_by: "",
  from_date: null,
  to_date: null,
  sort_order: null,
  additional_filters: null,
  init() {
    this._super(...arguments);
    this.set("priority", this.siteSettings.reviewable_default_visibility);
    this.set("filtersExpanded", this.site.desktopView);
  },
  allTypes() {
    return (this.reviewableTypes || []).map(type => {
      const translationKey = underscore(type).replace(/[^\w]+/g, "_");
      return {
        id: type,
        name: I18n.t(`review.types.${translationKey}.title`)
      };
    });
  },
  priorities() {
    return ["any", "low", "medium", "high"].map(priority => {
      return {
        id: priority,
        name: I18n.t(`review.filters.priority.${priority}`)
      };
    });
  },
  sortOrders() {
    return ["score", "score_asc", "created_at", "created_at_asc"].map(order => {
      return {
        id: order,
        name: I18n.t(`review.filters.orders.${order}`)
      };
    });
  },
  statuses() {
    return ["pending", "approved", "rejected", "deleted", "ignored", "reviewed", "all"].map(id => {
      return {
        id,
        name: I18n.t(`review.statuses.${id}.title`)
      };
    });
  },
  toggleFiltersIcon(filtersExpanded) {
    return filtersExpanded ? "chevron-up" : "chevron-down";
  },
  setRange(range) {
    this.setProperties(range);
  },
  refreshModel() {
    next(() => this.send("refreshRoute"));
  },
  actions: {
    remove(ids) {
      if (!ids) {
        return;
      }
      let newList = this.reviewables.reject(reviewable => {
        return ids.includes(reviewable.id);
      });
      if (newList.length === 0) {
        this.refreshModel();
      } else {
        this.reviewables.setObjects(newList);
      }
    },
    resetTopic() {
      this.set("topic_id", null);
      this.refreshModel();
    },
    refresh() {
      const currentStatus = this.status;
      const nextStatus = this.filterStatus;
      const currentOrder = this.sort_order;
      let nextOrder = this.filterSortOrder;
      const createdAtStatuses = ["reviewed", "all"];
      const priorityStatuses = ["approved", "rejected", "deleted", "ignored", "pending"];
      if (createdAtStatuses.includes(currentStatus) && currentOrder === "created_at" && priorityStatuses.includes(nextStatus) && nextOrder === "created_at") {
        nextOrder = "score";
      }
      if (priorityStatuses.includes(currentStatus) && currentOrder === "score" && createdAtStatuses.includes(nextStatus) && nextOrder === "score") {
        nextOrder = "created_at";
      }
      this.setProperties({
        type: this.filterType,
        priority: this.filterPriority,
        status: this.filterStatus,
        category_id: this.filterCategoryId,
        username: this.filterUsername,
        reviewed_by: this.filterReviewedBy,
        from_date: isPresent(this.filterFromDate) ? this.filterFromDate.toISOString(true).split("T")[0] : null,
        to_date: isPresent(this.filterToDate) ? this.filterToDate.toISOString(true).split("T")[0] : null,
        sort_order: nextOrder,
        additional_filters: JSON.stringify(this.additionalFilters)
      });
      this.refreshModel();
    },
    loadMore() {
      return this.reviewables.loadMore();
    },
    toggleFilters() {
      this.toggleProperty("filtersExpanded");
    },
    updateFilterReviewedBy(selected) {
      this.set("filterReviewedBy", selected.firstObject);
    },
    updateFilterUsername(selected) {
      this.set("filterUsername", selected.firstObject);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "allTypes", [_dec], Object.getOwnPropertyDescriptor(_obj, "allTypes"), _obj), _applyDecoratedDescriptor(_obj, "priorities", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "priorities"), _obj), _applyDecoratedDescriptor(_obj, "sortOrders", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "sortOrders"), _obj), _applyDecoratedDescriptor(_obj, "statuses", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "statuses"), _obj), _applyDecoratedDescriptor(_obj, "toggleFiltersIcon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleFiltersIcon"), _obj)), _obj)));