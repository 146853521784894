import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './composer-user-selector.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./composer-user-selector.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("recipients"), (_obj = {
  init() {
    this._super(...arguments);
    this.set("_groups", []);
  },
  didInsertElement() {
    this._super(...arguments);
    if (this.focusTarget === "usernames") {
      this.element.querySelector(".select-kit .select-kit-header").focus();
    }
  },
  splitRecipients(recipients) {
    if (Array.isArray(recipients)) {
      return recipients;
    }
    return recipients ? recipients.split(",").filter(Boolean) : [];
  },
  _updateGroups(selected, newGroups) {
    const groups = [];
    this._groups.forEach(existing => {
      if (selected.includes(existing)) {
        groups.addObject(existing);
      }
    });
    newGroups.forEach(newGroup => {
      if (!groups.includes(newGroup)) {
        groups.addObject(newGroup);
      }
    });
    this.setProperties({
      _groups: groups,
      hasGroups: groups.length > 0
    });
  },
  actions: {
    updateRecipients(selected, content) {
      const newGroups = content.filterBy("isGroup").mapBy("id");
      this._updateGroups(selected, newGroups);
      this.set("recipients", selected.join(","));
    }
  }
}, (_applyDecoratedDescriptor(_obj, "splitRecipients", [_dec], Object.getOwnPropertyDescriptor(_obj, "splitRecipients"), _obj)), _obj))));