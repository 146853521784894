import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { tracked } from "@glimmer/tracking";
import { applyModelTransformations } from "discourse/lib/model-transformers";
import RestModel from "discourse/models/rest";
let Notification = (_class = class Notification extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "read", _descriptor, this);
  }
  static async applyTransformations(notifications) {
    await applyModelTransformations("notification", notifications);
  }
  static async initializeNotifications(rawList) {
    const notifications = rawList.map(n => this.create(n));
    await this.applyTransformations(notifications);
    return notifications;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "read", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { Notification as default };