import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action, computed } from "@ember/object";
import { equal, notEmpty } from "@ember/object/computed";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import Bookmark from "discourse/models/bookmark";
import { iconHTML } from "discourse-common/lib/icon-library";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed(), _dec2 = discourseComputed("inSearchMode", "noContent"), _dec3 = discourseComputed("inSearchMode", "noContent"), (_obj = {
  queryParams: ["q"],
  q: null,
  router: service(),
  application: controller(),
  user: controller(),
  loading: false,
  loadingMore: false,
  permissionDenied: false,
  inSearchMode: notEmpty("q"),
  noContent: equal("model.bookmarks.length", 0),
  searchTerm: computed("q", {
    get() {
      return this.q;
    },
    set(key, value) {
      return value;
    }
  }),
  emptyStateBody() {
    return htmlSafe(I18n.t("user.no_bookmarks_body", {
      icon: iconHTML("bookmark")
    }));
  },
  userDoesNotHaveBookmarks(inSearchMode, noContent) {
    return !inSearchMode && noContent;
  },
  nothingFound(inSearchMode, noContent) {
    return inSearchMode && noContent;
  },
  search() {
    this.router.transitionTo({
      queryParams: {
        q: this.searchTerm
      }
    });
  },
  reload() {
    this.send("triggerRefresh");
  },
  loadMore() {
    if (this.loadingMore) {
      return Promise.resolve();
    }
    this.set("loadingMore", true);
    return this._loadMoreBookmarks(this.q).then(response => this._processLoadResponse(this.q, response)).catch(() => this._bookmarksListDenied()).finally(() => this.set("loadingMore", false));
  },
  _loadMoreBookmarks(searchQuery) {
    if (!this.model.loadMoreUrl) {
      return Promise.resolve();
    }
    let moreUrl = this.model.loadMoreUrl;
    if (searchQuery) {
      const delimiter = moreUrl.includes("?") ? "&" : "?";
      const q = encodeURIComponent(searchQuery);
      moreUrl += `${delimiter}q=${q}`;
    }
    return ajax({
      url: moreUrl
    });
  },
  _bookmarksListDenied() {
    this.set("permissionDenied", true);
  },
  async _processLoadResponse(searchTerm, response) {
    if (!response || !response.user_bookmark_list) {
      this.model.loadMoreUrl = null;
      return;
    }
    response = response.user_bookmark_list;
    this.model.searchTerm = searchTerm;
    this.model.loadMoreUrl = response.more_bookmarks_url;
    if (response.bookmarks) {
      const bookmarkModels = response.bookmarks.map(this.transform);
      await Bookmark.applyTransformations(bookmarkModels);
      this.model.bookmarks.pushObjects(bookmarkModels);
      this.session.set("bookmarksModel", this.model);
    }
  },
  transform(bookmark) {
    const bookmarkModel = Bookmark.create(bookmark);
    bookmarkModel.topicStatus = EmberObject.create({
      closed: bookmark.closed,
      archived: bookmark.archived,
      is_warning: bookmark.is_warning,
      pinned: false,
      unpinned: false,
      invisible: bookmark.invisible
    });
    return bookmarkModel;
  }
}, (_applyDecoratedDescriptor(_obj, "emptyStateBody", [_dec], Object.getOwnPropertyDescriptor(_obj, "emptyStateBody"), _obj), _applyDecoratedDescriptor(_obj, "userDoesNotHaveBookmarks", [_dec2], Object.getOwnPropertyDescriptor(_obj, "userDoesNotHaveBookmarks"), _obj), _applyDecoratedDescriptor(_obj, "nothingFound", [_dec3], Object.getOwnPropertyDescriptor(_obj, "nothingFound"), _obj), _applyDecoratedDescriptor(_obj, "search", [action], Object.getOwnPropertyDescriptor(_obj, "search"), _obj), _applyDecoratedDescriptor(_obj, "reload", [action], Object.getOwnPropertyDescriptor(_obj, "reload"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj)), _obj)));