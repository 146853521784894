var _DToggleSwitch;
import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DToggleSwitch extends Component {
  get computedLabel() {
    if (this.args.label) {
      return I18n.t(this.args.label);
    }
    return this.args.translatedLabel;
  }
  get checked() {
    return this.args.state ? "true" : "false";
  }
}
_DToggleSwitch = DToggleSwitch;
setComponentTemplate(createTemplateFactory(
/*
  
    <div class="d-toggle-switch">
      <label class="d-toggle-switch--label">
        {{! template-lint-disable no-redundant-role }}
        <button
          class="d-toggle-switch__checkbox"
          type="button"
          role="switch"
          aria-checked={{this.checked}}
          ...attributes
        ></button>
        {{! template-lint-enable no-redundant-role }}

        <span class="d-toggle-switch__checkbox-slider">
          {{#if @state}}
            {{icon "check"}}
          {{/if}}
        </span>
      </label>

      <span class="d-toggle-switch__checkbox-label">
        {{this.computedLabel}}
      </span>
    </div>
  
*/
{
  "id": "gd58zvuF",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"d-toggle-switch\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"d-toggle-switch--label\"],[12],[1,\"\\n\"],[1,\"        \"],[11,\"button\"],[24,0,\"d-toggle-switch__checkbox\"],[24,4,\"button\"],[24,\"role\",\"switch\"],[16,\"aria-checked\",[30,0,[\"checked\"]]],[17,1],[12],[13],[1,\"\\n\"],[1,\"\\n        \"],[10,1],[14,0,\"d-toggle-switch__checkbox-slider\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"            \"],[1,[28,[32,0],[\"check\"],null]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,1],[14,0,\"d-toggle-switch__checkbox-label\"],[12],[1,\"\\n        \"],[1,[30,0,[\"computedLabel\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@state\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-toggle-switch.js",
  "scope": () => [icon],
  "isStrictMode": true
}), _DToggleSwitch);