import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-manage-logs-filter.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-manage-logs-filter.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("type"), _dec2 = discourseComputed("value", "type"), (_obj = {
  tagName: "",
  label(type) {
    return I18n.t(`groups.manage.logs.${type}`);
  },
  filterText(value, type) {
    return type === "action" ? I18n.t(`group_histories.actions.${value}`) : value;
  }
}, (_applyDecoratedDescriptor(_obj, "label", [_dec], Object.getOwnPropertyDescriptor(_obj, "label"), _obj), _applyDecoratedDescriptor(_obj, "filterText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "filterText"), _obj)), _obj))));