import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _class;
/* import __COLOCATED_TEMPLATE__ from './bookmark-icon.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { NO_REMINDER_ICON, WITH_REMINDER_ICON } from "discourse/models/bookmark";
import I18n from "discourse-i18n";
import TEMPLATE from "./bookmark-icon.hbs";
let BookmarkIcon = (_dec = computed("bookmark.reminder_at"), _dec2 = computed("bookmark"), _dec3 = computed("bookmark.title"), (_class = class BookmarkIcon extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "tagName", "");
    _defineProperty(this, "bookmark", null);
  }
  get icon() {
    if (!this.bookmark) {
      return NO_REMINDER_ICON;
    }
    if (!isEmpty(this.bookmark.reminder_at)) {
      return WITH_REMINDER_ICON;
    }
    return NO_REMINDER_ICON;
  }
  get cssClasses() {
    return this.bookmark ? "bookmark-icon bookmark-icon__bookmarked" : "bookmark-icon";
  }
  get title() {
    if (!this.bookmark) {
      return I18n.t("bookmarks.create");
    }
    return this.bookmark.reminderTitle;
  }
}, (_applyDecoratedDescriptor(_class.prototype, "icon", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cssClasses", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "cssClasses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype)), _class));
export { BookmarkIcon as default };
setComponentTemplate(TEMPLATE, BookmarkIcon);