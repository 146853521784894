import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _class, _descriptor;
import { readOnly } from "@ember/object/computed";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
let Tag = (_dec = readOnly("pm_only"), _dec2 = discourseComputed("count", "pm_count"), _dec3 = discourseComputed("id"), (_class = class Tag extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "pmOnly", _descriptor, this);
  }
  totalCount(count, pmCount) {
    return pmCount ? count + pmCount : count;
  }
  searchContext(id) {
    return {
      type: "tag",
      id,
      tag: this,
      name: id
    };
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pmOnly", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "totalCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchContext", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "searchContext"), _class.prototype)), _class));
export { Tag as default };