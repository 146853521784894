import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import { action, get } from "@ember/object";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export function buildGroupPage(type) {
  var _obj;
  return DiscourseRoute.extend((_obj = {
    type,
    templateName: "group-activity-posts",
    controllerName: "group-activity-posts",
    titleToken() {
      return I18n.t(`groups.${type}`);
    },
    model(params, transition) {
      let categoryId = get(transition.to, "queryParams.category_id");
      return this.modelFor("group").findPosts({
        type,
        categoryId
      });
    },
    setupController(controller, model) {
      let loadedAll = model.length < 20;
      controller.setProperties({
        model,
        type,
        canLoadMore: !loadedAll
      });
    },
    didTransition() {
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [action], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj));
}
export default buildGroupPage("posts");