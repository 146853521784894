import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
import { registerDestructor } from "@ember/destroyable";
import Modifier from "ember-modifier";
import { bind } from "discourse-common/utils/decorators";
let CloseOnClickOutside = (_class = class CloseOnClickOutside extends Modifier {
  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, instance => instance.cleanup());
  }
  modify(element, _ref) {
    let [closeFn, {
      targetSelector,
      secondaryTargetSelector,
      target
    }] = _ref;
    this.closeFn = closeFn;
    this.element = element;
    this.target = target;
    this.targetSelector = targetSelector;
    this.secondaryTargetSelector = secondaryTargetSelector;
    document.addEventListener("pointerdown", this.check, {
      passive: true
    });
  }
  check(event) {
    if (this.element.contains(event.target)) {
      return;
    }
    const target = this.target ?? document.querySelector(this.targetSelector);
    if (target?.contains(event.target) || this.secondaryTargetSelector && document.querySelector(this.secondaryTargetSelector)?.contains(event.target)) {
      return;
    }
    this.closeFn(event);
  }
  cleanup() {
    document.removeEventListener("pointerdown", this.check);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "check", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "check"), _class.prototype)), _class);
export { CloseOnClickOutside as default };