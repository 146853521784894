import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import EmberObject from "@ember/object";
import { and } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default EmberObject.extend((_obj = {
  showBadges: and("postBadgesEnabled", "topic.unread_posts"),
  newDotText() {
    return this.currentUser && this.currentUser.trust_level > 0 ? "" : I18n.t("filters.new.lower_title");
  }
}, (_applyDecoratedDescriptor(_obj, "newDotText", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "newDotText"), _obj)), _obj));