import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './responsive-table.hbs'; */
import { tracked } from "@glimmer/tracking";
import Component, { setComponentTemplate } from "@ember/component";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./responsive-table.hbs";
let ResponsiveTable = (_class = class ResponsiveTable extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "lastScrollPosition", _descriptor, this);
    _initializerDefineProperty(this, "ticking", _descriptor2, this);
    _initializerDefineProperty(this, "_table", _descriptor3, this);
    _initializerDefineProperty(this, "_topHorizontalScrollBar", _descriptor4, this);
  }
  checkScroll() {
    const _fakeScrollContent = document.querySelector(".directory-table-top-scroll-fake-content");
    if (this._table.getBoundingClientRect().bottom < window.innerHeight) {
      // Bottom of the table is visible. Hide the scrollbar
      _fakeScrollContent.style.height = 0;
    } else {
      _fakeScrollContent.style.width = `${this._table.scrollWidth}px`;
      _fakeScrollContent.style.height = "1px";
    }
  }
  onTopScroll() {
    this.onHorizontalScroll(this._topHorizontalScrollBar, this._table);
  }
  onBottomScroll() {
    this.onHorizontalScroll(this._table, this._topHorizontalScrollBar);
  }
  onHorizontalScroll(primary, replica) {
    this.set("lastScrollPosition", primary?.scrollLeft);
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        replica.scrollLeft = this.lastScrollPosition;
        this.set("ticking", false);
      });
      this.set("ticking", true);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "lastScrollPosition", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ticking", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_table", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return document.querySelector(".directory-table");
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_topHorizontalScrollBar", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return document.querySelector(".directory-table-top-scroll");
  }
}), _applyDecoratedDescriptor(_class.prototype, "checkScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "checkScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTopScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onTopScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBottomScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onBottomScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onHorizontalScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onHorizontalScroll"), _class.prototype)), _class);
export { ResponsiveTable as default };
setComponentTemplate(TEMPLATE, ResponsiveTable);