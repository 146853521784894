import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './auth-token.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { userPath } from "discourse/lib/url";
import TEMPLATE from "./auth-token.hbs";
import { setComponentTemplate } from "@ember/component";
let AuthTokenComponent = (_class = class AuthTokenComponent extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "expanded", _descriptor2, this);
    _initializerDefineProperty(this, "latestPost", _descriptor3, this);
    this.fetchActivity();
  }
  async fetchActivity() {
    const posts = await ajax(userPath(`${this.currentUser.username_lower}/activity.json`));
    if (posts.length > 0) {
      this.latestPost = posts[0];
    }
  }
  toggleExpanded(event) {
    event?.preventDefault();
    this.expanded = !this.expanded;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expanded", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "latestPost", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "fetchActivity", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchActivity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleExpanded", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleExpanded"), _class.prototype)), _class);
export { AuthTokenComponent as default };
setComponentTemplate(TEMPLATE, AuthTokenComponent);