import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './choose-topic.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { searchForTerm } from "discourse/lib/search";
import { INPUT_DELAY } from "discourse-common/config/environment";
import discourseComputed, { debounce, observes } from "discourse-common/utils/decorators";
import TEMPLATE from "./choose-topic.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = observes("topicTitle"), _dec2 = discourseComputed("label"), _dec3 = observes("topics"), _dec4 = debounce(INPUT_DELAY), (_obj = {
  loading: null,
  noResults: null,
  topics: null,
  selectedTopicId: null,
  currentTopicId: null,
  additionalFilters: null,
  topicTitle: null,
  label: null,
  loadOnInit: false,
  topicChangedCallback: null,
  init() {
    this._super(...arguments);
    this.additionalFilters = this.additionalFilters || "";
    this.topicTitle = this.topicTitle || "";
    if (this.loadOnInit && !isEmpty(this.additionalFilters)) {
      searchForTerm(this.additionalFilters, {}).then(results => {
        if (results?.posts?.length > 0) {
          this.set("topics", results.posts.mapBy("topic").filter(t => t.id !== this.currentTopicId));
        } else {
          this.setProperties({
            topics: null,
            loading: false
          });
        }
      });
    }
  },
  didInsertElement() {
    this._super(...arguments);
    document.getElementById("choose-topic-title").addEventListener("keydown", this._handleEnter);
  },
  willDestroyElement() {
    this._super(...arguments);
    document.getElementById("choose-topic-title").removeEventListener("keydown", this._handleEnter);
  },
  topicTitleChanged() {
    if (this.oldTopicTitle === this.topicTitle) {
      return;
    }
    this.setProperties({
      loading: true,
      noResults: true,
      selectedTopicId: null,
      oldTopicTitle: this.topicTitle
    });
    this.search(this.topicTitle);
  },
  labelText(label) {
    return label || "choose_topic.title.search";
  },
  topicsChanged() {
    if (this.topics) {
      this.set("noResults", this.topics.length === 0);
    }
    this.set("loading", false);
  },
  search(title) {
    if (!this.element || this.isDestroying || this.isDestroyed) {
      return;
    }
    if (isEmpty(title) && isEmpty(this.additionalFilters)) {
      this.setProperties({
        topics: null,
        loading: false
      });
      this.onSearchEmptied?.();
      return;
    }
    const currentTopicId = this.currentTopicId;
    const titleWithFilters = `${title} ${this.additionalFilters}`;
    const searchParams = {};
    if (!isEmpty(title)) {
      searchParams.typeFilter = "topic";
      searchParams.restrictToArchetype = "regular";
      searchParams.searchForId = true;
    }
    searchForTerm(titleWithFilters, searchParams).then(results => {
      // search term changed after the request was fired but before we
      // got a response, ignore results.
      if (title !== this.topicTitle) {
        return;
      }
      if (results?.posts?.length > 0) {
        this.set("topics", results.posts.mapBy("topic").filter(t => t.id !== currentTopicId));
        if (this.topics.length === 1) {
          this.send("chooseTopic", this.topics[0]);
        }
      } else {
        this.setProperties({
          topics: null,
          loading: false
        });
      }
    });
  },
  chooseTopic(topic) {
    this.set("selectedTopicId", topic.id);
    if (this.topicChangedCallback) {
      this.topicChangedCallback(topic);
    }
  },
  focusInput(element) {
    if (this.autoFocus) {
      element.focus();
    }
  },
  _handleEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
}, (_applyDecoratedDescriptor(_obj, "topicTitleChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicTitleChanged"), _obj), _applyDecoratedDescriptor(_obj, "labelText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "labelText"), _obj), _applyDecoratedDescriptor(_obj, "topicsChanged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "topicsChanged"), _obj), _applyDecoratedDescriptor(_obj, "search", [_dec4], Object.getOwnPropertyDescriptor(_obj, "search"), _obj), _applyDecoratedDescriptor(_obj, "chooseTopic", [action], Object.getOwnPropertyDescriptor(_obj, "chooseTopic"), _obj), _applyDecoratedDescriptor(_obj, "focusInput", [action], Object.getOwnPropertyDescriptor(_obj, "focusInput"), _obj)), _obj))));