import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _UserStatusMessage;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import emoji from "discourse/helpers/emoji";
import { until } from "discourse/lib/formatter";
import DTooltip from "float-kit/components/d-tooltip";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let UserStatusMessage = (_class = (_UserStatusMessage = class UserStatusMessage extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
  get until() {
    if (!this.args.status.ends_at) {
      return;
    }
    const timezone1 = this.currentUser ? this.currentUser.user_option?.timezone : moment.tz.guess();
    return until(this.args.status.ends_at, timezone1, this.currentUser?.locale);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if @status}}
      <DTooltip
        @identifier="user-status-message-tooltip"
        class="user-status-message"
        ...attributes
      >
        <:trigger>
          {{emoji @status.emoji skipTitle=true}}
          {{#if @showDescription}}
            <span class="user-status-message-description">
              {{@status.description}}
            </span>
          {{/if}}
        </:trigger>
        <:content>
          {{emoji @status.emoji skipTitle=true}}
          <span class="user-status-tooltip-description">
            {{@status.description}}
          </span>
          {{#if this.until}}
            <div class="user-status-tooltip-until">
              {{this.until}}
            </div>
          {{/if}}
        </:content>
      </DTooltip>
    {{/if}}
  
*/
{
  "id": "F9tcOi7k",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],[[24,0,\"user-status-message\"],[17,2]],[[\"@identifier\"],[\"user-status-message-tooltip\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"emoji\"]]],[[\"skipTitle\"],[true]]]],[1,\"\\n\"],[41,[30,3],[[[1,\"            \"],[10,1],[14,0,\"user-status-message-description\"],[12],[1,\"\\n              \"],[1,[30,1,[\"description\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]],[[[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"emoji\"]]],[[\"skipTitle\"],[true]]]],[1,\"\\n          \"],[10,1],[14,0,\"user-status-tooltip-description\"],[12],[1,\"\\n            \"],[1,[30,1,[\"description\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"],[41,[30,0,[\"until\"]],[[[1,\"            \"],[10,0],[14,0,\"user-status-tooltip-until\"],[12],[1,\"\\n              \"],[1,[30,0,[\"until\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@status\",\"&attrs\",\"@showDescription\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-status-message.js",
  "scope": () => [DTooltip, emoji],
  "isStrictMode": true
}), _UserStatusMessage), _UserStatusMessage), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { UserStatusMessage as default };