import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import Controller, { inject as controller } from "@ember/controller";
let LoginPageController = (_class = class LoginPageController extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "application", _descriptor, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "application", [controller], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { LoginPageController as default };