import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
/* import __COLOCATED_TEMPLATE__ from './composer-toggles.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./composer-toggles.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("composeState"), _dec2 = discourseComputed("showToolbar"), _dec3 = discourseComputed("composeState"), _dec4 = discourseComputed("composeState"), _dec5 = discourseComputed("composeState"), _dec6 = discourseComputed("disableTextarea"), (_obj = {
  tagName: "",
  toggleTitle(composeState) {
    return composeState === "draft" || composeState === "saving" ? "composer.abandon" : "composer.collapse";
  },
  toggleToolbarTitle(showToolbar) {
    return showToolbar ? "composer.hide_toolbar" : "composer.show_toolbar";
  },
  fullscreenTitle(composeState) {
    return composeState === "draft" ? "composer.open" : composeState === "fullscreen" ? "composer.exit_fullscreen" : "composer.enter_fullscreen";
  },
  toggleIcon(composeState) {
    return composeState === "draft" || composeState === "saving" ? "times" : "chevron-down";
  },
  fullscreenIcon(composeState) {
    return composeState === "draft" ? "chevron-up" : composeState === "fullscreen" ? "discourse-compress" : "discourse-expand";
  },
  showFullScreenButton(disableTextarea) {
    if (this.site.mobileView) {
      return false;
    }
    return !disableTextarea;
  }
}, (_applyDecoratedDescriptor(_obj, "toggleTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleTitle"), _obj), _applyDecoratedDescriptor(_obj, "toggleToolbarTitle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleToolbarTitle"), _obj), _applyDecoratedDescriptor(_obj, "fullscreenTitle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "fullscreenTitle"), _obj), _applyDecoratedDescriptor(_obj, "toggleIcon", [_dec4], Object.getOwnPropertyDescriptor(_obj, "toggleIcon"), _obj), _applyDecoratedDescriptor(_obj, "fullscreenIcon", [_dec5], Object.getOwnPropertyDescriptor(_obj, "fullscreenIcon"), _obj), _applyDecoratedDescriptor(_obj, "showFullScreenButton", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showFullScreenButton"), _obj)), _obj))));