import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;
import { readOnly } from "@ember/object/computed";
import Service from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { observes } from "@ember-decorators/object";
import { autoUpdatingRelativeAge } from "discourse/lib/formatter";
import getURL from "discourse-common/lib/get-url";
import discourseComputed, { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
const LOGS_NOTICE_KEY = "logs-notice-text";
let LogsNoticeService = (_dec = readOnly("currentUser.admin"), _dec2 = discourseComputed("text"), _dec3 = discourseComputed("text"), _dec4 = discourseComputed("isEmpty", "isAdmin"), _dec5 = observes("text"), (_class = class LogsNoticeService extends Service {
  constructor() {
    super(...arguments);
    _defineProperty(this, "text", "");
    _initializerDefineProperty(this, "isAdmin", _descriptor, this);
  }
  init() {
    super.init(...arguments);
    if (this.siteSettings.alert_admins_if_errors_per_hour === 0 && this.siteSettings.alert_admins_if_errors_per_minute === 0) {
      return;
    }
    const text = this.keyValueStore.getItem(LOGS_NOTICE_KEY);
    if (text) {
      this.set("text", text);
    }
    this.messageBus.subscribe("/logs_error_rate_exceeded", this.onLogRateLimit);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.messageBus.unsubscribe("/logs_error_rate_exceeded", this.onLogRateLimit);
  }
  onLogRateLimit(data) {
    const {
      duration,
      rate
    } = data;
    let siteSettingLimit = 0;
    if (duration === "minute") {
      siteSettingLimit = this.siteSettings.alert_admins_if_errors_per_minute;
    } else if (duration === "hour") {
      siteSettingLimit = this.siteSettings.alert_admins_if_errors_per_hour;
    }
    let translationKey = rate === siteSettingLimit ? "reached" : "exceeded";
    translationKey += `_${duration}_MF`;
    this.set("text", I18n.messageFormat(`logs_error_rate_notice.${translationKey}`, {
      relativeAge: autoUpdatingRelativeAge(new Date(data.publish_at * 1000)),
      rate,
      limit: siteSettingLimit,
      url: getURL("/logs")
    }));
  }
  isEmpty(text) {
    return isEmpty(text);
  }
  message(text) {
    return htmlSafe(text);
  }
  hidden(thisIsEmpty, isAdmin) {
    return !isAdmin || thisIsEmpty;
  }
  _updateKeyValueStore() {
    this.keyValueStore.setItem(LOGS_NOTICE_KEY, this.text);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "onLogRateLimit", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onLogRateLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isEmpty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "message", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "message"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hidden", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "hidden"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_updateKeyValueStore", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_updateKeyValueStore"), _class.prototype)), _class));
export { LogsNoticeService as default };