import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj, _init;
/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { schedule } from "@ember/runloop";
/* global Pikaday:true */
import loadScript from "discourse/lib/load-script";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./date-picker.hbs";
const DATE_FORMAT = "YYYY-MM-DD";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("site.mobileView"), _dec2 = on("didInsertElement"), _dec3 = on("willDestroyElement"), _dec4 = discourseComputed("_placeholder"), (_obj = {
  classNames: ["date-picker-wrapper"],
  _picker: null,
  value: null,
  inputType(mobileView) {
    return mobileView ? "date" : "text";
  },
  _loadDatePicker() {
    if (this.site.mobileView) {
      this._loadNativePicker();
    } else {
      const container = document.getElementById(this.containerId);
      this._loadPikadayPicker(container);
    }
  },
  _loadPikadayPicker(container) {
    loadScript("/javascripts/pikaday.js").then(() => {
      schedule("afterRender", () => {
        const options = {
          field: this.element.querySelector(".date-picker"),
          container: container || null,
          bound: container === null,
          format: DATE_FORMAT,
          firstDay: 1,
          i18n: {
            previousMonth: I18n.t("dates.previous_month"),
            nextMonth: I18n.t("dates.next_month"),
            months: moment.months(),
            weekdays: moment.weekdays(),
            weekdaysShort: moment.weekdaysMin()
          },
          onSelect: date => this._handleSelection(date)
        };
        this._picker = new Pikaday(Object.assign(options, this._opts()));
      });
    });
  },
  _loadNativePicker() {
    const picker = this.element.querySelector("input.date-picker");
    picker.onchange = () => this._handleSelection(picker.value);
    picker.hide = () => {
      /* do nothing for native */
    };
    picker.destroy = () => {
      /* do nothing for native */
    };
    this._picker = picker;
  },
  _handleSelection(value) {
    const formattedDate = moment(value).format(DATE_FORMAT);
    if (!this.element || this.isDestroying || this.isDestroyed) {
      return;
    }
    if (this.onSelect) {
      this.onSelect(formattedDate);
    }
  },
  _destroy() {
    if (this._picker) {
      this._picker.destroy();
      this._picker = null;
    }
  },
  placeholder: {
    get(_placeholder) {
      return _placeholder || I18n.t("dates.placeholder");
    },
    set(value) {
      this.set("_placeholder", value);
      return value;
    }
  },
  _opts() {
    return null;
  }
}, (_applyDecoratedDescriptor(_obj, "inputType", [_dec], Object.getOwnPropertyDescriptor(_obj, "inputType"), _obj), _applyDecoratedDescriptor(_obj, "_loadDatePicker", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_loadDatePicker"), _obj), _applyDecoratedDescriptor(_obj, "_destroy", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_destroy"), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec4], (_init = Object.getOwnPropertyDescriptor(_obj, "placeholder"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj)), _obj))));