import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './types.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import { logSearchLinkClick } from "discourse/lib/search";
import DiscourseURL from "discourse/lib/url";
import TEMPLATE from "./types.hbs";
import { setComponentTemplate } from "@ember/component";
let Types = (_class = class Types extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "search", _descriptor, this);
  }
  get filteredResultTypes() {
    // return only topic result types
    if (this.args.topicResultsOnly) {
      return this.args.resultTypes.filter(resultType => resultType.type === "topic");
    }

    // return all result types minus topics
    return this.args.resultTypes.filter(resultType => resultType.type !== "topic");
  }
  onClick(_ref, event) {
    let {
      resultType,
      result
    } = _ref;
    logSearchLinkClick({
      searchLogId: this.args.searchLogId,
      searchResultId: result.id,
      searchResultType: resultType.type
    });
    if (wantsNewWindow(event)) {
      return;
    }
    event.preventDefault();
    this.routeToSearchResult(event.currentTarget.href);
  }
  onKeydown(_ref2, event) {
    let {
      resultType,
      result
    } = _ref2;
    if (event.key === "Escape") {
      this.args.closeSearchMenu();
      event.preventDefault();
      return false;
    } else if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      logSearchLinkClick({
        searchLogId: this.args.searchLogId,
        searchResultId: result.id,
        searchResultType: resultType.type
      });
      this.routeToSearchResult(event.target.href);
      return false;
    }
    this.search.handleResultInsertion(event);
    this.search.handleArrowUpOrDown(event);
  }
  routeToSearchResult(href) {
    DiscourseURL.routeTo(href);
    this.args.closeSearchMenu();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "onClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "routeToSearchResult", [action], Object.getOwnPropertyDescriptor(_class.prototype, "routeToSearchResult"), _class.prototype)), _class);
export { Types as default };
setComponentTemplate(TEMPLATE, Types);