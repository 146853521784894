import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DismissNew from "discourse/components/modal/dismiss-new";
import BulkSelectHelper from "discourse/lib/bulk-select-helper";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import { defineTrackedProperty } from "discourse/lib/tracked-tools";
import Topic from "discourse/models/topic";

// Just add query params here to have them automatically passed to topic list filters.
export const queryParams = {
  order: {
    replace: true,
    refreshModel: true
  },
  ascending: {
    replace: true,
    refreshModel: true,
    default: false
  },
  status: {
    replace: true,
    refreshModel: true
  },
  state: {
    replace: true,
    refreshModel: true
  },
  search: {
    replace: true,
    refreshModel: true
  },
  max_posts: {
    replace: true,
    refreshModel: true
  },
  min_posts: {
    replace: true,
    refreshModel: true
  },
  q: {
    replace: true,
    refreshModel: true
  },
  before: {
    replace: true,
    refreshModel: true
  },
  bumped_before: {
    replace: true,
    refreshModel: true
  },
  f: {
    replace: true,
    refreshModel: true
  },
  subset: {
    replace: true,
    refreshModel: true
  },
  period: {
    replace: true,
    refreshModel: true
  },
  topic_ids: {
    replace: true,
    refreshModel: true
  },
  group_name: {
    replace: true,
    refreshModel: true
  },
  tags: {
    replace: true,
    refreshModel: true
  },
  match_all_tags: {
    replace: true,
    refreshModel: true
  },
  no_subcategories: {
    replace: true,
    refreshModel: true
  },
  no_tags: {
    replace: true,
    refreshModel: true
  },
  exclude_tag: {
    replace: true,
    refreshModel: true
  }
};
export function resetParams() {
  let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  for (const [param, value] of Object.entries(queryParams)) {
    if (!skipParams.includes(param)) {
      this.controller.set(param, value.default);
    }
  }
}
export function addDiscoveryQueryParam(p, opts) {
  queryParams[p] = opts;
}
let DiscoveryListController = disableImplicitInjections(_class = (_class2 = class DiscoveryListController extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "composer", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
    _initializerDefineProperty(this, "currentUser", _descriptor4, this);
    _initializerDefineProperty(this, "router", _descriptor5, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor6, this);
    _initializerDefineProperty(this, "modal", _descriptor7, this);
    _initializerDefineProperty(this, "model", _descriptor8, this);
    _defineProperty(this, "queryParams", Object.keys(queryParams));
    _defineProperty(this, "bulkSelectHelper", new BulkSelectHelper(this));
    for (const [name, info] of Object.entries(queryParams)) {
      defineTrackedProperty(this, name, info.default);
    }
  }
  get canBulkSelect() {
    return this.currentUser?.canManageTopic || this.showDismissRead || this.showResetNew;
  }
  get showDismissRead() {
    return filterTypeForMode(this.model.list?.filter) === "unread" && this.model.list.get("topics.length") > 0;
  }
  get showResetNew() {
    return filterTypeForMode(this.model.list?.filter) === "new" && this.model.list?.get("topics.length") > 0;
  }
  get createTopicTargetCategory() {
    const {
      category
    } = this.model;
    if (category?.canCreateTopic) {
      return category;
    }
    if (this.siteSettings.default_subcategory_on_read_only_category) {
      return category?.subcategoryWithCreateTopicPermission;
    }
  }
  get createTopicDisabled() {
    // We are in a category route, but user does not have permission for the category
    return this.model.category && !this.createTopicTargetCategory;
  }
  get resolvedAscending() {
    return (this.ascending ?? this.model.list.get("params.ascending")) === "true";
  }
  get resolvedOrder() {
    return this.order ?? this.model.list.get("params.order") ?? "activity";
  }
  async callResetNew() {
    let dismissPosts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    let dismissTopics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let untrack = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    const isTracked = (this.router.currentRoute.queryParams["f"] || this.router.currentRoute.queryParams["filter"]) === "tracked";
    let topicIds = this.bulkSelectHelper.selected.map(topic => topic.id);
    const result = await Topic.resetNew(this.model.category, !this.model.noSubcategories, {
      tracked: isTracked,
      tag: this.model.tag,
      topicIds,
      dismissPosts,
      dismissTopics,
      untrack
    });
    if (result.topic_ids) {
      this.topicTrackingState.removeTopics(result.topic_ids);
    }
    this.router.refresh();
  }
  resetNew() {
    if (!this.currentUser.new_new_view_enabled) {
      return this.callResetNew();
    }
    this.modal.show(DismissNew, {
      model: {
        selectedTopics: this.bulkSelectHelper.selected,
        subset: this.model.listParams?.subset,
        dismissCallback: _ref => {
          let {
            dismissPosts,
            dismissTopics,
            untrack
          } = _ref;
          this.callResetNew(dismissPosts, dismissTopics, untrack);
        }
      }
    });
  }
  createTopic() {
    this.composer.openNewTopic({
      category: this.createTopicTargetCategory,
      tags: [this.model.tag?.id, ...(this.model.additionalTags ?? [])].filter(Boolean).reject(t => ["none", "all"].includes(t)).join(","),
      preferDraft: true
    });
  }
  changePeriod(p) {
    this.period = p;
  }
  changeSort(sortBy) {
    if (sortBy === this.resolvedOrder) {
      this.ascending = !this.resolvedAscending;
    } else {
      this.ascending = false;
    }
    this.order = sortBy;
  }
  changeNewListSubset(subset) {
    this.subset = subset;
    this.model.list.updateNewListSubsetParam(subset);
  }
  toggleTagInfo() {
    this.toggleProperty("showTagInfo");
  }
  dismissRead(dismissTopics) {
    const operationType = dismissTopics ? "topics" : "posts";
    this.bulkSelectHelper.dismissRead(operationType, {
      categoryId: this.model.category?.id,
      tagName: this.model.tag?.id,
      includeSubcategories: this.model.noSubcategories
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "model", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "resetNew", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetNew"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createTopic", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "createTopic"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changePeriod", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "changePeriod"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeSort", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeSort"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeNewListSubset", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeNewListSubset"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleTagInfo", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleTagInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dismissRead", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "dismissRead"), _class2.prototype)), _class2)) || _class;
export { DiscoveryListController as default };