import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("bookmark-menu-shim", "div.bookmark-menu-shim", createTemplateFactory(
/*
  <BookmarkMenu @bookmarkManager={{@data.bookmarkManager}} @buttonClasses="btn-flat" />
*/
{
  "id": "eucoNTax",
  "block": "[[[8,[39,0],null,[[\"@bookmarkManager\",\"@buttonClasses\"],[[30,1,[\"bookmarkManager\"]],\"btn-flat\"]],null]],[\"@data\"],false,[\"bookmark-menu\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/bookmark-menu.js",
  "isStrictMode": false
}));