import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _obj, _init, _init2, _init3, _init4;
/* import __COLOCATED_TEMPLATE__ from './user-card-contents.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import EmberObject, { action, set } from "@ember/object";
import { alias, and, gt, gte, not, or } from "@ember/object/computed";
import { dasherize } from "@ember/string";
import { isEmpty } from "@ember/utils";
import { propertyNotEqual, setting } from "discourse/lib/computed";
import { durationTiny } from "discourse/lib/formatter";
import { prioritizeNameInUx } from "discourse/lib/settings";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression, modKeysPressed } from "discourse/lib/utilities";
import CanCheckEmails from "discourse/mixins/can-check-emails";
import CardContentsBase from "discourse/mixins/card-contents-base";
import CleansUp from "discourse/mixins/cleans-up";
import User from "discourse/models/user";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./user-card-contents.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(CardContentsBase, CanCheckEmails, CleansUp, (_dec = discourseComputed("user"), _dec2 = discourseComputed("user"), _dec3 = discourseComputed("user.status"), _dec4 = discourseComputed("user.status.emoji"), _dec5 = discourseComputed("user.staff"), _dec6 = discourseComputed("user.trust_level"), _dec7 = discourseComputed("user.name"), _dec8 = discourseComputed("user"), _dec9 = discourseComputed("userTimezone"), _dec10 = discourseComputed("username"), _dec11 = discourseComputed("username", "topicPostCount"), _dec12 = discourseComputed("user.user_fields.@each.value"), _dec13 = discourseComputed("user.trust_level"), _dec14 = discourseComputed("user.badge_count", "user.featured_user_badges.length"), _dec15 = discourseComputed("user.time_read", "user.recent_time_read"), _dec16 = discourseComputed("user.recent_time_read"), _dec17 = discourseComputed("showRecentTimeRead", "user.time_read", "recentTimeRead"), _dec18 = observes("user.card_background_upload_url"), _dec19 = discourseComputed("user.primary_group_name"), _dec20 = discourseComputed("user.profile_hidden", "user.inactive"), (_obj = {
  elementId: "user-card",
  classNames: "user-card",
  avatarSelector: "[data-user-card]",
  avatarDataAttrKey: "userCard",
  mentionSelector: "a.mention",
  classNameBindings: ["visible:show", "showBadges", "user.card_background_upload_url::no-bg", "isFixed:fixed", "usernameClass", "primaryGroup"],
  attributeBindings: ["labelledBy:aria-labelledby"],
  allowBackgrounds: setting("allow_profile_backgrounds"),
  showBadges: setting("enable_badges"),
  postStream: alias("topic.postStream"),
  enoughPostsForFiltering: gte("topicPostCount", 2),
  showFilter: and("viewingTopic", "postStream.hasNoFilters", "enoughPostsForFiltering"),
  showName: propertyNotEqual("user.name", "user.username"),
  hasUserFilters: gt("postStream.userFilters.length", 0),
  showMoreBadges: gt("moreBadgesCount", 0),
  showDelete: and("viewingAdmin", "showName", "user.canBeDeleted"),
  linkWebsite: not("user.isBasic"),
  labelledBy(user) {
    return user ? "discourse-user-card-title" : null;
  },
  hasLocaleOrWebsite(user) {
    return user.location || user.website_name || this.userTimezone;
  },
  hasStatus() {
    return this.siteSettings.enable_user_status && this.user.status;
  },
  userStatusEmoji(emoji) {
    return emojiUnescape(escapeExpression(`:${emoji}:`));
  },
  isSuspendedOrHasBio: or("user.suspend_reason", "user.bio_excerpt"),
  showCheckEmail: and("user.staged", "canCheckEmails"),
  user: null,
  // If inside a topic
  topicPostCount: null,
  showFeaturedTopic: and("user.featured_topic", "siteSettings.allow_featured_topic_on_user_profiles"),
  showUserLocalTime: setting("display_local_time_in_user_card"),
  staff: isStaff => isStaff ? "staff" : "",
  newUser: trustLevel => trustLevel === 0 ? "new-user" : "",
  nameFirst(name) {
    return prioritizeNameInUx(name);
  },
  userTimezone(user) {
    if (!this.showUserLocalTime) {
      return;
    }
    return user.get("user_option.timezone");
  },
  formattedUserLocalTime(timezone) {
    return moment.tz(timezone).format(I18n.t("dates.time"));
  },
  usernameClass: username => username ? `user-card-${username}` : "",
  filterPostsLabel(username, count) {
    return I18n.t("topic.filter_to", {
      username,
      count
    });
  },
  publicUserFields() {
    const siteUserFields = this.site.get("user_fields");
    if (!isEmpty(siteUserFields)) {
      const userFields = this.get("user.user_fields");
      return siteUserFields.filterBy("show_on_user_card", true).sortBy("position").map(field => {
        set(field, "dasherized_name", dasherize(field.get("name")));
        const value = userFields ? userFields[field.get("id")] : null;
        return isEmpty(value) ? null : EmberObject.create({
          value,
          field
        });
      }).compact();
    }
  },
  removeNoFollow(trustLevel) {
    return trustLevel > 2 && !this.siteSettings.tl3_links_no_follow;
  },
  moreBadgesCount: (badgeCount, badgeLength) => badgeCount - badgeLength,
  showRecentTimeRead(timeRead, recentTimeRead) {
    return timeRead !== recentTimeRead && recentTimeRead !== 0;
  },
  recentTimeRead(recentTimeReadSeconds) {
    return durationTiny(recentTimeReadSeconds);
  },
  timeReadTooltip(showRecent, timeRead, recentTimeRead) {
    if (showRecent) {
      return I18n.t("time_read_recently_tooltip", {
        time_read: durationTiny(timeRead),
        recent_time_read: recentTimeRead
      });
    } else {
      return I18n.t("time_read_tooltip", {
        time_read: durationTiny(timeRead)
      });
    }
  },
  addBackground() {
    if (!this.allowBackgrounds) {
      return;
    }
    const thisElem = this.element;
    if (!thisElem) {
      return;
    }
    const url = this.get("user.card_background_upload_url");
    const bg = isEmpty(url) ? "" : `url(${getURLWithCDN(url)})`;
    thisElem.style.backgroundImage = bg;
  },
  primaryGroup(primaryGroup) {
    return `group-${primaryGroup}`;
  },
  contentHidden(profileHidden, inactive) {
    return profileHidden || inactive;
  },
  _showCallback(username, $target) {
    this._positionCard($target);
    this.setProperties({
      visible: true,
      loading: true
    });
    const args = {
      forCard: true,
      include_post_count_for: this.get("topic.id")
    };
    return User.findByUsername(username, args).then(user => {
      if (user.topic_post_count) {
        this.set("topicPostCount", user.topic_post_count[args.include_post_count_for]);
      }
      this.setProperties({
        user
      });
      this.user.statusManager.trackStatus();
      return user;
    }).catch(() => this._close()).finally(() => this.set("loading", null));
  },
  _close() {
    if (this.user) {
      this.user.statusManager.stopTrackingStatus();
    }
    this.setProperties({
      user: null,
      topicPostCount: null
    });
    this._super(...arguments);
  },
  cleanUp() {
    this._close();
  },
  handleShowUser(user, event) {
    if (event && modKeysPressed(event).length > 0) {
      return false;
    }
    event?.preventDefault();
    // Invokes `showUser` argument. Convert to `this.args.showUser` when
    // refactoring this to a glimmer component.
    this.showUser(user);
    this._close();
  },
  actions: {
    close() {
      this._close();
    },
    composePM(user, post) {
      this._close();
      this.composePrivateMessage(user, post);
    },
    cancelFilter() {
      const postStream = this.postStream;
      postStream.cancelFilter();
      postStream.refresh();
      this._close();
    },
    filterPosts() {
      this.filterPosts(this.user);
      this._close();
    },
    deleteUser() {
      this.user.delete();
      this._close();
    },
    showUser(user) {
      this.handleShowUser(user);
    },
    checkEmail(user) {
      user.checkEmail();
    }
  }
}, (_applyDecoratedDescriptor(_obj, "labelledBy", [_dec], Object.getOwnPropertyDescriptor(_obj, "labelledBy"), _obj), _applyDecoratedDescriptor(_obj, "hasLocaleOrWebsite", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasLocaleOrWebsite"), _obj), _applyDecoratedDescriptor(_obj, "hasStatus", [_dec3], Object.getOwnPropertyDescriptor(_obj, "hasStatus"), _obj), _applyDecoratedDescriptor(_obj, "userStatusEmoji", [_dec4], Object.getOwnPropertyDescriptor(_obj, "userStatusEmoji"), _obj), _applyDecoratedDescriptor(_obj, "staff", [_dec5], (_init = Object.getOwnPropertyDescriptor(_obj, "staff"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "newUser", [_dec6], (_init2 = Object.getOwnPropertyDescriptor(_obj, "newUser"), _init2 = _init2 ? _init2.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init2;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "nameFirst", [_dec7], Object.getOwnPropertyDescriptor(_obj, "nameFirst"), _obj), _applyDecoratedDescriptor(_obj, "userTimezone", [_dec8], Object.getOwnPropertyDescriptor(_obj, "userTimezone"), _obj), _applyDecoratedDescriptor(_obj, "formattedUserLocalTime", [_dec9], Object.getOwnPropertyDescriptor(_obj, "formattedUserLocalTime"), _obj), _applyDecoratedDescriptor(_obj, "usernameClass", [_dec10], (_init3 = Object.getOwnPropertyDescriptor(_obj, "usernameClass"), _init3 = _init3 ? _init3.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init3;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "filterPostsLabel", [_dec11], Object.getOwnPropertyDescriptor(_obj, "filterPostsLabel"), _obj), _applyDecoratedDescriptor(_obj, "publicUserFields", [_dec12], Object.getOwnPropertyDescriptor(_obj, "publicUserFields"), _obj), _applyDecoratedDescriptor(_obj, "removeNoFollow", [_dec13], Object.getOwnPropertyDescriptor(_obj, "removeNoFollow"), _obj), _applyDecoratedDescriptor(_obj, "moreBadgesCount", [_dec14], (_init4 = Object.getOwnPropertyDescriptor(_obj, "moreBadgesCount"), _init4 = _init4 ? _init4.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init4;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "showRecentTimeRead", [_dec15], Object.getOwnPropertyDescriptor(_obj, "showRecentTimeRead"), _obj), _applyDecoratedDescriptor(_obj, "recentTimeRead", [_dec16], Object.getOwnPropertyDescriptor(_obj, "recentTimeRead"), _obj), _applyDecoratedDescriptor(_obj, "timeReadTooltip", [_dec17], Object.getOwnPropertyDescriptor(_obj, "timeReadTooltip"), _obj), _applyDecoratedDescriptor(_obj, "addBackground", [_dec18], Object.getOwnPropertyDescriptor(_obj, "addBackground"), _obj), _applyDecoratedDescriptor(_obj, "primaryGroup", [_dec19], Object.getOwnPropertyDescriptor(_obj, "primaryGroup"), _obj), _applyDecoratedDescriptor(_obj, "contentHidden", [_dec20], Object.getOwnPropertyDescriptor(_obj, "contentHidden"), _obj), _applyDecoratedDescriptor(_obj, "handleShowUser", [action], Object.getOwnPropertyDescriptor(_obj, "handleShowUser"), _obj)), _obj))));