import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
import { tracked } from "@glimmer/tracking";
import { setOwner } from "@ember/application";
import { service } from "@ember/service";
import SidebarSectionForm from "discourse/components/modal/sidebar-section-form";
import { ajax } from "discourse/lib/ajax";
import SectionLink from "discourse/lib/sidebar/section-link";
import { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let Section = (_class = class Section {
  constructor(_ref) {
    let {
      section,
      owner
    } = _ref;
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "modal", _descriptor2, this);
    _initializerDefineProperty(this, "router", _descriptor3, this);
    _initializerDefineProperty(this, "dragCss", _descriptor4, this);
    _initializerDefineProperty(this, "links", _descriptor5, this);
    setOwner(this, owner);
    this.section = section;
    this.slug = section.slug;
    this.links = this.section.links.map(link => {
      return new SectionLink(link, this, this.router);
    });
  }
  get decoratedTitle() {
    return this.section.title;
  }
  get indicatePublic() {
    return this.section.public && this.currentUser?.staff;
  }
  get headerActions() {
    if (!this.section.public || this.currentUser?.admin) {
      return [{
        action: () => {
          return this.modal.show(SidebarSectionForm, {
            model: this
          });
        },
        title: I18n.t("sidebar.sections.custom.edit")
      }];
    }
  }
  get headerActionIcon() {
    return "pencil-alt";
  }
  disable() {
    this.dragCss = "disabled";
  }
  enable() {
    this.dragCss = null;
  }
  moveLinkDown(link) {
    const position = this.links.indexOf(link) + 1;
    this.links = this.links.removeObject(link);
    this.links.splice(position, 0, link);
  }
  moveLinkUp(link) {
    const position = this.links.indexOf(link) - 1;
    this.links = this.links.removeObject(link);
    this.links.splice(position, 0, link);
  }
  reorder() {
    return ajax(`/sidebar_sections/reorder`, {
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify({
        sidebar_section_id: this.section.id,
        links_order: this.links.map(link => link.id)
      })
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dragCss", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "links", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "disable", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "disable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enable", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "enable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveLinkDown", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "moveLinkDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveLinkUp", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "moveLinkUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reorder", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "reorder"), _class.prototype)), _class);
export { Section as default };