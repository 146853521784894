import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import MountWidget from "discourse/components/mount-widget";
import { observes } from "discourse-common/utils/decorators";
export default MountWidget.extend((_dec = observes("flairName", "flairUrl", "flairBgColor", "flairColor"), (_obj = {
  widget: "avatar-flair",
  _rerender() {
    this.queueRerender();
  },
  buildArgs() {
    return {
      flair_name: this.flairName,
      flair_url: this.flairUrl,
      flair_bg_color: this.flairBgColor,
      flair_color: this.flairColor
    };
  }
}, (_applyDecoratedDescriptor(_obj, "_rerender", [_dec], Object.getOwnPropertyDescriptor(_obj, "_rerender"), _obj)), _obj)));