import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _CardContainer;
import Component from "@glimmer/component";
import { inject as controller } from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import GroupCardContents from "discourse/components/group-card-contents";
import UserCardContents from "discourse/components/user-card-contents";
import routeAction from "discourse/helpers/route-action";
import DiscourseURL, { groupPath, userPath } from "discourse/lib/url";
import PluginOutlet from "./plugin-outlet";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let CardContainer = (_class = (_CardContainer = class CardContainer extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "topic", _descriptor2, this);
  }
  filterPosts(user1) {
    this.topic.send("filterParticipant", user1);
  }
  showUser(user1) {
    DiscourseURL.routeTo(userPath(user1.username_lower));
  }
  showGroup(group1) {
    DiscourseURL.routeTo(groupPath(group1.name));
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.site.mobileView}}
      <div class="card-cloak hidden"></div>
    {{/if}}

    <PluginOutlet @name="user-card-content-container">
      <UserCardContents
        @topic={{this.topic.model}}
        @showUser={{this.showUser}}
        @filterPosts={{this.filterPosts}}
        @composePrivateMessage={{routeAction "composePrivateMessage"}}
        role="dialog"
      />
    </PluginOutlet>

    <GroupCardContents
      @topic={{this.topic.model}}
      @showUser={{this.showUser}}
      @showGroup={{this.showGroup}}
    />
  
*/
{
  "id": "tk0FRXzl",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"      \"],[10,0],[14,0,\"card-cloak hidden\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[32,0],null,[[\"@name\"],[\"user-card-content-container\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[24,\"role\",\"dialog\"]],[[\"@topic\",\"@showUser\",\"@filterPosts\",\"@composePrivateMessage\"],[[30,0,[\"topic\",\"model\"]],[30,0,[\"showUser\"]],[30,0,[\"filterPosts\"]],[28,[32,2],[\"composePrivateMessage\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[32,3],null,[[\"@topic\",\"@showUser\",\"@showGroup\"],[[30,0,[\"topic\",\"model\"]],[30,0,[\"showUser\"]],[30,0,[\"showGroup\"]]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/card-container.js",
  "scope": () => [PluginOutlet, UserCardContents, routeAction, GroupCardContents],
  "isStrictMode": true
}), _CardContainer), _CardContainer), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topic", [controller], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "filterPosts", [action], Object.getOwnPropertyDescriptor(_class.prototype, "filterPosts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showUser", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showGroup", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showGroup"), _class.prototype)), _class);
export { CardContainer as default };