import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
/* import __COLOCATED_TEMPLATE__ from './second-factor-add-totp.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { MAX_SECOND_FACTOR_NAME_LENGTH, SECOND_FACTOR_METHODS } from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-add-totp.hbs";
import { setComponentTemplate } from "@ember/component";
let SecondFactorAddTotp = (_class = class SecondFactorAddTotp extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loading", _descriptor, this);
    _initializerDefineProperty(this, "secondFactorImage", _descriptor2, this);
    _initializerDefineProperty(this, "secondFactorKey", _descriptor3, this);
    _initializerDefineProperty(this, "showSecondFactorKey", _descriptor4, this);
    _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
    _initializerDefineProperty(this, "secondFactorToken", _descriptor6, this);
    _defineProperty(this, "maxSecondFactorNameLength", MAX_SECOND_FACTOR_NAME_LENGTH);
    _defineProperty(this, "totpType", SECOND_FACTOR_METHODS.TOTP);
  }
  totpRequested() {
    this.args.model.secondFactor.createSecondFactorTotp().then(response => {
      if (response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.errorMessage = null;
      this.secondFactorKey = response.key;
      this.secondFactorImage = response.qr;
    }).catch(error => {
      this.args.closeModal();
      this.args.model.onError(error);
    }).finally(() => this.loading = false);
  }
  enableShowSecondFactorKey(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.showSecondFactorKey = true;
  }
  enableSecondFactor() {
    if (!this.secondFactorToken || !this.secondFactorName) {
      this.errorMessage = I18n.t("user.second_factor.totp.name_and_code_required_error");
      return;
    }
    this.loading = true;
    this.args.model.secondFactor.enableSecondFactorTotp(this.secondFactorToken, this.secondFactorName).then(response => {
      if (response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.args.model.markDirty();
      this.errorMessage = null;
      this.args.closeModal();
      if (this.args.model.enforcedSecondFactor) {
        window.location.reload();
      }
    }).catch(error => this.args.model.onError(error)).finally(() => this.loading = false);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "secondFactorImage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "secondFactorKey", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showSecondFactorKey", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "secondFactorToken", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "totpRequested", [action], Object.getOwnPropertyDescriptor(_class.prototype, "totpRequested"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableShowSecondFactorKey", [action], Object.getOwnPropertyDescriptor(_class.prototype, "enableShowSecondFactorKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableSecondFactor", [action], Object.getOwnPropertyDescriptor(_class.prototype, "enableSecondFactor"), _class.prototype)), _class);
export { SecondFactorAddTotp as default };
setComponentTemplate(TEMPLATE, SecondFactorAddTotp);