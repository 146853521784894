import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { or, reads } from "@ember/object/computed";
import { isNone } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import BulkSelectHelper from "discourse/lib/bulk-select-helper";
import { defineTrackedProperty } from "discourse/lib/tracked-tools";
import Topic from "discourse/models/topic";
import { NEW_FILTER, UNREAD_FILTER } from "discourse/routes/build-private-messages-route";
import { QUERY_PARAMS } from "discourse/routes/user-topic-list";
import discourseComputed from "discourse-common/utils/decorators";

// Lists of topics on a user's page.
let UserTopicsListController = (_dec = reads("pmTopicTrackingState.newIncoming.length"), _dec2 = or("currentUser.canManageTopic", "showDismissRead", "showResetNew"), _dec3 = discourseComputed("model.topics.length", "incomingCount"), _dec4 = discourseComputed("filter", "model.topics.length"), _dec5 = discourseComputed("filter", "model.topics.length"), (_class = class UserTopicsListController extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "model", _descriptor, this);
    _defineProperty(this, "hideCategory", false);
    _defineProperty(this, "showPosters", false);
    _defineProperty(this, "channel", null);
    _defineProperty(this, "tagsForUser", null);
    _defineProperty(this, "queryParams", Object.keys(QUERY_PARAMS));
    _defineProperty(this, "bulkSelectHelper", new BulkSelectHelper(this));
    _initializerDefineProperty(this, "incomingCount", _descriptor2, this);
    _initializerDefineProperty(this, "canBulkSelect", _descriptor3, this);
    for (const [name, info] of Object.entries(QUERY_PARAMS)) {
      defineTrackedProperty(this, name, info.default);
    }
  }
  get bulkSelectEnabled() {
    return this.bulkSelectHelper.bulkSelectEnabled;
  }
  get selected() {
    return this.bulkSelectHelper.selected;
  }
  noContent(topicsLength, incomingCount) {
    return topicsLength === 0 && incomingCount === 0;
  }
  showResetNew(filter, hasTopics) {
    return filter === NEW_FILTER && hasTopics;
  }
  showDismissRead(filter, hasTopics) {
    return filter === UNREAD_FILTER && hasTopics;
  }
  subscribe() {
    this.pmTopicTrackingState.trackIncoming(this.inbox, this.filter);
  }
  unsubscribe() {
    this.pmTopicTrackingState.stopIncomingTracking();
  }
  changeSort(sortBy) {
    if (sortBy === this.resolvedOrder) {
      this.ascending = !this.resolvedAscending;
    } else {
      this.ascending = false;
    }
    this.order = sortBy;
  }
  get resolvedAscending() {
    if (isNone(this.ascending)) {
      return this.model.get("params.ascending") === "true";
    } else {
      return this.ascending.toString() === "true";
    }
  }
  get resolvedOrder() {
    return this.order ?? this.model.get("params.order") ?? "activity";
  }
  resetNew() {
    const topicIds = this.selected ? this.selected.map(topic => topic.id) : null;
    const opts = {
      inbox: this.inbox,
      topicIds
    };
    if (this.group) {
      opts.groupName = this.group.name;
    }
    Topic.pmResetNew(opts).then(result => {
      if (result && result.topic_ids.length > 0) {
        this.pmTopicTrackingState.removeTopics(result.topic_ids);
        this.send("refresh");
      }
    });
  }
  loadMore() {
    this.model.loadMore();
  }
  async showInserted(event) {
    event?.preventDefault();
    if (this.model.loadingBefore) {
      return;
    }
    try {
      const topicIds = [...this.pmTopicTrackingState.newIncoming];
      await this.model.loadBefore(topicIds);
      this.pmTopicTrackingState.resetIncomingTracking(topicIds);
    } catch (e) {
      popupAjaxError(e);
    }
  }
  refresh() {
    this.send("triggerRefresh");
  }
  toggleBulkSelect() {
    this.bulkSelectHelper.toggleBulkSelect();
  }
  dismissRead(operationType, options) {
    this.bulkSelectHelper.dismissRead(operationType, options);
  }
  updateAutoAddTopicsToBulkSelect(value) {
    this.bulkSelectHelper.autoAddTopicsToBulkSelect = value;
  }
  addTopicsToBulkSelect(topics) {
    this.bulkSelectHelper.addTopics(topics);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "model", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "incomingCount", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canBulkSelect", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "noContent", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "noContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showResetNew", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showResetNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDismissRead", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showDismissRead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSort", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetNew", [action], Object.getOwnPropertyDescriptor(_class.prototype, "resetNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showInserted", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showInserted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBulkSelect", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismissRead", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dismissRead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAutoAddTopicsToBulkSelect", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAutoAddTopicsToBulkSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTopicsToBulkSelect", [action], Object.getOwnPropertyDescriptor(_class.prototype, "addTopicsToBulkSelect"), _class.prototype)), _class));
export { UserTopicsListController as default };