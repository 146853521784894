import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;
import { A } from "@ember/array";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { url } from "discourse/lib/computed";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression } from "discourse/lib/utilities";
import RestModel from "discourse/models/rest";
import Site from "discourse/models/site";
import UserAction from "discourse/models/user-action";
import discourseComputed from "discourse-common/utils/decorators";
let UserStream = (_dec = url("itemsLoaded", "user.username_lower", "/user_actions.json?offset=%@&username=%@"), _dec2 = discourseComputed("filter"), _dec3 = discourseComputed("baseUrl", "filterParam", "actingUsername"), _dec4 = discourseComputed("loaded", "content.[]"), _dec5 = discourseComputed("nextFindUrl", "lastLoadedUrl"), (_class = class UserStream extends RestModel {
  constructor() {
    super(...arguments);
    _defineProperty(this, "loaded", false);
    _defineProperty(this, "itemsLoaded", 0);
    _defineProperty(this, "content", []);
    _initializerDefineProperty(this, "baseUrl", _descriptor, this);
  }
  filterParam(filter) {
    if (filter === UserAction.TYPES.replies) {
      return [UserAction.TYPES.replies, UserAction.TYPES.quotes].join(",");
    }
    if (!filter) {
      return [UserAction.TYPES.topics, UserAction.TYPES.posts].join(",");
    }
    return filter;
  }
  filterBy(opts) {
    this.setProperties(Object.assign({
      itemsLoaded: 0,
      content: [],
      lastLoadedUrl: null
    }, opts));
    return this.findItems();
  }
  nextFindUrl() {
    let findUrl = this.baseUrl;
    if (this.filterParam) {
      findUrl += `&filter=${this.filterParam}`;
    }
    if (this.actingUsername) {
      findUrl += `&acting_username=${this.actingUsername}`;
    }
    return findUrl;
  }
  noContent(loaded, content) {
    return loaded && content.length === 0;
  }
  canLoadMore() {
    return this.nextFindUrl !== this.lastLoadedUrl;
  }
  remove(userAction) {
    // 1) remove the user action from the child groups
    this.content.forEach(ua => {
      ["likes", "stars", "edits", "bookmarks"].forEach(group => {
        const items = ua.get(`childGroups.${group}.items`);
        if (items) {
          items.removeObject(userAction);
        }
      });
    });

    // 2) remove the parents that have no children
    const content = this.content.filter(ua => {
      return ["likes", "stars", "edits", "bookmarks"].some(group => {
        return ua.get(`childGroups.${group}.items.length`) > 0;
      });
    });
    this.setProperties({
      content,
      itemsLoaded: content.length
    });
  }
  findItems() {
    if (!this.canLoadMore) {
      // Don't load the same stream twice. We're probably at the end.
      return Promise.resolve();
    }
    const findUrl = this.nextFindUrl;
    if (this.loading) {
      return Promise.resolve();
    }
    this.set("loading", true);
    return ajax(findUrl).then(result => {
      if (result && result.user_actions) {
        const copy = A();
        result.categories?.forEach(category => {
          Site.current().updateCategory(category);
        });
        result.user_actions.forEach(action => {
          action.title = emojiUnescape(escapeExpression(action.title));
          copy.pushObject(UserAction.create(action));
        });
        this.content.pushObjects(UserAction.collapseStream(copy));
        this.setProperties({
          itemsLoaded: this.itemsLoaded + result.user_actions.length
        });
      }
    }).finally(() => this.setProperties({
      loaded: true,
      loading: false,
      lastLoadedUrl: findUrl
    }));
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "baseUrl", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "filterParam", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "filterParam"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextFindUrl", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "nextFindUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noContent", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "noContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canLoadMore", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canLoadMore"), _class.prototype)), _class));
export { UserStream as default };