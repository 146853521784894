import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _UserDropdown;
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { or } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import i18n from "discourse-common/helpers/i18n";
import Notifications from "./user-dropdown/notifications";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let UserDropdown = (_class = (_UserDropdown = class UserDropdown extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
  click(e1) {
    if (wantsNewWindow(e1)) {
      return;
    }
    e1.preventDefault();
    this.args.toggleUserMenu();
    // remove the focus of the header dropdown button after clicking
    e1.target.tagName.toLowerCase() === "button" ? e1.target.blur() : e1.target.closest("button").blur();
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <li
      id="current-user"
      class={{concatClass
        (if @active "active")
        "header-dropdown-toggle current-user user-menu-panel"
      }}
    >
      <button
        class="icon btn-flat"
        aria-haspopup="true"
        aria-expanded={{@active}}
        aria-label={{i18n
          "user.account_possessive"
          name=(or this.currentUser.name this.currentUser.username)
        }}
        {{on "click" this.click}}
      >
        <Notifications @active={{@active}} />
      </button>
    </li>
  
*/
{
  "id": "rvGVLTjZ",
  "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,1,\"current-user\"],[15,0,[28,[32,0],[[52,[30,1],\"active\"],\"header-dropdown-toggle current-user user-menu-panel\"],null]],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"icon btn-flat\"],[24,\"aria-haspopup\",\"true\"],[16,\"aria-expanded\",[30,1]],[16,\"aria-label\",[28,[32,1],[\"user.account_possessive\"],[[\"name\"],[[28,[32,2],[[30,0,[\"currentUser\",\"name\"]],[30,0,[\"currentUser\",\"username\"]]],null]]]]],[4,[32,3],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@active\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@active\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/user-dropdown.js",
  "scope": () => [concatClass, i18n, or, on, Notifications],
  "isStrictMode": true
}), _UserDropdown), _UserDropdown), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "click", [action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class);
export { UserDropdown as default };