import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Controller from "@ember/controller";
import { action, computed } from "@ember/object";
import { alias, and } from "@ember/object/computed";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { makeArray } from "discourse-common/lib/helpers";
import discourseComputed from "discourse-common/utils/decorators";
export default Controller.extend((_dec = discourseComputed("model.trust_level", "model.groups"), _dec2 = discourseComputed("userCanIgnore", "model.staff"), _dec3 = discourseComputed("model.user_option.allow_private_messages"), (_obj = {
  ignoredUsernames: alias("model.ignored_usernames"),
  userCanIgnore(trustLevel) {
    return trustLevel >= this.siteSettings.min_trust_level_to_allow_ignore || this.currentUser.can_ignore_users;
  },
  ignoredEnabled(userCanIgnore, userIsStaff) {
    return this.currentUser.staff || userCanIgnore || userIsStaff;
  },
  allowPmUsersEnabled: and("model.user_option.enable_allowed_pm_users", "model.user_option.allow_private_messages"),
  mutedUsernames: computed("model.muted_usernames", {
    get() {
      let usernames = this.model.muted_usernames;
      if (typeof usernames === "string") {
        usernames = usernames.split(",").filter(Boolean);
      }
      return makeArray(usernames).uniq();
    }
  }),
  allowedPmUsernames: computed("model.allowed_pm_usernames", {
    get() {
      let usernames = this.model.allowed_pm_usernames;
      if (typeof usernames === "string") {
        usernames = usernames.split(",").filter(Boolean);
      }
      return makeArray(usernames).uniq();
    }
  }),
  init() {
    this._super(...arguments);
    this.saveAttrNames = ["allow_private_messages", "muted_usernames", "allowed_pm_usernames", "enable_allowed_pm_users"];
  },
  onChangeMutedUsernames(usernames) {
    this.model.set("muted_usernames", usernames.uniq().join(","));
  },
  onChangeAllowedPmUsernames(usernames) {
    this.model.set("allowed_pm_usernames", usernames.uniq().join(","));
  },
  disableAllowPmUsersSetting(allowPrivateMessages) {
    return !allowPrivateMessages;
  },
  save() {
    this.set("saved", false);
    return this.model.save(this.saveAttrNames).then(() => this.set("saved", true)).catch(popupAjaxError);
  }
}, (_applyDecoratedDescriptor(_obj, "userCanIgnore", [_dec], Object.getOwnPropertyDescriptor(_obj, "userCanIgnore"), _obj), _applyDecoratedDescriptor(_obj, "ignoredEnabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "ignoredEnabled"), _obj), _applyDecoratedDescriptor(_obj, "onChangeMutedUsernames", [action], Object.getOwnPropertyDescriptor(_obj, "onChangeMutedUsernames"), _obj), _applyDecoratedDescriptor(_obj, "onChangeAllowedPmUsernames", [action], Object.getOwnPropertyDescriptor(_obj, "onChangeAllowedPmUsernames"), _obj), _applyDecoratedDescriptor(_obj, "disableAllowPmUsersSetting", [_dec3], Object.getOwnPropertyDescriptor(_obj, "disableAllowPmUsersSetting"), _obj), _applyDecoratedDescriptor(_obj, "save", [action], Object.getOwnPropertyDescriptor(_obj, "save"), _obj)), _obj)));