import { on } from "@ember/modifier";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarSectionLinkButton = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="sidebar-section-link-wrapper">
    <button
      {{on "click" @action}}
      type="button"
      class="sidebar-section-link sidebar-row --link-button"
    >
      <span class="sidebar-section-link-prefix icon">
        {{icon @icon}}
      </span>

      <span class="sidebar-section-link-content-text">
        {{@text}}
      </span>
    </button>
  </div>

*/
{
  "id": "n7+hJdQ2",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"sidebar-section-link-wrapper\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"sidebar-section-link sidebar-row --link-button\"],[24,4,\"button\"],[4,[32,0],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[10,1],[14,0,\"sidebar-section-link-prefix icon\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[[30,2]],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,1],[14,0,\"sidebar-section-link-content-text\"],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@action\",\"@icon\",\"@text\"],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/section-link-button.js",
  "scope": () => [on, icon],
  "isStrictMode": true
}), templateOnly());
export default SidebarSectionLinkButton;