import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './reviewable-score.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { gt } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-score.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("rs.score_type.title", "reviewable.target_created_by"), (_obj = {
  tagName: "",
  showStatus: gt("rs.status", 0),
  title(title, targetCreatedBy) {
    if (title && targetCreatedBy) {
      return title.replace(/{{username}}|%{username}/, targetCreatedBy.username);
    }
    return title;
  }
}, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj))));