import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _UserAction;
import { equal, or } from "@ember/object/computed";
import { service } from "@ember/service";
import { userPath } from "discourse/lib/url";
import { postUrl } from "discourse/lib/utilities";
import RestModel from "discourse/models/rest";
import UserActionGroup from "discourse/models/user-action-group";
import discourseComputed from "discourse-common/utils/decorators";
import Category from "./category";
const UserActionTypes = {
  likes_given: 1,
  likes_received: 2,
  bookmarks: 3,
  topics: 4,
  posts: 5,
  replies: 6,
  mentions: 7,
  quotes: 9,
  edits: 11,
  messages_sent: 12,
  messages_received: 13,
  links: 17
};
const InvertedActionTypes = {};
Object.keys(UserActionTypes).forEach(k => InvertedActionTypes[k] = UserActionTypes[k]);
let UserAction = (_dec = or("name", "username"), _dec2 = or("target_name", "target_username"), _dec3 = or("acting_name", "acting_username"), _dec4 = equal("action_type", UserActionTypes.replies), _dec5 = equal("action_type", UserActionTypes.posts), _dec6 = equal("action_type", UserActionTypes.topics), _dec7 = equal("action_type", UserActionTypes.bookmarks), _dec8 = equal("action_type", UserActionTypes.messages_sent), _dec9 = equal("action_type", UserActionTypes.messages_received), _dec10 = equal("action_type", UserActionTypes.mentions), _dec11 = or("messageSentType", "messageReceivedType"), _dec12 = or("postType", "replyType"), _dec13 = discourseComputed("category_id"), _dec14 = discourseComputed("action_type"), _dec15 = discourseComputed("username"), _dec16 = discourseComputed("target_username"), _dec17 = discourseComputed("target_username"), _dec18 = discourseComputed("username"), _dec19 = discourseComputed("usernameLower"), _dec20 = discourseComputed(), _dec21 = discourseComputed(), _dec22 = discourseComputed("childGroups", "childGroups.likes.items", "childGroups.likes.items.[]", "childGroups.stars.items", "childGroups.stars.items.[]", "childGroups.edits.items", "childGroups.edits.items.[]", "childGroups.bookmarks.items", "childGroups.bookmarks.items.[]"), (_class = (_UserAction = class UserAction extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "presentName", _descriptor2, this);
    _initializerDefineProperty(this, "targetDisplayName", _descriptor3, this);
    _initializerDefineProperty(this, "actingDisplayName", _descriptor4, this);
    _initializerDefineProperty(this, "replyType", _descriptor5, this);
    _initializerDefineProperty(this, "postType", _descriptor6, this);
    _initializerDefineProperty(this, "topicType", _descriptor7, this);
    _initializerDefineProperty(this, "bookmarkType", _descriptor8, this);
    _initializerDefineProperty(this, "messageSentType", _descriptor9, this);
    _initializerDefineProperty(this, "messageReceivedType", _descriptor10, this);
    _initializerDefineProperty(this, "mentionType", _descriptor11, this);
    _initializerDefineProperty(this, "isPM", _descriptor12, this);
    _initializerDefineProperty(this, "postReplyType", _descriptor13, this);
  }
  static collapseStream(stream) {
    const uniq = {};
    const collapsed = [];
    let pos = 0;
    stream.forEach(item => {
      const key = "" + item.topic_id + "-" + item.post_number;
      const found = uniq[key];
      if (found === void 0) {
        let current;
        if (UserAction.TO_COLLAPSE.includes(item.action_type)) {
          current = UserAction.create(item);
          item.switchToActing();
          current.addChild(item);
        } else {
          current = item;
        }
        uniq[key] = pos;
        collapsed[pos] = current;
        pos += 1;
      } else {
        if (UserAction.TO_COLLAPSE.includes(item.action_type)) {
          item.switchToActing();
          collapsed[found].addChild(item);
        } else {
          collapsed[found].setProperties(item.getProperties("action_type", "description"));
        }
      }
    });
    return collapsed;
  }
  category() {
    return Category.findById(this.category_id);
  }
  descriptionKey(action) {
    if (action === null || UserAction.TO_SHOW.includes(action)) {
      if (this.isPM) {
        return this.sameUser ? "sent_by_you" : "sent_by_user";
      } else {
        return this.sameUser ? "posted_by_you" : "posted_by_user";
      }
    }
    if (this.topicType) {
      return this.sameUser ? "you_posted_topic" : "user_posted_topic";
    }
    if (this.postReplyType) {
      if (this.reply_to_post_number) {
        return this.sameUser ? "you_replied_to_post" : "user_replied_to_post";
      } else {
        return this.sameUser ? "you_replied_to_topic" : "user_replied_to_topic";
      }
    }
    if (this.mentionType) {
      if (this.sameUser) {
        return "you_mentioned_user";
      } else {
        return this.targetUser ? "user_mentioned_you" : "user_mentioned_user";
      }
    }
  }
  sameUser(username) {
    return username === this.currentUser?.get("username");
  }
  targetUser(targetUsername) {
    return targetUsername === this.currentUser?.get("username");
  }
  targetUserUrl(username) {
    return userPath(username);
  }
  usernameLower(username) {
    return username.toLowerCase();
  }
  userUrl(usernameLower) {
    return userPath(usernameLower);
  }
  postUrl() {
    return postUrl(this.slug, this.topic_id, this.post_number);
  }
  replyUrl() {
    return postUrl(this.slug, this.topic_id, this.reply_to_post_number);
  }
  addChild(action) {
    let groups = this.childGroups;
    if (!groups) {
      groups = {
        likes: UserActionGroup.create({
          icon: "heart"
        }),
        stars: UserActionGroup.create({
          icon: "star"
        }),
        edits: UserActionGroup.create({
          icon: "pencil-alt"
        }),
        bookmarks: UserActionGroup.create({
          icon: "bookmark"
        })
      };
    }
    this.set("childGroups", groups);
    const bucket = function () {
      switch (action.action_type) {
        case UserActionTypes.likes_given:
        case UserActionTypes.likes_received:
          return "likes";
        case UserActionTypes.edits:
          return "edits";
        case UserActionTypes.bookmarks:
          return "bookmarks";
      }
    }();
    const current = groups[bucket];
    if (current) {
      current.push(action);
    }
  }
  children() {
    const g = this.childGroups;
    let rval = [];
    if (g) {
      rval = [g.likes, g.stars, g.edits, g.bookmarks].filter(function (i) {
        return i.get("items") && i.get("items").length > 0;
      });
    }
    return rval;
  }
  switchToActing() {
    this.setProperties({
      username: this.acting_username,
      name: this.actingDisplayName
    });
  }
}, _defineProperty(_UserAction, "TYPES", UserActionTypes), _defineProperty(_UserAction, "TYPES_INVERTED", InvertedActionTypes), _defineProperty(_UserAction, "TO_COLLAPSE", [UserActionTypes.likes_given, UserActionTypes.likes_received, UserActionTypes.edits, UserActionTypes.bookmarks]), _defineProperty(_UserAction, "TO_SHOW", [UserActionTypes.likes_given, UserActionTypes.likes_received, UserActionTypes.edits, UserActionTypes.bookmarks, UserActionTypes.messages_sent, UserActionTypes.messages_received]), _UserAction), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "presentName", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "targetDisplayName", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "actingDisplayName", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "replyType", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "postType", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "topicType", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "bookmarkType", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "messageSentType", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "messageReceivedType", [_dec9], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "mentionType", [_dec10], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isPM", [_dec11], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "postReplyType", [_dec12], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "category", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "category"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "descriptionKey", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "descriptionKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sameUser", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "sameUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "targetUser", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "targetUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "targetUserUrl", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "targetUserUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "usernameLower", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "usernameLower"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userUrl", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "userUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postUrl", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "postUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replyUrl", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "replyUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "children", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "children"), _class.prototype)), _class));
export { UserAction as default };