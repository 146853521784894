import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _Contents;
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { service } from "@ember/service";
import { and } from "truth-helpers";
import BootstrapModeNotice from "../bootstrap-mode-notice";
import PluginOutlet from "../plugin-outlet";
import HomeLogo from "./home-logo";
import SidebarToggle from "./sidebar-toggle";
import TopicInfo from "./topic/info";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let Contents = (_class = (_Contents = class Contents extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    _initializerDefineProperty(this, "header", _descriptor4, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor5, this);
  }
  get topicPresent() {
    return !!this.header.topic;
  }
  get sidebarIcon() {
    if (this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu) {
      return "discourse-sidebar";
    }
    return "bars";
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="contents">
      {{#if this.site.desktopView}}
        {{#if @sidebarEnabled}}
          <SidebarToggle
            @toggleNavigationMenu={{@toggleNavigationMenu}}
            @showSidebar={{@showSidebar}}
            @icon={{this.sidebarIcon}}
          />
        {{/if}}
      {{/if}}

      <div class="home-logo-wrapper-outlet">
        <PluginOutlet @name="home-logo-wrapper">
          <HomeLogo @minimized={{this.topicPresent}} />
        </PluginOutlet>
      </div>

      {{#if this.header.topic}}
        <TopicInfo @topic={{this.header.topic}} />
      {{else if
        (and
          this.siteSettings.bootstrap_mode_enabled
          this.currentUser.staff
          this.site.desktopView
        )
      }}
        <div class="d-header-mode">
          <BootstrapModeNotice />
        </div>
      {{/if}}

      <div class="before-header-panel-outlet">
        <PluginOutlet
          @name="before-header-panel"
          @outletArgs={{hash topic=this.header.topic}}
        />
      </div>
      <div class="panel" role="navigation">{{yield}}</div>
      <div class="after-header-panel-outlet">
        <PluginOutlet
          @name="after-header-panel"
          @outletArgs={{hash topic=this.header.topic}}
        />
      </div>
    </div>
  
*/
{
  "id": "M4o0mfye",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"contents\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[41,[30,1],[[[1,\"          \"],[8,[32,0],null,[[\"@toggleNavigationMenu\",\"@showSidebar\",\"@icon\"],[[30,2],[30,3],[30,0,[\"sidebarIcon\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"home-logo-wrapper-outlet\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@name\"],[\"home-logo-wrapper\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],null,[[\"@minimized\"],[[30,0,[\"topicPresent\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"header\",\"topic\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@topic\"],[[30,0,[\"header\",\"topic\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,4],[[30,0,[\"siteSettings\",\"bootstrap_mode_enabled\"]],[30,0,[\"currentUser\",\"staff\"]],[30,0,[\"site\",\"desktopView\"]]],null],[[[1,\"        \"],[10,0],[14,0,\"d-header-mode\"],[12],[1,\"\\n          \"],[8,[32,5],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]],[1,\"\\n      \"],[10,0],[14,0,\"before-header-panel-outlet\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@name\",\"@outletArgs\"],[\"before-header-panel\",[28,[32,6],null,[[\"topic\"],[[30,0,[\"header\",\"topic\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"panel\"],[14,\"role\",\"navigation\"],[12],[18,4,null],[13],[1,\"\\n      \"],[10,0],[14,0,\"after-header-panel-outlet\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@name\",\"@outletArgs\"],[\"after-header-panel\",[28,[32,6],null,[[\"topic\"],[[30,0,[\"header\",\"topic\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@sidebarEnabled\",\"@toggleNavigationMenu\",\"@showSidebar\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/contents.js",
  "scope": () => [SidebarToggle, PluginOutlet, HomeLogo, TopicInfo, and, BootstrapModeNotice, hash],
  "isStrictMode": true
}), _Contents), _Contents), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "header", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { Contents as default };