import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
/* import __COLOCATED_TEMPLATE__ from './second-factor-backup-edit.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import { debounce } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-backup-edit.hbs";
import { setComponentTemplate } from "@ember/component";
let SecondFactorBackupEdit = (_dec = debounce(2000), (_class = class SecondFactorBackupEdit extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loading", _descriptor, this);
    _initializerDefineProperty(this, "errorMessage", _descriptor2, this);
    _initializerDefineProperty(this, "successMessage", _descriptor3, this);
    _initializerDefineProperty(this, "backupEnabled", _descriptor4, this);
    _initializerDefineProperty(this, "remainingCodes", _descriptor5, this);
    _initializerDefineProperty(this, "backupCodes", _descriptor6, this);
    _initializerDefineProperty(this, "secondFactorMethod", _descriptor7, this);
  }
  copyBackupCode(successful) {
    if (successful) {
      this.successMessage = I18n.t("user.second_factor_backup.copied_to_clipboard");
    } else {
      this.errorMessage = I18n.t("user.second_factor_backup.copy_to_clipboard_error");
    }
    this._hideCopyMessage();
  }
  generateSecondFactorCodes() {
    this.loading = true;
    this.args.model.secondFactor.generateSecondFactorCodes().then(response => {
      if (response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.args.model.markDirty();
      this.errorMessage = null;
      this.backupCodes = response.backup_codes;
      this.args.model.setBackupEnabled(true);
      this.backupEnabled = true;
      this.remainingCodes = response.backup_codes.length;
      this.args.model.setCodesRemaining(this.remainingCodes);
    }).catch(error => {
      this.args.closeModal();
      this.args.model.onError(error);
    }).finally(() => this.loading = false);
  }
  _hideCopyMessage() {
    this.successMessage = null;
    this.errorMessage = null;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "successMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "backupEnabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.secondFactor.second_factor_backup_enabled;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "remainingCodes", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.secondFactor.second_factor_remaining_backup_codes;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "backupCodes", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "secondFactorMethod", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return SECOND_FACTOR_METHODS.TOTP;
  }
}), _applyDecoratedDescriptor(_class.prototype, "copyBackupCode", [action], Object.getOwnPropertyDescriptor(_class.prototype, "copyBackupCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateSecondFactorCodes", [action], Object.getOwnPropertyDescriptor(_class.prototype, "generateSecondFactorCodes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_hideCopyMessage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_hideCopyMessage"), _class.prototype)), _class));
export { SecondFactorBackupEdit as default };
setComponentTemplate(TEMPLATE, SecondFactorBackupEdit);