import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _SidebarFooter;
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import SidebarSectionForm from "discourse/components/modal/sidebar-section-form";
import PluginOutlet from "discourse/components/plugin-outlet";
import routeAction from "discourse/helpers/route-action";
import { ADMIN_PANEL } from "discourse/lib/sidebar/panels";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarFooter = (_class = (_SidebarFooter = class SidebarFooter extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "capabilities", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "modal", _descriptor3, this);
    _initializerDefineProperty(this, "site", _descriptor4, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor5, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor6, this);
  }
  get showManageSectionsButton() {
    return this.currentUser && !this.sidebarState.isCurrentPanel(ADMIN_PANEL);
  }
  get showToggleMobileButton() {
    return this.site.mobileView || this.siteSettings.enable_mobile_theme && this.capabilities.touch;
  }
  get showKeyboardShortcutsButton() {
    return this.site.desktopView;
  }
  manageSections() {
    this.modal.show(SidebarSectionForm);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="sidebar-footer-wrapper">
      <div class="sidebar-footer-container">
        <div class="sidebar-footer-actions">
          <PluginOutlet @name="sidebar-footer-actions" />

          {{#if this.showManageSectionsButton}}
            <DButton
              @icon="plus"
              @action={{this.manageSections}}
              @title="sidebar.sections.custom.add"
              class="btn-flat sidebar-footer-actions-button add-section"
            />
          {{/if}}

          {{#if this.showToggleMobileButton}}
            <DButton
              @action={{routeAction "toggleMobileView"}}
              @title={{if this.site.mobileView "desktop_view" "mobile_view"}}
              @icon={{if this.site.mobileView "desktop" "mobile-alt"}}
              class="btn-flat sidebar-footer-actions-button sidebar-footer-actions-toggle-mobile-view"
            />
          {{/if}}

          {{#if this.showKeyboardShortcutsButton}}
            <DButton
              @action={{routeAction "showKeyboardShortcutsHelp"}}
              @title="keyboard_shortcuts_help.title"
              @icon="keyboard"
              class="btn-flat sidebar-footer-actions-button sidebar-footer-actions-keyboard-shortcuts"
            />
          {{/if}}
        </div>
      </div>
    </div>
  
*/
{
  "id": "wm0hkpWN",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-footer-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"sidebar-footer-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-footer-actions\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@name\"],[\"sidebar-footer-actions\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showManageSectionsButton\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"btn-flat sidebar-footer-actions-button add-section\"]],[[\"@icon\",\"@action\",\"@title\"],[\"plus\",[30,0,[\"manageSections\"]],\"sidebar.sections.custom.add\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showToggleMobileButton\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"btn-flat sidebar-footer-actions-button sidebar-footer-actions-toggle-mobile-view\"]],[[\"@action\",\"@title\",\"@icon\"],[[28,[32,2],[\"toggleMobileView\"],null],[52,[30,0,[\"site\",\"mobileView\"]],\"desktop_view\",\"mobile_view\"],[52,[30,0,[\"site\",\"mobileView\"]],\"desktop\",\"mobile-alt\"]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showKeyboardShortcutsButton\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"btn-flat sidebar-footer-actions-button sidebar-footer-actions-keyboard-shortcuts\"]],[[\"@action\",\"@title\",\"@icon\"],[[28,[32,2],[\"showKeyboardShortcutsHelp\"],null],\"keyboard_shortcuts_help.title\",\"keyboard\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/footer.js",
  "scope": () => [PluginOutlet, DButton, routeAction],
  "isStrictMode": true
}), _SidebarFooter), _SidebarFooter), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "manageSections", [action], Object.getOwnPropertyDescriptor(_class.prototype, "manageSections"), _class.prototype)), _class);
export { SidebarFooter as default };