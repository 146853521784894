import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './search-menu-panel.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./search-menu-panel.hbs";
import { setComponentTemplate } from "@ember/component";
let SearchMenuPanel = (_class = class SearchMenuPanel extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
  }
  get animationClass() {
    return this.site.mobileView || this.site.narrowDesktopView ? "slide-in" : "drop-down";
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SearchMenuPanel as default };
setComponentTemplate(TEMPLATE, SearchMenuPanel);