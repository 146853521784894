import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './raw-email.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Post from "discourse/models/post";
import TEMPLATE from "./raw-email.hbs";
import { setComponentTemplate } from "@ember/component";
let RawEmailComponent = (_class = class RawEmailComponent extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "rawEmail", _descriptor, this);
    _initializerDefineProperty(this, "textPart", _descriptor2, this);
    _initializerDefineProperty(this, "htmlPart", _descriptor3, this);
    _initializerDefineProperty(this, "tab", _descriptor4, this);
    if (this.args.model.id) {
      this.loadRawEmail(this.args.model.id);
    }
  }
  async loadRawEmail(postId) {
    const result = await Post.loadRawEmail(postId);
    this.rawEmail = result.raw_email;
    this.textPart = result.text_part;
    this.htmlPart = result.html_part;
  }
  displayRaw() {
    this.tab = "raw";
  }
  displayTextPart() {
    this.tab = "text_part";
  }
  displayHtmlPart() {
    this.tab = "html_part";
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rawEmail", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.rawEmail || "";
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "textPart", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "htmlPart", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tab", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "raw";
  }
}), _applyDecoratedDescriptor(_class.prototype, "loadRawEmail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadRawEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayRaw", [action], Object.getOwnPropertyDescriptor(_class.prototype, "displayRaw"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayTextPart", [action], Object.getOwnPropertyDescriptor(_class.prototype, "displayTextPart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayHtmlPart", [action], Object.getOwnPropertyDescriptor(_class.prototype, "displayHtmlPart"), _class.prototype)), _class);
export { RawEmailComponent as default };
setComponentTemplate(TEMPLATE, RawEmailComponent);