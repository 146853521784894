import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _IframedHtml;
import Component from "@glimmer/component";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import { bind } from "discourse-common/utils/decorators";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let IframedHtml = (_class = (_IframedHtml = class IframedHtml extends Component {
  writeHtml(element1) {
    const iframeDoc1 = element1.contentWindow.document;
    iframeDoc1.open("text/html", "replace");
    iframeDoc1.write(this.args.html);
    iframeDoc1.close();
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{! template-lint-disable require-iframe-title }}
    <iframe
      {{didInsert this.writeHtml}}
      {{didUpdate this.witeHtml @html}}
      sandbox="allow-same-origin"
      class={{if @html "iframed-html"}}
      ...attributes
    ></iframe>
  
*/
{
  "id": "z2W2n4PA",
  "block": "[[[1,\"\\n\"],[1,\"    \"],[11,\"iframe\"],[24,\"sandbox\",\"allow-same-origin\"],[16,0,[52,[30,1],\"iframed-html\"]],[17,2],[4,[32,0],[[30,0,[\"writeHtml\"]]],null],[4,[32,1],[[30,0,[\"witeHtml\"]],[30,1]],null],[12],[13],[1,\"\\n  \"]],[\"@html\",\"&attrs\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/iframed-html.js",
  "scope": () => [didInsert, didUpdate],
  "isStrictMode": true
}), _IframedHtml), _IframedHtml), (_applyDecoratedDescriptor(_class.prototype, "writeHtml", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "writeHtml"), _class.prototype)), _class);
export { IframedHtml as default };