import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './google-search.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias } from "@ember/object/computed";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./google-search.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  classNames: ["google-search-form"],
  classNameBindings: ["hidden:hidden"],
  hidden: alias("siteSettings.login_required"),
  siteUrl() {
    return `${location.protocol}//${location.host}${getURL("/")}`;
  }
}, (_applyDecoratedDescriptor(_obj, "siteUrl", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "siteUrl"), _obj)), _obj)));