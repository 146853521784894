import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import Controller, { inject as controller } from "@ember/controller";
import { service } from "@ember/service";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("currentUser.draft_count"), _dec2 = discourseComputed("model.pending_posts_count"), (_obj = {
  currentUser: service(),
  user: controller(),
  userActionType: null,
  draftLabel(count) {
    return count > 0 ? I18n.t("drafts.label_with_count", {
      count
    }) : I18n.t("drafts.label");
  },
  pendingLabel(count) {
    return count > 0 ? I18n.t("pending_posts.label_with_count", {
      count
    }) : I18n.t("pending_posts.label");
  }
}, (_applyDecoratedDescriptor(_obj, "draftLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "draftLabel"), _obj), _applyDecoratedDescriptor(_obj, "pendingLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "pendingLabel"), _obj)), _obj)));