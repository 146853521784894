import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
/* import __COLOCATED_TEMPLATE__ from './avatar-selector.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { allowsImages } from "discourse/lib/uploads";
import { isTesting } from "discourse-common/config/environment";
import TEMPLATE from "./avatar-selector.hbs";
import { setComponentTemplate } from "@ember/component";
let AvatarSelectorModal = (_class = class AvatarSelectorModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "gravatarRefreshDisabled", _descriptor3, this);
    _initializerDefineProperty(this, "gravatarFailed", _descriptor4, this);
    _initializerDefineProperty(this, "uploading", _descriptor5, this);
    _initializerDefineProperty(this, "_selected", _descriptor6, this);
  }
  get user() {
    return this.args.model.user;
  }
  get selected() {
    return this._selected ?? this.defaultSelection;
  }
  set selected(value) {
    this._selected = value;
  }
  get submitDisabled() {
    return this.selected === "logo" || this.uploading;
  }
  get selectableAvatars() {
    const mode = this.siteSettings.selectable_avatars_mode;
    const list = this.siteSettings.selectable_avatars;
    return mode !== "disabled" ? list ? list.split("|") : [] : null;
  }
  get showSelectableAvatars() {
    return this.siteSettings.selectable_avatars_mode !== "disabled";
  }
  get showCustomAvatarSelector() {
    const mode = this.siteSettings.selectable_avatars_mode;
    switch (mode) {
      case "no_one":
        return false;
      case "tl1":
      case "tl2":
      case "tl3":
      case "tl4":
        const allowedTl = parseInt(mode.replace("tl", ""), 10);
        return this.user.admin || this.user.moderator || this.user.trust_level >= allowedTl;
      case "staff":
        return this.user.admin || this.user.moderator;
      case "everyone":
      default:
        return true;
    }
  }
  get defaultSelection() {
    if (this.user.use_logo_small_as_avatar) {
      return "logo";
    } else if (this.user.avatar_template === this.user.system_avatar_template) {
      return "system";
    } else if (this.user.avatar_template === this.user.gravatar_avatar_template) {
      return "gravatar";
    } else {
      return "custom";
    }
  }
  get selectedUploadId() {
    const selected = this.selected;
    switch (selected) {
      case "system":
        return this.user.system_avatar_upload_id;
      case "gravatar":
        return this.user.gravatar_avatar_upload_id;
      default:
        return this.user.custom_avatar_upload_id;
    }
  }
  get allowAvatarUpload() {
    return this.currentUser.can_upload_avatar && allowsImages(this.currentUser.staff, this.siteSettings);
  }
  onSelectedChanged(value) {
    this.selected = value;
  }
  async selectAvatar(url, event) {
    event?.preventDefault();
    try {
      await this.user.selectAvatar(url);
      window.location.reload();
    } catch (error) {
      popupAjaxError(error);
    }
  }
  uploadComplete() {
    this.selected = "custom";
  }
  async refreshGravatar() {
    this.gravatarRefreshDisabled = true;
    try {
      const result = await ajax(`/user_avatar/${this.user.username}/refresh_gravatar.json`, {
        type: "POST"
      });
      if (!result.gravatar_upload_id) {
        this.gravatarFailed = true;
      } else {
        this.gravatarFailed = false;
        this.user.setProperties({
          gravatar_avatar_upload_id: result.gravatar_upload_id,
          gravatar_avatar_template: result.gravatar_avatar_template
        });
      }
    } finally {
      this.gravatarRefreshDisabled = false;
    }
  }
  async saveAvatarSelection() {
    try {
      await this.user.pickAvatar(this.selectedUploadId, this.selected);
      if (!isTesting()) {
        window.location.reload();
      }
    } catch (error) {
      popupAjaxError(error);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "gravatarRefreshDisabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "gravatarFailed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uploading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_selected", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "onSelectedChanged", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectedChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAvatar", [action], Object.getOwnPropertyDescriptor(_class.prototype, "selectAvatar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadComplete", [action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshGravatar", [action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshGravatar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAvatarSelection", [action], Object.getOwnPropertyDescriptor(_class.prototype, "saveAvatarSelection"), _class.prototype)), _class);
export { AvatarSelectorModal as default };
setComponentTemplate(TEMPLATE, AvatarSelectorModal);