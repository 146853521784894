import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './anonymous-topic-footer-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { getTopicFooterButtons } from "discourse/lib/register-topic-footer-button";
import TEMPLATE from "./anonymous-topic-footer-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = computed("allButtons.[]"), (_obj = {
  elementId: "topic-footer-buttons",
  attributeBindings: ["role"],
  role: "region",
  allButtons: getTopicFooterButtons(),
  get buttons() {
    return this.allButtons.filterBy("anonymousOnly", true).sortBy("priority").reverse();
  }
}, (_applyDecoratedDescriptor(_obj, "buttons", [_dec], Object.getOwnPropertyDescriptor(_obj, "buttons"), _obj)), _obj))));