import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
import EmberObject from "@ember/object";
import { alias } from "@ember/object/computed";
import { isNone } from "@ember/utils";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { userPath } from "discourse/lib/url";
import Topic from "discourse/models/topic";
import User from "discourse/models/user";
import discourseComputed from "discourse-common/utils/decorators";
let Invite = (_dec = alias("topics.firstObject.id"), _dec2 = alias("topics.firstObject.title"), _dec3 = discourseComputed("invite_key"), _dec4 = discourseComputed("groups"), _dec5 = discourseComputed("topics.firstObject"), _dec6 = discourseComputed("email", "domain"), (_class = class Invite extends EmberObject {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "topicId", _descriptor, this);
    _initializerDefineProperty(this, "topicTitle", _descriptor2, this);
  }
  static create() {
    const result = super.create(...arguments);
    if (result.user) {
      result.user = User.create(result.user);
    }
    return result;
  }
  static findInvitedBy(user, filter, search, offset) {
    if (!user) {
      Promise.resolve();
    }
    const data = {};
    if (!isNone(filter)) {
      data.filter = filter;
    }
    if (!isNone(search)) {
      data.search = search;
    }
    data.offset = offset || 0;
    return ajax(userPath(`${user.username_lower}/invited.json`), {
      data
    }).then(result => {
      result.invites = result.invites.map(i => Invite.create(i));
      return EmberObject.create(result);
    });
  }
  static reinviteAll() {
    return ajax("/invites/reinvite-all", {
      type: "POST"
    });
  }
  static destroyAllExpired() {
    return ajax("/invites/destroy-all-expired", {
      type: "POST"
    });
  }
  save(data) {
    const promise = this.id ? ajax(`/invites/${this.id}`, {
      type: "PUT",
      data
    }) : ajax("/invites", {
      type: "POST",
      data
    });
    return promise.then(result => this.setProperties(result));
  }
  destroy() {
    return ajax("/invites", {
      type: "DELETE",
      data: {
        id: this.id
      }
    }).then(() => this.set("destroyed", true));
  }
  reinvite() {
    return ajax("/invites/reinvite", {
      type: "POST",
      data: {
        email: this.email
      }
    }).then(() => this.set("reinvited", true)).catch(popupAjaxError);
  }
  shortKey(key) {
    return key.slice(0, 4) + "...";
  }
  groupIds(groups) {
    return groups ? groups.map(group => group.id) : [];
  }
  topic(topicData) {
    return topicData ? Topic.create(topicData) : null;
  }
  emailOrDomain(email, domain) {
    return email || domain;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "topicId", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topicTitle", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "shortKey", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "shortKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "groupIds", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "groupIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "topic", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "topic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailOrDomain", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "emailOrDomain"), _class.prototype)), _class));
export { Invite as default };