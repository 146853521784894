import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import RestAdapter from "discourse/adapters/rest";
export default class ReviewableAdapter extends RestAdapter {
  constructor() {
    super(...arguments);
    _defineProperty(this, "jsonMode", true);
  }
  pathFor(store, type, findArgs) {
    return this.appendQueryParams("/review", findArgs);
  }
}