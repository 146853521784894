import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './slow-mode-info.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { durationTextFromSeconds } from "discourse/helpers/slow-mode";
import { popupAjaxError } from "discourse/lib/ajax-error";
import Topic from "discourse/models/topic";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./slow-mode-info.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("topic.slow_mode_seconds"), _dec2 = discourseComputed("topic.slow_mode_seconds", "topic.closed"), (_obj = {
  durationText(seconds) {
    return durationTextFromSeconds(seconds);
  },
  showSlowModeNotice(seconds, closed) {
    return seconds > 0 && !closed;
  },
  disableSlowMode() {
    Topic.setSlowMode(this.topic.id, 0).catch(popupAjaxError).then(() => this.set("topic.slow_mode_seconds", 0));
  }
}, (_applyDecoratedDescriptor(_obj, "durationText", [_dec], Object.getOwnPropertyDescriptor(_obj, "durationText"), _obj), _applyDecoratedDescriptor(_obj, "showSlowModeNotice", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showSlowModeNotice"), _obj), _applyDecoratedDescriptor(_obj, "disableSlowMode", [action], Object.getOwnPropertyDescriptor(_obj, "disableSlowMode"), _obj)), _obj))));