import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './json-schema-editor.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { create } from "virtual-dom";
import loadScript from "discourse/lib/load-script";
import { iconNode } from "discourse-common/lib/icon-library";
import { afterRender } from "discourse-common/utils/decorators";
import TEMPLATE from "./json-schema-editor.hbs";
import { setComponentTemplate } from "@ember/component";
let JsonSchemaEditorModal = (_class = class JsonSchemaEditorModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "editor", _descriptor, this);
    _initializerDefineProperty(this, "value", _descriptor2, this);
    _initializerDefineProperty(this, "flash", _descriptor3, this);
    _initializerDefineProperty(this, "flashType", _descriptor4, this);
  }
  get settingName() {
    return this.args.model.settingName.replace(/\_/g, " ");
  }
  buildJsonEditor(editor) {
    loadScript("/javascripts/jsoneditor.js").then(this._loadEditor.bind(this, editor));
  }
  teardownJsonEditor() {
    this.editor?.destroy();
  }
  saveChanges() {
    const errors = this.editor.validate();
    if (!errors.length) {
      this.value = JSON.stringify(this.editor.getValue());
      this.args.model.updateValue(this.value);
      this.args.closeModal();
    } else {
      this.flash = errors.mapBy("message").join("\n");
      this.flashType = "error";
    }
  }
  _loadEditor(editor) {
    let {
      JSONEditor
    } = window;
    JSONEditor.defaults.options.theme = "barebones";
    JSONEditor.defaults.iconlibs = {
      discourseIcons: DiscourseJsonSchemaEditorIconlib
    };
    JSONEditor.defaults.options.iconlib = "discourseIcons";
    this.editor = new JSONEditor(editor, {
      schema: this.args.model.jsonSchema,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      disable_array_reorder: false,
      disable_array_copy: false,
      enable_array_copy: true,
      disable_edit_json: true,
      disable_properties: true,
      disable_collapse: false,
      show_errors: "never",
      startval: this.value ? JSON.parse(this.value) : null
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "editor", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.value;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flashType", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "buildJsonEditor", [action], Object.getOwnPropertyDescriptor(_class.prototype, "buildJsonEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownJsonEditor", [action], Object.getOwnPropertyDescriptor(_class.prototype, "teardownJsonEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [action], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_loadEditor", [afterRender], Object.getOwnPropertyDescriptor(_class.prototype, "_loadEditor"), _class.prototype)), _class);
export { JsonSchemaEditorModal as default };
class DiscourseJsonSchemaEditorIconlib {
  constructor() {
    this.mapping = {
      delete: "trash-alt",
      add: "plus",
      moveup: "arrow-up",
      movedown: "arrow-down",
      moveleft: "chevron-left",
      moveright: "chevron-right",
      copy: "copy",
      collapse: "chevron-down",
      expand: "chevron-up"
    };
  }
  getIcon(key) {
    if (!this.mapping[key]) {
      return;
    }
    return create(iconNode(this.mapping[key]));
  }
}
setComponentTemplate(TEMPLATE, JsonSchemaEditorModal);