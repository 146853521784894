import bodyClass from "discourse/helpers/body-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const AddTopicStatusClasses = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @topic.invisible}}
    {{bodyClass "topic-status-unlisted"}}
  {{/if}}
  {{#if @topic.pinned}}
    {{bodyClass "topic-status-pinned"}}
  {{/if}}
  {{#if @topic.unpinned}}
    {{bodyClass "topic-status-unpinned"}}
  {{/if}}

*/
{
  "id": "qZDeg+nI",
  "block": "[[[1,\"\\n\"],[41,[30,1,[\"invisible\"]],[[[1,\"    \"],[1,[28,[32,0],[\"topic-status-unlisted\"],null]],[1,\"\\n\"]],[]],null],[41,[30,1,[\"pinned\"]],[[[1,\"    \"],[1,[28,[32,0],[\"topic-status-pinned\"],null]],[1,\"\\n\"]],[]],null],[41,[30,1,[\"unpinned\"]],[[[1,\"    \"],[1,[28,[32,0],[\"topic-status-unpinned\"],null]],[1,\"\\n\"]],[]],null]],[\"@topic\"],false,[\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/add-topic-status-classes.js",
  "scope": () => [bodyClass],
  "isStrictMode": true
}), templateOnly());
export default AddTopicStatusClasses;