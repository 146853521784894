import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _BootstrapModeNotice;
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import DiscourseURL from "discourse/lib/url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let BootstrapModeNotice = (_class = (_BootstrapModeNotice = class BootstrapModeNotice extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
  }
  routeToAdminGuide() {
    DiscourseURL.routeTo(`/t/-/${this.siteSettings.admin_quick_start_topic_id}`);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <DButton
      @action={{this.routeToAdminGuide}}
      @label="bootstrap_mode"
      class="btn-default bootstrap-mode"
    />
  
*/
{
  "id": "Mv5p0BJa",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-default bootstrap-mode\"]],[[\"@action\",\"@label\"],[[30,0,[\"routeToAdminGuide\"]],\"bootstrap_mode\"]],null],[1,\"\\n  \"]],[],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/bootstrap-mode-notice.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), _BootstrapModeNotice), _BootstrapModeNotice), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "routeToAdminGuide", [action], Object.getOwnPropertyDescriptor(_class.prototype, "routeToAdminGuide"), _class.prototype)), _class);
export { BootstrapModeNotice as default };