import { concat } from "@ember/helper";
import emoji from "discourse/helpers/emoji";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const title = (description1, endsAt1, timezone1) => {
  let content1 = description1;
  if (endsAt1) {
    const until1 = moment.tz(endsAt1, timezone1).format(I18n.t("dates.long_date_without_year"));
    content1 += `\n${I18n.t("until")} ${until1}`;
  }
  return content1;
};
const UserStatusBubble = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="user-status-background">
    {{emoji
      @status.emoji
      title=(title @status.description @status.ends_at @timezone)
      alt=(concat ":" @status.emoji ":")
    }}
  </div>

*/
{
  "id": "66KKY1Ar",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"user-status-background\"],[12],[1,\"\\n    \"],[1,[28,[32,0],[[30,1,[\"emoji\"]]],[[\"title\",\"alt\"],[[28,[32,1],[[30,1,[\"description\"]],[30,1,[\"ends_at\"]],[30,2]],null],[28,[32,2],[\":\",[30,1,[\"emoji\"]],\":\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@status\",\"@timezone\"],false,[]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/user-dropdown/user-status-bubble.js",
  "scope": () => [emoji, title, concat],
  "isStrictMode": true
}), templateOnly());
export default UserStatusBubble;