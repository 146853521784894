import _initializerDefineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './filter-navigation.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { resettableTracked } from "discourse/lib/tracked-tools";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./filter-navigation.hbs";
import { setComponentTemplate } from "@ember/component";
let DiscoveryFilterNavigation = (_class = class DiscoveryFilterNavigation extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "copyIcon", _descriptor, this);
    _initializerDefineProperty(this, "copyClass", _descriptor2, this);
    _initializerDefineProperty(this, "newQueryString", _descriptor3, this);
  }
  updateQueryString(string) {
    this.newQueryString = string;
  }
  clearInput() {
    this.newQueryString = "";
    this.args.updateTopicsListQueryParams(this.newQueryString);
  }
  copyQueryString() {
    this.copyIcon = "check";
    this.copyClass = "btn-default ok";
    navigator.clipboard.writeText(window.location);
    discourseDebounce(this._restoreButton, 3000);
  }
  _restoreButton() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.copyIcon = "link";
    this.copyClass = "btn-default";
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "copyIcon", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "link";
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "copyClass", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "btn-default";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "newQueryString", [resettableTracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.queryString;
  }
}), _applyDecoratedDescriptor(_class.prototype, "updateQueryString", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "clearInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyQueryString", [action], Object.getOwnPropertyDescriptor(_class.prototype, "copyQueryString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_restoreButton", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_restoreButton"), _class.prototype)), _class);
export { DiscoveryFilterNavigation as default };
setComponentTemplate(TEMPLATE, DiscoveryFilterNavigation);