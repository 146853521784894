import _applyDecoratedDescriptor from "/srv/http/diasporafoundation.org/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './user-summary-category-search.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./user-summary-category-search.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("user", "category"), (_obj = {
  tagName: "",
  searchParams() {
    return `@${this.get("user.username")} #${this.get("category.slug")}`;
  }
}, (_applyDecoratedDescriptor(_obj, "searchParams", [_dec], Object.getOwnPropertyDescriptor(_obj, "searchParams"), _obj)), _obj))));