import avatar from "discourse/helpers/bound-avatar-template";
import formatDate from "discourse/helpers/format-date";
import dIcon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ActionsSummary = setComponentTemplate(createTemplateFactory(
/*
  
  {{#each @data.actionsSummary as |as|}}
    <div class="post-action">{{as.description}}</div>
    <div class="clearfix"></div>
  {{/each}}
  {{#if @data.deleted_at}}
    <div class="post-action deleted-post">
      {{dIcon "far-trash-alt"}}
      <a
        class="trigger-user-card"
        data-user-card={{@data.deletedByUsername}}
        title={{@data.deletedByUsername}}
        aria-hidden="true"
      >
        {{avatar
          @data.deletedByAvatarTemplate
          "tiny"
          title=@data.deletedByUsername
        }}
      </a>
      {{formatDate @data.deleted_at format="tiny"}}
    </div>
  {{/if}}

*/
{
  "id": "fApKtWRQ",
  "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"actionsSummary\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"post-action\"],[12],[1,[30,2,[\"description\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"clearfix\"],[12],[13],[1,\"\\n\"]],[2]],null],[41,[30,1,[\"deleted_at\"]],[[[1,\"    \"],[10,0],[14,0,\"post-action deleted-post\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"far-trash-alt\"],null]],[1,\"\\n      \"],[10,3],[14,0,\"trigger-user-card\"],[15,\"data-user-card\",[30,1,[\"deletedByUsername\"]]],[15,\"title\",[30,1,[\"deletedByUsername\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[[30,1,[\"deletedByAvatarTemplate\"]],\"tiny\"],[[\"title\"],[[30,1,[\"deletedByUsername\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[32,2],[[30,1,[\"deleted_at\"]]],[[\"format\"],[\"tiny\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@data\",\"as\"],false,[\"each\",\"-track-array\",\"if\"]]",
  "moduleName": "/srv/http/diasporafoundation.org/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/actions-summary.js",
  "scope": () => [dIcon, avatar, formatDate],
  "isStrictMode": true
}), templateOnly());
export default ActionsSummary;